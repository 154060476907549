@import 'src/styles/utilities/utilities_new';

.ant-checkbox-inner {
  border-color: $theme-colors-border-color-base;
}

// Blue checkboxes
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $theme-colors-checkbox-color;
  color: $theme-colors-checkbox-check-color;
}

.ant-checkbox-checked {
  &.ant-checkbox-disabled {
    .ant-checkbox-inner {
      border-color: $theme-colors-border-color-base;
      background-color: $theme-colors-disabled-bg;
      color: $theme-colors-disabled-color;
    }
  }
}

.ant-checkbox-wrapper {
  color: $theme-colors-text-color;
}
