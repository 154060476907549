@import 'src/styles/utilities/utilities';
@import 'src/styles/utilities/utilities_new';

body.dark {
  .ant-drawer-content-wrapper {
    &.mobile-drawer {
      .ant-drawer-body {
        padding: 0;
        background: #1f1f1f;
      }
    }
  }

  .ant-layout-header {
    background: $theme-colors-component-background;
    border-bottom: 1px solid #434343;
  }

  // dark sub-header
  .ant-page-header {
    &.site-page-header {
      background: $theme-colors-component-background;
      border-bottom: 1px solid #434343;
    }
  }
}

body.default {
  .ant-layout-header {
    background: $theme-colors-component-background;
    border-bottom: 1px solid $gray-5;
  }

  .ant-page-header {
    &.site-page-header {
      background: $theme-colors-component-background;
      border-bottom: 1px solid $gray-5;
    }
  }
}

.ant-layout {
  color: var(--primary-font-color);
  background: $layout-body-body-background;
}

.ant-layout-header {
  color: var(--primary-font-color);
}

.ant-page-header {
  //display: flex;
  //
  //align-items: center;

  &.site-page-header {
    padding: 6px 20px;
  }

  .ant-page-header-heading {
    // width: 100%;
  }

  .ant-page-header-heading-title {
    // font-size: 16px;
  }

  .ant-page-header-heading-left {
    // width: 75%;
  }

  .ant-page-header-heading-extra {
    // width: 25%;
  }
}

.ant-layout-footer {
  padding: 14px;
}

.ant-drawer-content-wrapper {
  &.no-padding {
    .ant-drawer-body {
      padding: 0;
    }
  }

  &.mobile-drawer {
    .ant-drawer-body {
      padding: 0;
      background: #001529;
    }
  }
}
