@import 'src/styles/utilities/utilities_new';

.fr-element {
  background: $theme-colors-input-bg;
  font-family: $sf-pro-display;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: $theme-colors-input-color !important;
  padding: 5px 11px !important ;
}

.fr-wrapper {
  border-radius: 0 0 2px 2px;
}

.fr-emoticon-img {
  background-repeat: no-repeat !important;
  font-size: inherit;
  height: 1em;
  width: 1em;
  min-height: 20px;
  min-width: 20px;
  display: inline-block;
  margin: -0.1em 0.1em 0.1em;
  line-height: 1;
  vertical-align: middle;
}

.fr-toolbar.fr-top {
  border: 1px solid $theme-colors-border-color-base !important;
  border-radius: 2px 2px 0 0 !important;
  background: $theme-colors-background-color-light;
}

// Overriding some Dark theme Editor Styles
.dark-theme.fr-box.fr-basic .fr-wrapper {
  border: 1px solid $theme-colors-border-color-base;
}

.fr-box.fr-basic .fr-wrapper {
  border: 1px solid $theme-colors-border-color-base;
  top: 0;
  left: 0;

  scrollbar-width: thin;
  scrollbar-color: $theme-colors-border-color-base
    $theme-colors-component-background;
}

.second-toolbar {
  border: none;
}

.fr-toolbar .fr-command.fr-btn svg path {
  fill: $theme-colors-input-color;
}

.fr-toolbar .fr-command.fr-btn {
  color: $theme-colors-input-color;
}

.dark-theme .second-toolbar {
  border: none;
}

.fr-toolbar .fr-newline {
  background: none;
}

.fr-second-toolbar {
  border: none !important;
}

.fr-second-toolbar:empty {
  min-height: 0px;
}

#standardGuidance
  app-rteditor
  #rteditor
  .fr-toolbar
  .fr-btn-grp
  button[data-cmd='insertImage'] {
  display: none;
}

#standardGuidance
  app-rteditor
  #rteditor
  .fr-toolbar
  .fr-btn-grp
  button[data-cmd='googleDoc'] {
  display: none;
}

// For the rteditor @mention options
.tribute-container {
  width: 300px !important;
  height: auto;
  position: absolute;
  overflow: auto;
  max-height: 300px;
  z-index: 100 !important;
  background: skyblue !important;
  padding: 10px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      cursor: pointer;
      line-height: 1.3;
      margin: 12px 0;
    }
  }
}

/* Custom Mentions */
.customMentions {
  font-weight: bold;
  color: grey;
  font-size: 18px;
  border-bottom: 1px solid grey;
  pointer-events: none;
  cursor: none;
  margin: 12px 0;
}
