@import 'src/styles/utilities/utilities';

/**  Common styles for all modals in shared/modals directory with a clasname of 'ncx-modal' **/

body {
  &.dark {
    .ncx-modal {
      .header {
        color: $gray-5;
        border-bottom: 1px solid #434343;
      }
    }
  }

  &.default {
    .ncx-modal {
      .header {
        color: rgba(0, 0, 0, 0.85);
        border-bottom: 1px solid #e8e8e8;
      }
    }
  }
}

.ncx-modal {
  .ant-modal-body {
    padding: 0;
  }

  .header {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 24px;
    margin: 0;
  }

  .body {
    padding: 24px;

    > section {
      margin-bottom: 14px;

      &:last-child {
        margin-bottom: 0;
      }

      .form-label {
        color: $secondary-font-color;
        margin: 0 0 5px 0;
        font-size: 14px;
      }

      .form-hint {
        color: $secondary-font-color;
        margin: 5px 0 0 0;
        font-size: 12px;

        &.error {
          color: $red-6;
        }
      }

      .form-element {
        nz-select,
        nz-input,
        nz-textarea,
        nz-date-picker {
          width: 100%;
        }
      }
    }

    .list-selector {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        justify-content: space-between;
        padding: 5px 10px;
        border-radius: 4px;
        border: 1px solid $components-border;
      }

      .delete {
        color: $red-6;
      }
    }

    .tags-container {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      gap: 5px;
    }
  }
}

.scrollable-container {
  max-height: 256px; /* Set a max height for the scrollable container */
  overflow-y: auto; /* Enable vertical scrolling */
}
