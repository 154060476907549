@import 'src/styles/utilities/utilities';

body {
  &.default {
    // all siders take the same background
    .ant-layout-sider {
      background: $gray-1;
      border-left: 1px solid $theme-colors-divider-bg;

      // ... except for the left-side navigation menu
      &.menu-sidebar {
        background: #001529;
        border-left: none;
      }
    }
  }

  &.dark {
    // all siders take the same background
    .ant-layout-sider {
      background: $gray-9;
      border-left: 1px solid $theme-colors-divider-bg;

      // ... except for the left-side navigation menu
      &.menu-sidebar {
        background: #1f1f1f;
        border-left: none;
        border-right: 1px solid #ffffff0f;
      }
    }
  }
}
