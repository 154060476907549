@import 'src/styles/utilities/utilities_new';

.ant-tooltip {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      border-radius: 2px;
      background: #1d1d1d !important;
      color: #ffffff !important;
      font-family: $sf-pro-display;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;

      &:before {
        background: #1d1d1d !important;
      }
    }

    .ant-tooltip-arrow {
      .ant-tooltip-arrow-content {
        background: #1d1d1d !important;

        &:before {
          background: #1d1d1d !important;
        }
      }
    }
  }
}

.ant-tooltip {
  z-index: 1000;
}
