@import 'src/styles/utilities/utilities_new';

.ant-input,
.ant-input-affix-wrapper {
  border-radius: 2px;
  background: $theme-colors-input-bg;
  color: $theme-colors-text-color;
  border: 1px solid $theme-colors-border-color-base;
  // padding: 0px 12px;

  &::placeholder {
    color: $theme-colors-input-placeholder-color;
    opacity: 1;
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 2px;
  background: $theme-colors-input-bg;
  color: $theme-colors-input-color;
  border: 1px solid $theme-colors-input-border-color;
}

.ant-select-arrow {
  color: $theme-colors-buttons-default-color;
}

.ant-select-selection-placeholder {
  color: $theme-colors-input-placeholder-color;
  opacity: 1;
}

.ant-select-multiple.ant-select-disabled.ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  background-color: $theme-colors-input-disabled-bg;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: $theme-colors-input-disabled-bg;
}

.ant-select-multiple .ant-select-selection-item {
  background-color: $theme-colors-select-selection-item-bg;
  border: 1px solid $theme-colors-select-selection-item-border-color;
}

.ant-select-multiple .ant-select-selection-item-content {
  vertical-align: middle;
  word-break: break-all;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  flex: auto;
  white-space: wrap;
}
