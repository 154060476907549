@mixin settingsFontStyles() {
  float: left;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

@mixin settingsAntCardBody() {
  padding: 0;
  zoom: 1;
}

@mixin tagsFontStyles() {
  float: left;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

@mixin tagsAntCardBody() {
  padding: 0 !important;
  zoom: 1;
}

@mixin setOffset($property) {
  margin: $property;
}

@mixin settingDivider($width) {
  display: block;
  width: $width !important;
  min-width: $width !important;
  height: 1px !important;
  margin: 0 !important;
  top: 17px !important;
}

@mixin customCard($overflowy, $height) {
  overflow-y: $overflowy;
  height: $height;
}

@mixin webkitScrollBar() {
  /* width */
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border: 4px solid $scroll-bar-color;
  }

  &::-webkit-scrollbar:horizontal {
    display: none;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    border: 4px solid $scroll-bar-color;
  }
}
