@import 'src/styles/utilities/utilities';

body {
  &.default {
    .ant-drawer-content {
      background: $gray-1;
    }
  }

  &.dark {
    .ant-drawer-content {
      background: $gray-9;
    }
  }
}
