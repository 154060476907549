@import 'src/styles/utilities/utilities_new';

.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 4px 4px 0 0;
}
.ant-collapse-header .ant-collapse-arrow {
  vertical-align: middle !important;
}

.ant-collapse-content {
  border-radius: 0 0 4px 4px !important;
  background-color: $theme-colors-background-color-rhd !important;
}
.ant-input-affix-wrapper {
  border: 1px solid $theme-colors-input-color;
}

.ant-collapse {
  border-radius: 4px;
  margin-bottom: 16px;
  background-color: $theme-colors-background-color-rhd;

  &.ant-collapse-borderless {
    background-color: $theme-colors-background-color-rhd;
    border: none;

    > .ant-collapse-item {
      border: none;
    }
  }

  .ant-collapse-content {
    border-top: none;
  }

  .ant-collapse-arrow {
    font-size: 16px !important;
    transform: rotate(90deg) !important;
    top: 30% !important;
  }

  .ant-collapse-item-active {
    .ant-collapse-arrow {
      transform: rotate(180deg) !important;
    }
  }

  > .ant-collapse-item {
    // specificity issue .. need to double the selector
    > .ant-collapse-header.ant-collapse-header {
      overflow: hidden;
      color: $theme-colors-text-color;
      text-overflow: ellipsis;

      font-family: $sf-pro-display;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 135%;
      letter-spacing: 0;
      text-align: left;
      background-color: $theme-colors-background-color-rhd;
      padding: 16px 40px 16px 16px;

      i:not(.ant-collapse-arrow) {
        padding-right: 8px;
        color: $collapse-panel-header-icons;
      }

      .ant-collapse-arrow {
        color: $collapse-icon;
      }
    }

    // Target the header when the item is not active (collapsed)
    &:not(.ant-collapse-item-active) .ant-collapse-header {
      border-radius: 4px 4px 4px 4px;
    }

    &:last-child {
      border-radius: 0 0 4px 4px;
    }
  }

  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: none;

    &:last-child {
      border-radius: 0 0 4px 4px;
    }
  }
}
