@import 'variables.scss';

/**
    Form label hints and (optional) text
 */
%label-hint {
  margin-left: 5px;
  font-size: 14px;
  color: $gray-7;
  font-weight: 300;
}

%label-optional {
  ::ng-deep {
    .ant-form-item-label {
      &:after {
        content: '(optional)';
        @extend %label-hint;
      }
    }
  }
}
