@import 'src/styles/utilities/utilities';

.ant-tabs {
  color: $tabs-fore-color;
}

.ant-tabs-nav {
  margin: 0 16px 16px 16px;
  border-bottom: 1px solid $theme-colors-border-color-base;

  .ant-tabs-nav-wrap {
    margin: 0 16px;
    @media #{$max-medium} {
      margin: 0 24px;
    }
  }
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 24px;
}

.ant-tabs-tab {
  color: $theme-colors-text-color;

  font-family: $sf-pro-display;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 157.15%; /* 22.001px */
}

// mparillo -- removing the transition looks better because
// the active tab has a 'bold' style
.ant-tabs-tab-btn {
  transition: none;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $theme-colors-tabs-card-active-color;

  font-family: $sf-pro-display;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 157.15%;
}

.ant-tabs-tab:hover {
  color: $text-selection !important;
}

.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none;
  margin: 0 20px 0 23px;
}

.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > .ant-tabs-nav::before {
  margin: 0 20px 0 23px;
}

// --mparillo
// You can't do this.
// Some pages have nav tabs that go full-width (e.g. Admin page)
.ant-tabs-top > .ant-tabs-nav {
  // margin: 0 24px 16px 24px;
}
