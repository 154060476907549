@import 'src/styles/utilities/utilities_new';

.ant-menu {
  &.ant-menu-dark {
    a,
    button {
      color: $theme-colors-menu-dark-color;

      &:hover {
        color: $theme-colors-menu-dark-selected-item-text-color;
      }
    }

    .ant-menu-item-selected {
      background: $theme-colors-primary-color;
    }

    .ant-menu-inline {
      &.ant-menu-sub {
        background: $theme-colors-menu-dark-inline-submenu-bg;
      }
    }
  }
}

.ant-menu-submenu {
  // This is necessary to force the fly-out menu to appear to the right of the menu item at the right distance
  &.ant-menu-submenu-placement-right {
    left: 20px !important;
  }

  .ant-menu-item {
    a {
      color: $theme-colors-menu-dark-color;

      &:hover {
        color: $theme-colors-menu-dark-selected-item-text-color;
      }
    }
  }
}
