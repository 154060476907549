/*
These are all of the Light and Dark color variables in the Ant Design system.
DO NOT ADD or REMOVE colors from this file.
If you need to add or update these colors, it must be done by the UX Design Team and exported out of Figma again.
*/

body {
  --global-colors-grey-0: #0303030A;   // Colors/Grey/Light/0
  --global-colors-grey-2: #03030305;   // Colors/Grey/Light/2
  --global-colors-grey-6: #0303030F;   // Colors/Grey/Light/6
  --global-colors-grey-8: #03030314;   // Colors/Grey/Light/8
  --global-colors-grey-10: #03030319;   // Colors/Grey/Light/10
  --global-colors-grey-15: #03030326;   // Colors/Grey/Light/15
  --global-colors-grey-20: #03030333;   // Colors/Grey/Light/20
  --global-colors-grey-25: #0303033F;   // Colors/Grey/Light/25
  --global-colors-grey-30: #0303034C;   // Colors/Grey/Light/30
  --global-colors-grey-35: #03030359;   // Colors/Grey/Light/35
  --global-colors-grey-40: #03030366;   // Colors/Grey/Light/40
  --global-colors-grey-45: #03030372;   // Colors/Grey/Light/45
  --global-colors-grey-50: #0303037F;   // Colors/Grey/Light/50
  --global-colors-grey-55: #0303038C;   // Colors/Grey/Light/55
  --global-colors-grey-60: #03030399;   // Colors/Grey/Light/60
  --global-colors-grey-65: #030303A5;   // Colors/Grey/Light/65
  --global-colors-grey-70: #030303B2;   // Colors/Grey/Light/70
  --global-colors-grey-75: #030303B2;   // Colors/Grey/Light/75
  --global-colors-grey-80: #030303CC;   // Colors/Grey/Light/80
  --global-colors-grey-85: #030303D8;   // Colors/Grey/Light/85
  --global-colors-grey-90: #030303E5;   // Colors/Grey/Light/90
  --global-colors-grey-96: #030303F4;   // Colors/Grey/Light/96
  --global-colors-grey-100: #030303FF;   // Colors/Grey/Light/100
  --global-colors-blue-main-10: #F0F5FFFF;   // Colors/Geek Blue/Light/10
  --global-colors-cyan-10: #E6FFFBFF;   // Colors/Cyan/Light/10
  --global-colors-cyan-20: #B5F5ECFF;   // Colors/Cyan/Light/20
  --global-colors-cyan-30: #87E8DEFF;   // Colors/Cyan/Light/30
  --global-colors-cyan-40: #5CDBD3FF;   // Colors/Cyan/Light/40
  --global-colors-cyan-50: #36CFC9FF;   // Colors/Cyan/Light/50
  --global-colors-cyan-60: #13C2C2FF;   // Colors/Cyan/Light/60
  --global-colors-cyan-70: #08979CFF;   // Colors/Cyan/Light/70
  --global-colors-cyan-80: #006D75FF;   // Colors/Cyan/Light/80
  --global-colors-cyan-90: #00474FFF;   // Colors/Cyan/Light/90
  --global-colors-cyan-100: #002329FF;   // Colors/Cyan/Light/100
  --global-colors-blue-main-20: #D6E4FFFF;   // Colors/Geek Blue/Light/20
  --global-colors-blue-main-30: #ADC6FFFF;   // Colors/Geek Blue/Light/30
  --global-colors-blue-main-40: #85A5FFFF;   // Colors/Geek Blue/Light/40
  --global-colors-blue-main-50: #597EF7FF;   // Colors/Geek Blue/Light/50
  --global-colors-blue-main-60: #2F54EBFF;   // Colors/Geek Blue/Light/60
  --global-colors-blue-main-70: #1D39C4FF;   // Colors/Geek Blue/Light/70
  --global-colors-blue-main-80: #10239EFF;   // Colors/Geek Blue/Light/80
  --global-colors-blue-main-90: #061178FF;   // Colors/Geek Blue/Light/90
  --global-colors-blue-main-100: #030852FF;   // Colors/Geek Blue/Light/100
  --global-colors-blue-secondary-10: #E6F7FFFF;   // Colors/Daybreak Blue/Light/10
  --global-colors-blue-secondary-20: #BAE7FFFF;   // Colors/Daybreak Blue/Light/20
  --global-colors-blue-secondary-30: #91D5FFFF;   // Colors/Daybreak Blue/Light/30
  --global-colors-blue-secondary-40: #69C0FFFF;   // Colors/Daybreak Blue/Light/40
  --global-colors-blue-secondary-50: #40A9FFFF;   // Colors/Daybreak Blue/Light/50
  --global-colors-blue-secondary-60: #1890FFFF;   // Colors/Daybreak Blue/Light/60
  --global-colors-blue-secondary-70: #096DD9FF;   // Colors/Daybreak Blue/Light/70
  --global-colors-blue-secondary-80: #0050B3FF;   // Colors/Daybreak Blue/Light/80
  --global-colors-blue-secondary-90: #003A8CFF;   // Colors/Daybreak Blue/Light/90
  --global-colors-blue-secondary-100: #002766FF;   // Colors/Daybreak Blue/Light/100
  --global-colors-green-main-10: #F6FFEDFF;   // Colors/Green/Light/10
  --global-colors-green-main-20: #D9F7BEFF;   // Colors/Green/Light/20
  --global-colors-green-main-30: #B7EB8FFF;   // Colors/Green/Light/30
  --global-colors-green-main-40: #95DE64FF;   // Colors/Green/Light/40
  --global-colors-green-main-50: #73D13DFF;   // Colors/Green/Light/50
  --global-colors-green-main-60: #52C41AFF;   // Colors/Green/Light/60
  --global-colors-green-main-70: #389E0DFF;   // Colors/Green/Light/70
  --global-colors-green-main-80: #237804FF;   // Colors/Green/Light/80
  --global-colors-green-main-90: #135200FF;   // Colors/Green/Light/90
  --global-colors-green-main-100: #092B00FF;   // Colors/Green/Light/100
  --global-colors-green-secondary-10: #FCFFE6FF;   // Colors/Lime/Light/10
  --global-colors-green-secondary-20: #F4FFB8FF;   // Colors/Lime/Light/20
  --global-colors-green-secondary-30: #EAFF8FFF;   // Colors/Lime/Light/30
  --global-colors-green-secondary-40: #D3F261FF;   // Colors/Lime/Light/40
  --global-colors-green-secondary-50: #BAE637FF;   // Colors/Lime/Light/50
  --global-colors-green-secondary-60: #A0D911FF;   // Colors/Lime/Light/60
  --global-colors-green-secondary-70: #7CB305FF;   // Colors/Lime/Light/70
  --global-colors-green-secondary-80: #5B8C00FF;   // Colors/Lime/Light/80
  --global-colors-green-secondary-90: #3F6600FF;   // Colors/Lime/Light/90
  --global-colors-green-secondary-100: #254000FF;   // Colors/Lime/Light/100
  --global-colors-magenta-10: #FFF0F6FF;   // Colors/Magenta/Light/10
  --global-colors-magenta-20: #FFD6E7FF;   // Colors/Magenta/Light/20
  --global-colors-magenta-30: #FFADD2FF;   // Colors/Magenta/Light/30
  --global-colors-magenta-40: #FF85C0FF;   // Colors/Magenta/Light/40
  --global-colors-magenta-50: #F759ABFF;   // Colors/Magenta/Light/50
  --global-colors-magenta-60: #EB2F96FF;   // Colors/Magenta/Light/60
  --global-colors-magenta-70: #C41D7FFF;   // Colors/Magenta/Light/70
  --global-colors-magenta-80: #9E1068FF;   // Colors/Magenta/Light/80
  --global-colors-magenta-90: #780650FF;   // Colors/Magenta/Light/90
  --global-colors-magenta-100: #520339FF;   // Colors/Magenta/Light/100
  --global-colors-orange-10: #FFF7E6FF;   // Colors/Orange/Light/10
  --global-colors-orange-20: #FFE7BAFF;   // Colors/Orange/Light/20
  --global-colors-orange-30: #FFD591FF;   // Colors/Orange/Light/30
  --global-colors-orange-40: #FFC069FF;   // Colors/Orange/Light/40
  --global-colors-orange-50: #FFA940FF;   // Colors/Orange/Light/50
  --global-colors-orange-60: #FA8C16FF;   // Colors/Orange/Light/60
  --global-colors-orange-70: #D46B08FF;   // Colors/Orange/Light/70
  --global-colors-orange-80: #AD4E00FF;   // Colors/Orange/Light/80
  --global-colors-orange-90: #873800FF;   // Colors/Orange/Light/90
  --global-colors-orange-100: #612500FF;   // Colors/Orange/Light/100
  --global-colors-purple-10: #F9F0FFFF;   // Colors/Golden Purple/Light/10
  --global-colors-purple-20: #EFDBFFFF;   // Colors/Golden Purple/Light/20
  --global-colors-purple-30: #D3ADF7FF;   // Colors/Golden Purple/Light/30
  --global-colors-purple-40: #B37FEBFF;   // Colors/Golden Purple/Light/40
  --global-colors-purple-50: #9254DEFF;   // Colors/Golden Purple/Light/50
  --global-colors-purple-60: #722ED1FF;   // Colors/Golden Purple/Light/60
  --global-colors-purple-70: #531DABFF;   // Colors/Golden Purple/Light/70
  --global-colors-purple-80: #391085FF;   // Colors/Golden Purple/Light/80
  --global-colors-purple-90: #22075EFF;   // Colors/Golden Purple/Light/90
  --global-colors-purple-100: #120338FF;   // Colors/Golden Purple/Light/100
  --global-colors-red-main-10: #FFF1F0FF;   // Colors/Dust Red/Light/10
  --global-colors-red-main-20: #FFCCC7FF;   // Colors/Dust Red/Light/20
  --global-colors-red-main-30: #FFA39EFF;   // Colors/Dust Red/Light/30
  --global-colors-red-main-40: #FF7875FF;   // Colors/Dust Red/Light/40
  --global-colors-red-main-50: #FF4D4FFF;   // Colors/Dust Red/Light/50
  --global-colors-red-main-60: #F5222DFF;   // Colors/Dust Red/Light/60
  --global-colors-red-main-70: #CF1322FF;   // Colors/Dust Red/Light/70
  --global-colors-red-main-80: #A8071AFF;   // Colors/Dust Red/Light/80
  --global-colors-red-main-90: #820014FF;   // Colors/Dust Red/Light/90
  --global-colors-red-main-100: #5C0011FF;   // Colors/Dust Red/Light/100
  --global-colors-red-secondary-10: #FFF2E8FF;   // Colors/Volcano Red/Light/10
  --global-colors-red-secondary-20: #FFD8BFFF;   // Colors/Volcano Red/Light/20
  --global-colors-red-secondary-30: #FFBB96FF;   // Colors/Volcano Red/Light/30
  --global-colors-red-secondary-40: #FF9C6EFF;   // Colors/Volcano Red/Light/40
  --global-colors-red-secondary-50: #FF7A45FF;   // Colors/Volcano Red/Light/50
  --global-colors-red-secondary-60: #FA541CFF;   // Colors/Volcano Red/Light/60
  --global-colors-red-secondary-70: #D4380DFF;   // Colors/Volcano Red/Light/70
  --global-colors-red-secondary-80: #AD2102FF;   // Colors/Volcano Red/Light/80
  --global-colors-red-secondary-90: #871400FF;   // Colors/Volcano Red/Light/90
  --global-colors-red-secondary-100: #5C0011FF;   // Colors/Volcano Red/Light/100
  --global-colors-yellow-main-10: #FEFFE6FF;   // Colors/Yellow/Light/10
  --global-colors-yellow-main-20: #FFFFB8FF;   // Colors/Yellow/Light/20
  --global-colors-yellow-main-30: #FFFB8FFF;   // Colors/Yellow/Light/30
  --global-colors-yellow-main-40: #FFF566FF;   // Colors/Yellow/Light/40
  --global-colors-yellow-main-50: #FFEC3DFF;   // Colors/Yellow/Light/50
  --global-colors-yellow-main-60: #FADB14FF;   // Colors/Yellow/Light/60
  --global-colors-yellow-main-70: #D4B106FF;   // Colors/Yellow/Light/70
  --global-colors-yellow-main-80: #AD8B00FF;   // Colors/Yellow/Light/80
  --global-colors-yellow-main-90: #876800FF;   // Colors/Yellow/Light/90
  --global-colors-yellow-main-100: #614700FF;   // Colors/Yellow/Light/100
  --global-colors-yellow-secondary-10: #FFFBE6FF;   // Colors/Gold/Light/10
  --global-colors-yellow-secondary-20: #FFF1B8FF;   // Colors/Gold/Light/20
  --global-colors-yellow-secondary-30: #FFE58FFF;   // Colors/Gold/Light/30
  --global-colors-yellow-secondary-40: #FFD666FF;   // Colors/Gold/Light/40
  --global-colors-yellow-secondary-50: #FFC53DFF;   // Colors/Gold/Light/50
  --global-colors-yellow-secondary-60: #FAAD14FF;   // Colors/Gold/Light/60
  --global-colors-yellow-secondary-70: #D48806FF;   // Colors/Gold/Light/70
  --global-colors-yellow-secondary-80: #AD6800FF;   // Colors/Gold/Light/80
  --global-colors-yellow-secondary-90: #874D00FF;   // Colors/Gold/Light/90
  --global-colors-yellow-secondary-100: #613400FF;   // Colors/Gold/Light/100
  --theme-colors-alert-success-border-color: #B7EB8FFF;   // Colors/Green/Light/30
  --theme-colors-alert-success-bg-color: #F6FFEDFF;   // Colors/Green/Light/10
  --theme-colors-alert-success-icon-color: #52C41AFF;   // Colors/Green/Light/60
  --theme-colors-alert-info-border-color: #91D5FFFF;   // Colors/Daybreak Blue/Light/30
  --theme-colors-alert-info-bg-color: #E6F7FFFF;   // Colors/Daybreak Blue/Light/10
  --theme-colors-alert-info-icon-color: #1890FFFF;   // Colors/Daybreak Blue/Light/60
  --theme-colors-alert-warning-border-color: #FFE58FFF;   // Colors/Gold/Light/30
  --theme-colors-alert-warning-bg-color: #FFFBE6FF;   // Colors/Gold/Light/10
  --theme-colors-alert-warning-icon-color: #FAAD14FF;   // Colors/Gold/Light/60
  --theme-colors-alert-error-border-color: #FFA39EFF;   // Colors/Dust Red/Light/30
  --theme-colors-alert-error-bg-color: #FFF1F0FF;   // Colors/Dust Red/Light/10
  --theme-colors-alert-error-icon-color: #FF4D4FFF;   // Colors/Dust Red/Light/50
  --theme-colors-alert-message-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-alert-message-text-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-alert-message-close-out: #03030372;   // Colors/Grey/Light/45
  --theme-colors-alert-message-close-hover-color: #030303B2;   // Colors/Grey/Light/75
  --theme-colors-anchor-bg: #00000000;   // Theme Colors/Anchor/bg
  --theme-colors-avatar-bg: #CCCCCCFF;   // Colors/White/7
  --theme-colors-avatar-color: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-avatar-group-border-color: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-anchor-border-color: #0303030F;   // Colors/Grey/Light/6
  --theme-colors-back-top-color: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-back-top-bg: #03030372;   // Colors/Grey/Light/45
  --theme-colors-back-top-hover-bg: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-background-color-light: #FAFAFAFF;   // Colors/White/2
  --theme-colors-background-color-base: #0303030A;   // Colors/Grey/Light/0
  --theme-colors-background-color-card: #F5F5F5FF;   // Colors/White/1
  --theme-colors-background-color-rhd: #F5F5F5FF;   // Colors/White/1
  --theme-colors-background-color--rhd---alt: #F0F5FFFF;   // Colors/Geek Blue/Light/10
  --theme-colors-badge-text-color: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-badge-badge-color: #FF4D4FFF;   // Colors/Dust Red/Light/50
  --theme-colors-body-background: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-border-color-base: #03030326;   // Colors/Grey/Light/15
  --theme-colors-bread-crumb-base-color: #03030372;   // Colors/Grey/Light/45
  --theme-colors-bread-crumb-last-item-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-bread-crumb-link-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-bread-crumb-link-color-hover: #03030372;   // Colors/Grey/Light/45
  --theme-colors-bread-crumb-separator-color: #03030372;   // Colors/Grey/Light/45
  --theme-colors-button-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-button-check-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-button-button-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-button-button-hover-color: #40A9FFFF;   // Colors/Daybreak Blue/Light/50
  --theme-colors-button-button-active-color: #096DD9FF;   // Colors/Daybreak Blue/Light/70
  --theme-colors-button-disabled-button-checked-bg: #03030319;   // Colors/Grey/Light/10
  --theme-colors-button-disabled-button-checked-color: #0303033F;   // Colors/Grey/Light/25
  --theme-colors-border-color-split: #0303030F;   // Colors/Grey/Light/6
  --theme-colors-border-color-inverse: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-buttons-primary-primary-color: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-calendar-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-card-head-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-cascader-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-cascader-item-selected-bg: #E6F7FFFF;   // Colors/Daybreak Blue/Light/10
  --theme-colors-cascader-menu-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-cascader-menu-border-color-split: #0303030F;   // Colors/Grey/Light/6
  --theme-colors-card-header-background: #00000000;   // Theme Colors/Card/header-background
  --theme-colors-card-actions-background: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-card-background: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-card-head-extra-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-calendar-input-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-calendar-border-color: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-calendar-item-active-bg: #E6F7FFFF;   // Colors/Daybreak Blue/Light/10
  --theme-colors-calendar-column-active-bg: #E6F7FF33;   // Theme Colors/Calendar/column-active-bg
  --theme-colors-calendar-full-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-calendar-full-panel-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-buttons-primary-bg: #1890FFFF;   // Colors/Daybreak Blue/Light/60
  --theme-colors-buttons-default-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-buttons-default-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-buttons-default-border: #03030326;   // Colors/Grey/Light/15
  --theme-colors-buttons-danger-color: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-buttons-danger-bg: #FF4D4FFF;   // Colors/Dust Red/Light/50
  --theme-colors-buttons-danger-border: #FF4D4FFF;   // Colors/Dust Red/Light/50
  --theme-colors-buttons-disable-color: #0303033F;   // Colors/Grey/Light/25
  --theme-colors-buttons-disable-bg: #0303030A;   // Colors/Grey/Light/0
  --theme-colors-buttons-disable-border: #03030326;   // Colors/Grey/Light/15
  --theme-colors-buttons-default-ghost-color: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-buttons-default-ghost-bg: #00000000;   // Theme Colors/Buttons/Default Ghost/bg
  --theme-colors-buttons-default-ghost-border: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-buttons-group-border: #40A9FFFF;   // Colors/Daybreak Blue/Light/50
  --theme-colors-buttons-link-hover-bg: #00000000;   // Theme Colors/Buttons/Link/hover-bg
  --theme-colors-buttons-text-hover-bg: #03030305;   // Colors/Grey/Light/2
  --theme-colors-checkbox-color: #1890FFFF;   // Colors/Daybreak Blue/Light/60
  --theme-colors-checkbox-check-color: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-checkbox-check-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-collapse-header-bg: #03030305;   // Colors/Grey/Light/2
  --theme-colors-collapse-content-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-comment-author-name-color: #03030372;   // Colors/Grey/Light/45
  --theme-colors-comment-author-time-color: #CCCCCCFF;   // Colors/White/7
  --theme-colors-comment-action-color: #03030372;   // Colors/Grey/Light/45
  --theme-colors-comment-action-hover-color: #595959FF;   // Colors/Black/6
  --theme-colors-component-background: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-descriptions-bg: #FAFAFAFF;   // Colors/White/2
  --theme-colors-descriptions-extra-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-disabled-color: #0303033F;   // Colors/Grey/Light/25
  --theme-colors-disabled-bg: #0303030A;   // Colors/Grey/Light/0
  --theme-colors-disabled-active-bg: #03030319;   // Colors/Grey/Light/10
  --theme-colors-disabled-color-dark: #0303034C;   // Colors/Grey/Light/30
  --theme-colors-divider-bg: #0303030F;   // Colors/Grey/Light/6
  --theme-colors-dot-dot-color: #1890FFFF;   // Colors/Daybreak Blue/Light/60
  --theme-colors-dot-dot-disabled-color: #03030333;   // Colors/Grey/Light/20
  --theme-colors-drawer-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-dropdown-selected-color: #1890FFFF;   // Colors/Daybreak Blue/Light/60
  --theme-colors-dropdown-menu-submenu-disabled-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-dropdown-selected-bg: #E6F7FFFF;   // Colors/Daybreak Blue/Light/10
  --theme-colors-dropdown-menu-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-empty-img-default-elipse: #F5F5F5FF;   // Colors/White/1
  --theme-colors-empty-img-default-path-1: #AEB8C2FF;   // Theme Colors/Empty/img-default-path-1
  --theme-colors-empty-img-default-path-3: #F5F5F7FF;   // Theme Colors/Empty/img-default-path-3
  --theme-colors-empty-img-default-path-4: #DCE0E6FF;   // Theme Colors/Empty/img-default-path-4
  --theme-colors-empty-img-default-path-5: #DCE0E6FF;   // Theme Colors/Empty/img-default-path-5
  --theme-colors-error-color: #FF4D4FFF;   // Colors/Dust Red/Light/50
  --theme-colors-form-warning-input-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-form-error-input-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-error-color-hover: #FF7875FF;   // Colors/Dust Red/Light/40
  --theme-colors-error-color-active: #F5222DFF;   // Colors/Dust Red/Light/60
  --theme-colors-error-color-outline: #FFCCC7FF;   // Colors/Dust Red/Light/20
  --theme-colors-error-color-deprecated-bg: #FFF1F0FF;   // Colors/Dust Red/Light/10
  --theme-colors-error-color-deprecated-border: #FFA39EFF;   // Colors/Dust Red/Light/30
  --theme-colors-header-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-header-component-header-color: #0050B3FF;   // Colors/Daybreak Blue/Light/80
  --theme-colors-highlight-color: #FF4D4FFF;   // Colors/Dust Red/Light/50
  --theme-colors-highlight-search-return-bg: #EFDBFFFF;   // Colors/Golden Purple/Light/20
  --theme-colors-highlight-search-return-color: #391085FF;   // Colors/Golden Purple/Light/80
  --theme-colors-icon-color: #030303B2;   // Colors/Grey/Light/75
  --theme-colors-icon-secondary: #0303033F;   // Colors/Grey/Light/25
  --theme-colors-images-bg: #F5F5F5FF;   // Colors/White/1
  --theme-colors-images-color: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-images-preview-operation-color: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-info-color: #40A9FFFF;   // Colors/Daybreak Blue/Light/50
  --theme-colors-input-placeholder-color: #0303033F;   // Colors/Grey/Light/25
  --theme-colors-input-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-input-icon-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-input-border-color: #03030326;   // Colors/Grey/Light/15
  --theme-colors-input-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-input-number-hover-border-color: #40A9FFFF;   // Colors/Daybreak Blue/Light/50
  --theme-colors-input-number-handler-active-bg: #F4F4F4FF;   // Colors/White/3
  --theme-colors-input-number-handler-hover-bg: #40A9FFFF;   // Colors/Daybreak Blue/Light/50
  --theme-colors-input-number-handler-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-input-number-handler-border-color: #03030326;   // Colors/Grey/Light/15
  --theme-colors-input-addon-bg: #03030305;   // Colors/Grey/Light/2
  --theme-colors-input-hover-border-color: #40A9FFFF;   // Colors/Daybreak Blue/Light/50
  --theme-colors-input-disabled-bg: #0303030A;   // Colors/Grey/Light/0
  --theme-colors-input-icon-hover-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-input-disabled-color: #0303033F;   // Colors/Grey/Light/25
  --theme-colors-input-read-only: #FAFAFAFF;   // Colors/White/2
  --theme-colors-info-color-deprecated-bg: #E6F7FFFF;   // Colors/Daybreak Blue/Light/10
  --theme-colors-info-color-deprecated-border: #91D5FFFF;   // Colors/Daybreak Blue/Light/30
  --theme-colors-item-active-bg: #E6F7FFFF;   // Colors/Daybreak Blue/Light/10
  --theme-colors-item-hover-bg: #F5F5F5FF;   // Colors/White/1
  --theme-colors-label-required-color: #FF4D4FFF;   // Colors/Dust Red/Light/50
  --theme-colors-label-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-link-color-base: #1890FFFF;   // Colors/Daybreak Blue/Light/60
  --theme-colors-link-hover: #40A9FFFF;   // Colors/Daybreak Blue/Light/50
  --theme-colors-link-color-split: #40A9FFFF;   // Colors/Daybreak Blue/Light/50
  --theme-colors-link-color-inverse: #096DD9FF;   // Colors/Daybreak Blue/Light/70
  --theme-colors-link-active: #096DD9FF;   // Colors/Daybreak Blue/Light/70
  --theme-colors-list-header-background: #00000000;   // Theme Colors/List/header-background
  --theme-colors-page-header-back-color: #000000FF;   // Colors/Black/1
  --theme-colors-pagination-item-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-pagination-item-bg-active: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-pagination-item-link-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-pagination-item-disabled-color-active: #0303033F;   // Colors/Grey/Light/25
  --theme-colors-pagination-item-disabled-bg-active: #03030319;   // Colors/Grey/Light/10
  --theme-colors-pagination-item-input-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-list-footer-background: #00000000;   // Theme Colors/List/footer-background
  --theme-colors-list-customize-card-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-picker-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-popover-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-popover-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-popover-arrow-color: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-popover-arrow-outer-color: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-popover-background: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-popover-customize-border-color: #0303030F;   // Colors/Grey/Light/6
  --theme-colors-primary-10: #E6F7FFFF;   // Colors/Daybreak Blue/Light/10
  --theme-colors-primary-20: #BAE7FFFF;   // Colors/Daybreak Blue/Light/20
  --theme-colors-primary-30: #91D5FFFF;   // Colors/Daybreak Blue/Light/30
  --theme-colors-primary-40: #69C0FFFF;   // Colors/Daybreak Blue/Light/40
  --theme-colors-primary-50: #40A9FFFF;   // Colors/Daybreak Blue/Light/50
  --theme-colors-primary-60: #1890FFFF;   // Colors/Daybreak Blue/Light/60
  --theme-colors-primary-70: #096DD9FF;   // Colors/Daybreak Blue/Light/70
  --theme-colors-primary-80: #0050B3FF;   // Colors/Daybreak Blue/Light/80
  --theme-colors-primary-90: #003A8CFF;   // Colors/Daybreak Blue/Light/90
  --theme-colors-primary-100: #002766FF;   // Colors/Daybreak Blue/Light/100
  --theme-colors-primary-color: #1890FFFF;   // Colors/Daybreak Blue/Light/60
  --theme-colors-primary-color-hover: #40A9FFFF;   // Colors/Daybreak Blue/Light/50
  --theme-colors-primary-color-active: #096DD9FF;   // Colors/Daybreak Blue/Light/70
  --theme-colors-primary-color-outline: #BAE7FFFF;   // Colors/Daybreak Blue/Light/20
  --theme-colors-picker-basic-cell-hover-color: #F5F5F5FF;   // Colors/White/1
  --theme-colors-picker-basic-cell-active-with-range-color: #E6F7FFFF;   // Colors/Daybreak Blue/Light/10
  --theme-colors-picker-basic-cell-disabled-bg: #0303030A;   // Colors/Grey/Light/0
  --theme-colors-picker-border-color: #0303030F;   // Colors/Grey/Light/6
  --theme-colors-picker-basic-cell-hover-with-range-color: #73D13DFF;   // Colors/Green/Light/50
  --theme-colors-picker-date-hover-range-color: #73D13DFF;   // Colors/Green/Light/50
  --theme-colors-processing-color: #1890FFFF;   // Colors/Daybreak Blue/Light/60
  --theme-colors-progress-default-color: #1890FFFF;   // Colors/Daybreak Blue/Light/60
  --theme-colors-progress-remaining-color: #0303030A;   // Colors/Grey/Light/0
  --theme-colors-progress-info-text-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-progress-steps-item-bg: #F3F3F3FF;   // Colors/White/5
  --theme-colors-progress-text-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-process-tail-color: #0303030F;   // Colors/Grey/Light/6
  --theme-colors-mark-bg: #FFE58FFF;   // Colors/Gold/Light/30
  --theme-colors-mentions-dropdown-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-menu-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-menu-popup-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-menu-item-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-menu-inline-submenu-bg: #03030305;   // Colors/Grey/Light/2
  --theme-colors-menu-highlight-color: #1890FFFF;   // Colors/Daybreak Blue/Light/60
  --theme-colors-menu-highlight-danger-color: #FF4D4FFF;   // Colors/Dust Red/Light/50
  --theme-colors-menu-item-active-bg: #E6F7FFFF;   // Colors/Daybreak Blue/Light/10
  --theme-colors-menu-item-active-danger-bg: #FFF1F0FF;   // Colors/Dust Red/Light/10
  --theme-colors-menu-item-group-title-color: #03030372;   // Colors/Grey/Light/45
  --theme-colors-menu-dark-color: #FFFFFFA5;   // Theme Colors/Menu/dark-color
  --theme-colors-menu-dark-danger-color: #FF4D4FFF;   // Colors/Dust Red/Light/50
  --theme-colors-menu-dark-bg: #001529FF;   // NCX/Menu
  --theme-colors-menu-dark-arrow-color: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-menu-dark-inline-submenu-bg: #000C17FF;   // NCX/Inline
  --theme-colors-menu-dark-item-active-bg: #1890FFFF;   // Colors/Daybreak Blue/Light/60
  --theme-colors-menu-dark-item-active-danger-bg: #FF4D4FFF;   // Colors/Dust Red/Light/50
  --theme-colors-menu-dark-selected-item-icon-color: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-menu-dark-selected-item-text-color: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-menu-dark-item-hover-bg: #00000000;   // Theme Colors/Menu/dark-item-hover-bg
  --theme-colors-menu-dark-highlight-color: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-message-notice-content-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-mentions-dropdown-menu-item-hover-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-modal-header-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-modal-header-border-color-split: #0303030F;   // Colors/Grey/Light/6
  --theme-colors-modal-content-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-modal-heading-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-modal-close-color: #03030372;   // Colors/Grey/Light/45
  --theme-colors-modal-footer-bg: #00000000;   // Theme Colors/Modal/footer-bg
  --theme-colors-modal-footer-border-color-split: #0303030F;   // Colors/Grey/Light/6
  --theme-colors-modal-mask-bg: #030303B2;   // Colors/Grey/Light/75
  --theme-colors-normal-color: #D9D9D9FF;   // Theme Colors/Normal/Color
  --theme-colors-notifications-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-radio-solid-checked-color: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-radio-dot-color: #1890FFFF;   // Colors/Daybreak Blue/Light/60
  --theme-colors-radio-button-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-radio-button-checked-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-radio-button-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-radio-button-hover-color: #40A9FFFF;   // Colors/Daybreak Blue/Light/50
  --theme-colors-radio-button-active-color: #096DD9FF;   // Colors/Daybreak Blue/Light/70
  --theme-colors-radio-button-disabled-checked-bg: #03030319;   // Colors/Grey/Light/10
  --theme-colors-radio-button-disabled-button-checked-color: #0303033F;   // Colors/Grey/Light/25
  --theme-colors-radio-dot-disabled-color: #03030333;   // Colors/Grey/Light/20
  --theme-colors-rate-star-color: #FADB14FF;   // Colors/Yellow/Light/60
  --theme-colors-rate-star-bg: #0303030F;   // Colors/Grey/Light/6
  --theme-colors-segmented-bg: #0303030A;   // Colors/Grey/Light/0
  --theme-colors-segmented-hover-bg: #0303030F;   // Colors/Grey/Light/6
  --theme-colors-segmented-selected-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-segmented-label-color: #030303A5;   // Colors/Grey/Light/65
  --theme-colors-segmented-label-hover-color: #262626FF;   // Colors/Black/7
  --theme-colors-select-border-color: #03030326;   // Colors/Grey/Light/15
  --theme-colors-select-item-selected-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-select-dropdown-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-select-item-selected-bg: #E6F7FFFF;   // Colors/Daybreak Blue/Light/10
  --theme-colors-select-item-active-bg: #F5F5F5FF;   // Colors/White/1
  --theme-colors-select-background: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-select-clear-background: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-select-selection-item-bg: #0303030A;   // Colors/Grey/Light/0
  --theme-colors-select-selection-item-border-color: #0303030F;   // Colors/Grey/Light/6
  --theme-colors-select-multiple-disabled-baclground: #0303030A;   // Colors/Grey/Light/0
  --theme-colors-select-multiple-item-disabled-color: #BFBFBFFF;   // Colors/White/4
  --theme-colors-select-multiple-item-disabled-border-color: #03030326;   // Colors/Grey/Light/15
  --theme-colors-shadow-color: #03030326;   // Colors/Grey/Light/15
  --theme-colors-skeleton-color: #BEBEBEFF;   // Theme Colors/Skeleton/color
  --theme-colors-slider-rail-background-color: #0303030A;   // Colors/Grey/Light/0
  --theme-colors-slider-rail-background-color-hover: #E1E1E1FF;   // Colors/White/8
  --theme-colors-slider-track-background-color: #91D5FFFF;   // Colors/Daybreak Blue/Light/30
  --theme-colors-slider-track-background-color-hover: #69C0FFFF;   // Colors/Daybreak Blue/Light/40
  --theme-colors-slider-handle-background-color: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-slider-handle-color: #91D5FFFF;   // Colors/Daybreak Blue/Light/30
  --theme-colors-slider-handle-color-hover: #85A5FFFF;   // Colors/Geek Blue/Light/40
  --theme-colors-slider-handle-color-tooltip-open: #1890FFFF;   // Colors/Daybreak Blue/Light/60
  --theme-colors-slider-dor-border-color: #0303030F;   // Colors/Grey/Light/6
  --theme-colors-slider-disabled-color: #0303033F;   // Colors/Grey/Light/25
  --theme-colors-slider-disabled-background-color: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-shadow-color-inverse: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-solid-checked-color: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-status-new-default: #EB2F96FF;   // Colors/Magenta/Light/60
  --theme-colors-status-new-background: #FFF0F6FF;   // Colors/Magenta/Light/10
  --theme-colors-status-new-border: #FFADD2FF;   // Colors/Magenta/Light/30
  --theme-colors-status-in-progress-default: #1890FFFF;   // Colors/Daybreak Blue/Light/60
  --theme-colors-status-in-progress-background: #E6F7FFFF;   // Colors/Daybreak Blue/Light/10
  --theme-colors-status-in-progress-border: #91D5FFFF;   // Colors/Daybreak Blue/Light/30
  --theme-colors-status-revised-default: #FA8C16FF;   // Colors/Orange/Light/60
  --theme-colors-status-revised-background: #FFF7E6FF;   // Colors/Orange/Light/10
  --theme-colors-status-revised-border: #FFD591FF;   // Colors/Orange/Light/30
  --theme-colors-status-completed-default: #52C41AFF;   // Colors/Green/Light/60
  --theme-colors-status-completed-background: #F6FFEDFF;   // Colors/Green/Light/10
  --theme-colors-status-completed-border: #B7EB8FFF;   // Colors/Green/Light/30
  --theme-colors-status-concan-default: #03030372;   // Colors/Grey/Light/45
  --theme-colors-status-concan-background: #03030319;   // Colors/Grey/Light/10
  --theme-colors-status-concan-border: #0303033F;   // Colors/Grey/Light/25
  --theme-colors-status-cancelled-default: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-status-cancelled-background: #000000FF;   // Colors/Black/1
  --theme-colors-status-cancelled-border: #030303A5;   // Colors/Grey/Light/65
  --theme-colors-steps-background: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-success-color: #52C41AFF;   // Colors/Green/Light/60
  --theme-colors-switch-color: #1890FFFF;   // Colors/Daybreak Blue/Light/60
  --theme-colors-switch-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-success-color-hover: #73D13DFF;   // Colors/Green/Light/50
  --theme-colors-success-color-active: #389E0DFF;   // Colors/Green/Light/70
  --theme-colors-success-color-outline: #D9F7BEFF;   // Colors/Green/Light/20
  --theme-colors-success-color-deprecated-bg: #F6FFEDFF;   // Colors/Green/Light/10
  --theme-colors-success-color-deprecated-border: #B7EB8FFF;   // Colors/Green/Light/30
  --theme-colors-table-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-tabs-card-head-background: #03030305;   // Colors/Grey/Light/2
  --theme-colors-tabs-card-active-color: #1890FFFF;   // Colors/Daybreak Blue/Light/60
  --theme-colors-tabs-ink-bar-color: #1890FFFF;   // Colors/Daybreak Blue/Light/60
  --theme-colors-tabs-highlight-color: #1890FFFF;   // Colors/Daybreak Blue/Light/60
  --theme-colors-tabs-hover-color: #40A9FFFF;   // Colors/Daybreak Blue/Light/50
  --theme-colors-tabs-active-color: #096DD9FF;   // Colors/Daybreak Blue/Light/70
  --theme-colors-table-header-bg: #03030305;   // Colors/Grey/Light/2
  --theme-colors-table-header-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-table-header-sort-bg: #0303030A;   // Colors/Grey/Light/0
  --theme-colors-table-body-sort-bg: #FAFAFAFF;   // Colors/White/2
  --theme-colors-table-row-hover-bg: #0303030A;   // Colors/Grey/Light/0
  --theme-colors-table-selected-row-bg: #E6F7FFFF;   // Colors/Daybreak Blue/Light/10
  --theme-colors-table-body-selected-sort-bg: #E6F7FFFF;   // Colors/Daybreak Blue/Light/10
  --theme-colors-table-expand-row-bg: #FBFBFBFF;   // Colors/White/6
  --theme-colors-table-border-color: #0303030F;   // Colors/Grey/Light/6
  --theme-colors-table-footer-bg: #0303030A;   // Colors/Grey/Light/0
  --theme-colors-table-footer-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-table-header-bg-sm: #03030305;   // Colors/Grey/Light/2
  --theme-colors-table-header-cell-split-color: #0303030F;   // Colors/Grey/Light/6
  --theme-colors-table-header-sort-active-bg: #0303030A;   // Colors/Grey/Light/0
  --theme-colors-table-fixed-header-sort-active-bg: #FFFFFFF4;   // Colors/Grey/Dark/96
  --theme-colors-table-header-filter-active-bg: #0303030A;   // Colors/Grey/Light/0
  --theme-colors-table-filter-dropdown-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-table-expand-icon-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-table-sticky-scroll-bar-bg: #03030359;   // Colors/Grey/Light/35
  --theme-colors-tag-default-bg: #03030305;   // Colors/Grey/Light/2
  --theme-colors-tag-default-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-text-color: #030303D8;   // Colors/Grey/Light/85
  --theme-colors-timeline-color: #0303030F;   // Colors/Grey/Light/6
  --theme-colors-tooltip-color: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-transfer-disabled-bg: #0303030A;   // Colors/Grey/Light/0
  --theme-colors-transfer-item-hover-bg: #F5F5F5FF;   // Colors/White/1
  --theme-colors-tooltip-bg: #1D1D1DFF;   // Colors/Black/8
  --theme-colors-tooltip-arrow-color: #1D1D1DFF;   // Colors/Black/8
  --theme-colors-timeline-dot-color: #1890FFFF;   // Colors/Daybreak Blue/Light/60
  --theme-colors-timeline-dot-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-text-color-secondary: #0303038C;   // Colors/Grey/Light/55
  --theme-colors-text-color-inverse: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-text-selection-background: #1890FFFF;   // Colors/Daybreak Blue/Light/60
  --theme-colors-tree-bg: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-tree-directory-selected-color: #FFFFFFFF;   // Colors/White/Color
  --theme-colors-tree-directory-selected-bg: #1890FFFF;   // Colors/Daybreak Blue/Light/60
  --theme-colors-tree-node-hover-bg: #F5F5F5FF;   // Colors/White/1
  --theme-colors-tree-node-selected-bg: #BAE7FFFF;   // Colors/Daybreak Blue/Light/20
  --theme-colors-upload-actions-color: #03030372;   // Colors/Grey/Light/45
  --theme-colors-warning-color: #FAAD14FF;   // Colors/Gold/Light/60
  --theme-colors-warning-color-hover: #FFC53DFF;   // Colors/Gold/Light/50
  --theme-colors-warning-color-active: #D48806FF;   // Colors/Gold/Light/70
  --theme-colors-warning-color-outline: #FFF1B8FF;   // Colors/Gold/Light/20
  --theme-colors-warning-color-deprecated-bg: #FFFBE6FF;   // Colors/Gold/Light/10
  --theme-colors-warning-color-deprecated-border: #FFE58FFF;   // Colors/Gold/Light/30
  --layout-body-body-background: #F0F2F5FF;   // Layout/Body/body-background
  --layout-footer-background: #F0F2F5FF;   // Layout/Footer/background
  --layout-header-background: #001529FF;   // Layout/Header/background
  --layout-sider-background: #001529FF;   // Layout/Sider/background
  --layout-sider-background-light: #FFFFFFFF;   // Colors/White/Color
  --layout-trigger-background: #002140FF;   // Layout/Trigger/background
  --layout-trigger-color: #FFFFFFFF;   // Colors/White/Color
  --layout-trigger-background-light: #FFFFFFFF;   // Colors/White/Color
  --layout-trigger-color-light: #030303D8;   // Colors/Grey/Light/85
  --layout-header-header-color: #030303D8;   // Colors/Grey/Light/85
  --layout-nav-ncx-menu: #001529FF;   // NCX/Menu
  --layout-nav-ncx-inline: #000C17FF;   // NCX/Inline
  --layout-nav-search-menu: var(--global-colors-purple-100);   // Search/Menu
  --layout-nav-search-inline: #000C17FF;   // Search/Inline
  --theme-colors-text-dark: #FFFFFFD8;   // Colors/Grey/Dark/85
  --layout-header-text-main-color: #030303D8;   // Colors/Grey/Light/85
  --layout-header-text-secondary: #030303D8;   // Colors/Grey/Light/85 
  
  &.dark	{
    --global-colors-grey-0: #FFFFFF0A;   // Colors/Grey/Dark/0
    --global-colors-grey-2: #FFFFFF05;   // Colors/Grey/Dark/2
    --global-colors-grey-6: #FFFFFF0F;   // Colors/Grey/Dark/6
    --global-colors-grey-8: #FFFFFF14;   // Colors/Grey/Dark/8
    --global-colors-grey-10: #FFFFFF19;   // Colors/Grey/Dark/10
    --global-colors-grey-15: #FFFFFF26;   // Colors/Grey/Dark/15
    --global-colors-grey-20: #FFFFFF33;   // Colors/Grey/Dark/20
    --global-colors-grey-25: #FFFFFF3F;   // Colors/Grey/Dark/25
    --global-colors-grey-30: #FFFFFF4C;   // Colors/Grey/Dark/30
    --global-colors-grey-35: #FFFFFF59;   // Colors/Grey/Dark/35
    --global-colors-grey-40: #FFFFFF66;   // Colors/Grey/Dark/40
    --global-colors-grey-45: #FFFFFF72;   // Colors/Grey/Dark/45
    --global-colors-grey-50: #FFFFFF7F;   // Colors/Grey/Dark/50
    --global-colors-grey-55: #FFFFFF8C;   // Colors/Grey/Dark/55
    --global-colors-grey-60: #FFFFFF99;   // Colors/Grey/Dark/60
    --global-colors-grey-65: #FFFFFFA5;   // Colors/Grey/Dark/65
    --global-colors-grey-70: #FFFFFFB2;   // Colors/Grey/Dark/70
    --global-colors-grey-75: #FFFFFFBF;   // Colors/Grey/Dark/75
    --global-colors-grey-80: #FFFFFFCC;   // Colors/Grey/Dark/80
    --global-colors-grey-85: #FFFFFFD8;   // Colors/Grey/Dark/85
    --global-colors-grey-90: #FFFFFFE5;   // Colors/Grey/Dark/90
    --global-colors-grey-96: #FFFFFFF4;   // Colors/Grey/Dark/96
    --global-colors-grey-100: #FFFFFFFF;   // Colors/Grey/Dark/100
    --global-colors-blue-main-10: #131629FF;   // Colors/Geek Blue/Dark/10
    --global-colors-cyan-10: #112123FF;   // Colors/Cyan/Dark/10
    --global-colors-cyan-20: #113536FF;   // Colors/Cyan/Dark/20
    --global-colors-cyan-30: #144848FF;   // Colors/Cyan/Dark/30
    --global-colors-cyan-40: #146262FF;   // Colors/Cyan/Dark/40
    --global-colors-cyan-50: #138585FF;   // Colors/Cyan/Dark/50
    --global-colors-cyan-60: #13A8A8FF;   // Colors/Cyan/Dark/60
    --global-colors-cyan-70: #33BCB7FF;   // Colors/Cyan/Dark/70
    --global-colors-cyan-80: #58D1C9FF;   // Colors/Cyan/Dark/80
    --global-colors-cyan-90: #84E2D8FF;   // Colors/Cyan/Dark/90
    --global-colors-cyan-100: #B2F1E8FF;   // Colors/Cyan/Dark/100
    --global-colors-blue-main-20: #161D40FF;   // Colors/Geek Blue/Dark/20
    --global-colors-blue-main-30: #1C2755FF;   // Colors/Geek Blue/Dark/30
    --global-colors-blue-main-40: #203175FF;   // Colors/Geek Blue/Dark/40
    --global-colors-blue-main-50: #263EA0FF;   // Colors/Geek Blue/Dark/50
    --global-colors-blue-main-60: #2B4ACBFF;   // Colors/Geek Blue/Dark/60
    --global-colors-blue-main-70: #5273E0FF;   // Colors/Geek Blue/Dark/70
    --global-colors-blue-main-80: #7F9EF3FF;   // Colors/Geek Blue/Dark/80
    --global-colors-blue-main-90: #A8C1F8FF;   // Colors/Geek Blue/Dark/90
    --global-colors-blue-main-100: #B7DCFAFF;   // Colors/Geek Blue/Dark/100
    --global-colors-blue-secondary-10: #111A2CFF;   // Colors/Daybreak Blue/Dark/10
    --global-colors-blue-secondary-20: #112545FF;   // Colors/Daybreak Blue/Dark/20
    --global-colors-blue-secondary-30: #15325BFF;   // Colors/Daybreak Blue/Dark/30
    --global-colors-blue-secondary-40: #15417EFF;   // Colors/Daybreak Blue/Dark/40
    --global-colors-blue-secondary-50: #1554ADFF;   // Colors/Daybreak Blue/Dark/50
    --global-colors-blue-secondary-60: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --global-colors-blue-secondary-70: #3C89E8FF;   // Colors/Daybreak Blue/Dark/70
    --global-colors-blue-secondary-80: #65A9F3FF;   // Colors/Daybreak Blue/Dark/80
    --global-colors-blue-secondary-90: #8DC5F8FF;   // Colors/Daybreak Blue/Dark/90
    --global-colors-blue-secondary-100: #B7DCFAFF;   // Colors/Daybreak Blue/Dark/100
    --global-colors-green-main-10: #162312FF;   // Colors/Green/Dark/10
    --global-colors-green-main-20: #1D3712FF;   // Colors/Green/Dark/20
    --global-colors-green-main-30: #274916FF;   // Colors/Green/Dark/30
    --global-colors-green-main-40: #306317FF;   // Colors/Green/Dark/40
    --global-colors-green-main-50: #3C8618FF;   // Colors/Green/Dark/50
    --global-colors-green-main-60: #49AA19FF;   // Colors/Green/Dark/60
    --global-colors-green-main-70: #6ABE39FF;   // Colors/Green/Dark/70
    --global-colors-green-main-80: #8FD460FF;   // Colors/Green/Dark/80
    --global-colors-green-main-90: #B2E58BFF;   // Colors/Green/Dark/90
    --global-colors-green-main-100: #D5F2BBFF;   // Colors/Green/Dark/100
    --global-colors-green-secondary-10: #1F2611FF;   // Colors/Lime/Dark/10
    --global-colors-green-secondary-20: #2E3C10FF;   // Colors/Lime/Dark/20
    --global-colors-green-secondary-30: #3E4F13FF;   // Colors/Lime/Dark/30
    --global-colors-green-secondary-40: #536D13FF;   // Colors/Lime/Dark/40
    --global-colors-green-secondary-50: #6F9412FF;   // Colors/Lime/Dark/50
    --global-colors-green-secondary-60: #8BBB11FF;   // Colors/Lime/Dark/60
    --global-colors-green-secondary-70: #A9D134FF;   // Colors/Lime/Dark/70
    --global-colors-green-secondary-80: #C9E75DFF;   // Colors/Lime/Dark/80
    --global-colors-green-secondary-90: #E4F88BFF;   // Colors/Lime/Dark/90
    --global-colors-green-secondary-100: #F0FAB5FF;   // Colors/Lime/Dark/100
    --global-colors-magenta-10: #291321FF;   // Colors/Magenta/Dark/10
    --global-colors-magenta-20: #40162FFF;   // Colors/Magenta/Dark/20
    --global-colors-magenta-30: #551C3BFF;   // Colors/Magenta/Dark/30
    --global-colors-magenta-40: #75204FFF;   // Colors/Magenta/Dark/40
    --global-colors-magenta-50: #A02669FF;   // Colors/Magenta/Dark/50
    --global-colors-magenta-60: #CB2B83FF;   // Colors/Magenta/Dark/60
    --global-colors-magenta-70: #E0529CFF;   // Colors/Magenta/Dark/70
    --global-colors-magenta-80: #F37FB7FF;   // Colors/Magenta/Dark/80
    --global-colors-magenta-90: #F8A8CCFF;   // Colors/Magenta/Dark/90
    --global-colors-magenta-100: #FAD2E3FF;   // Colors/Magenta/Dark/100
    --global-colors-orange-10: #2B1D11FF;   // Colors/Orange/Dark/10
    --global-colors-orange-20: #442A11FF;   // Colors/Orange/Dark/20
    --global-colors-orange-30: #593815FF;   // Colors/Orange/Dark/30
    --global-colors-orange-40: #7C4A15FF;   // Colors/Orange/Dark/40
    --global-colors-orange-50: #AA6215FF;   // Colors/Orange/Dark/50
    --global-colors-orange-60: #D87A16FF;   // Colors/Orange/Dark/60
    --global-colors-orange-70: #E89A3CFF;   // Colors/Orange/Dark/70
    --global-colors-orange-80: #F3B765FF;   // Colors/Orange/Dark/80
    --global-colors-orange-90: #F8CF8DFF;   // Colors/Orange/Dark/90
    --global-colors-orange-100: #FAE3B7FF;   // Colors/Orange/Dark/100
    --global-colors-purple-10: #1A1325FF;   // Colors/Golden Purple/Dark/10
    --global-colors-purple-20: #24163AFF;   // Colors/Golden Purple/Dark/20
    --global-colors-purple-30: #301C4DFF;   // Colors/Golden Purple/Dark/30
    --global-colors-purple-40: #3E2069FF;   // Colors/Golden Purple/Dark/40
    --global-colors-purple-50: #51258FFF;   // Colors/Golden Purple/Dark/50
    --global-colors-purple-60: #642AB5FF;   // Colors/Golden Purple/Dark/60
    --global-colors-purple-70: #854ECAFF;   // Colors/Golden Purple/Dark/70
    --global-colors-purple-80: #AB7AE0FF;   // Colors/Golden Purple/Dark/80
    --global-colors-purple-90: #CDA8F0FF;   // Colors/Golden Purple/Dark/90
    --global-colors-purple-100: #EBD7FAFF;   // Colors/Golden Purple/Dark/100
    --global-colors-red-main-10: #2A1215FF;   // Colors/Dust Red/Dark/10
    --global-colors-red-main-20: #431418FF;   // Colors/Dust Red/Dark/20
    --global-colors-red-main-30: #58181CFF;   // Colors/Dust Red/Dark/30
    --global-colors-red-main-40: #791A1FFF;   // Colors/Dust Red/Dark/40
    --global-colors-red-main-50: #A61D24FF;   // Colors/Dust Red/Dark/50
    --global-colors-red-main-60: #D32029FF;   // Colors/Dust Red/Dark/60
    --global-colors-red-main-70: #E84749FF;   // Colors/Dust Red/Dark/70
    --global-colors-red-main-80: #F37370FF;   // Colors/Dust Red/Dark/80
    --global-colors-red-main-90: #F89F9AFF;   // Colors/Dust Red/Dark/90
    --global-colors-red-main-100: #FAC8C3FF;   // Colors/Dust Red/Dark/100
    --global-colors-red-secondary-10: #2B1611FF;   // Colors/Volcano Red/Dark/10
    --global-colors-red-secondary-20: #441D12FF;   // Colors/Volcano Red/Dark/20
    --global-colors-red-secondary-30: #592716FF;   // Colors/Volcano Red/Dark/30
    --global-colors-red-secondary-40: #7C3118FF;   // Colors/Volcano Red/Dark/40
    --global-colors-red-secondary-50: #AA3E19FF;   // Colors/Volcano Red/Dark/50
    --global-colors-red-secondary-60: #D84A1BFF;   // Colors/Volcano Red/Dark/60
    --global-colors-red-secondary-70: #E87040FF;   // Colors/Volcano Red/Dark/70
    --global-colors-red-secondary-80: #F3956AFF;   // Colors/Volcano Red/Dark/80
    --global-colors-red-secondary-90: #F8B692FF;   // Colors/Volcano Red/Dark/90
    --global-colors-red-secondary-100: #FAD4BCFF;   // Colors/Volcano Red/Dark/100
    --global-colors-yellow-main-10: #2B2611FF;   // Colors/Yellow/Dark/10
    --global-colors-yellow-main-20: #443B11FF;   // Colors/Yellow/Dark/20
    --global-colors-yellow-main-30: #595014FF;   // Colors/Yellow/Dark/30
    --global-colors-yellow-main-40: #7C6E14FF;   // Colors/Yellow/Dark/40
    --global-colors-yellow-main-50: #AA9514FF;   // Colors/Yellow/Dark/50
    --global-colors-yellow-main-60: #D8BD14FF;   // Colors/Yellow/Dark/60
    --global-colors-yellow-main-70: #E8D639FF;   // Colors/Yellow/Dark/70
    --global-colors-yellow-main-80: #F3EA62FF;   // Colors/Yellow/Dark/80
    --global-colors-yellow-main-90: #F8F48BFF;   // Colors/Yellow/Dark/90
    --global-colors-yellow-main-100: #FAFAB5FF;   // Colors/Yellow/Dark/100
    --global-colors-yellow-secondary-10: #2B2111FF;   // Colors/Gold/Dark/10
    --global-colors-yellow-secondary-20: #443111FF;   // Colors/Gold/Dark/20
    --global-colors-yellow-secondary-30: #594214FF;   // Colors/Gold/Dark/30
    --global-colors-yellow-secondary-40: #7C5914FF;   // Colors/Gold/Dark/40
    --global-colors-yellow-secondary-50: #AA7714FF;   // Colors/Gold/Dark/50
    --global-colors-yellow-secondary-60: #D89614FF;   // Colors/Gold/Dark/60
    --global-colors-yellow-secondary-70: #E8B339FF;   // Colors/Gold/Dark/70
    --global-colors-yellow-secondary-80: #F3CC62FF;   // Colors/Gold/Dark/80
    --global-colors-yellow-secondary-90: #F8DF8BFF;   // Colors/Gold/Dark/90
    --global-colors-yellow-secondary-100: #FAEDB5FF;   // Colors/Gold/Dark/100
    --theme-colors-alert-success-border-color: #274916FF;   // Colors/Green/Dark/30
    --theme-colors-alert-success-bg-color: #162312FF;   // Colors/Green/Dark/10
    --theme-colors-alert-success-icon-color: #49AA19FF;   // Colors/Green/Dark/60
    --theme-colors-alert-info-border-color: #15325BFF;   // Colors/Daybreak Blue/Dark/30
    --theme-colors-alert-info-bg-color: #111A2CFF;   // Colors/Daybreak Blue/Dark/10
    --theme-colors-alert-info-icon-color: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-alert-warning-border-color: #594214FF;   // Colors/Gold/Dark/30
    --theme-colors-alert-warning-bg-color: #2B2111FF;   // Colors/Gold/Dark/10
    --theme-colors-alert-warning-icon-color: #D89614FF;   // Colors/Gold/Dark/60
    --theme-colors-alert-error-border-color: #58181CFF;   // Colors/Dust Red/Dark/30
    --theme-colors-alert-error-bg-color: #2A1215FF;   // Colors/Dust Red/Dark/10
    --theme-colors-alert-error-icon-color: #A61D24FF;   // Colors/Dust Red/Dark/50
    --theme-colors-alert-message-color: #FFFFFFD8;   // Colors/Grey/Dark/85
    --theme-colors-alert-message-text-color: #FFFFFFD8;   // Colors/Grey/Dark/85
    --theme-colors-alert-message-close-out: #FFFFFF72;   // Colors/Grey/Dark/45
    --theme-colors-alert-message-close-hover-color: #FFFFFFBF;   // Colors/Grey/Dark/75
    --theme-colors-anchor-bg: #00000000;   // Theme Colors/Anchor/bg
    --theme-colors-avatar-bg: #424242FF;   // Colors/Black/3
    --theme-colors-avatar-color: #000000FF;   // Colors/Black/1
    --theme-colors-avatar-group-border-color: #000000FF;   // Colors/Black/1
    --theme-colors-anchor-border-color: #FFFFFF0F;   // Colors/Grey/Dark/6
    --theme-colors-back-top-color: #000000FF;   // Colors/Black/1
    --theme-colors-back-top-bg: #FFFFFF72;   // Colors/Grey/Dark/45
    --theme-colors-back-top-hover-bg: #FFFFFFD8;   // Colors/Grey/Dark/85
    --theme-colors-background-color-light: #FFFFFF0A;   // Colors/Grey/Dark/0
    --theme-colors-background-color-base: #FFFFFF14;   // Colors/Grey/Dark/8
    --theme-colors-background-color-card: #1D1D1DFF;   // Colors/Black/8
    --theme-colors-background-color-rhd: #1D1D1DFF;   // Colors/Black/8
    --theme-colors-background-color--rhd---alt: #131629FF;   // Colors/Geek Blue/Dark/10
    --theme-colors-badge-text-color: #FFFFFFFF;   // Colors/White/Color
    --theme-colors-badge-badge-color: #E84749FF;   // Colors/Dust Red/Dark/70
    --theme-colors-body-background: #141414FF;   // Colors/Black/2
    --theme-colors-border-color-base: #424242FF;   // Colors/Black/3
    --theme-colors-bread-crumb-base-color: #FFFFFF72;   // Colors/Grey/Dark/45
    --theme-colors-bread-crumb-last-item-color: #FFFFFFD8;   // Colors/Grey/Dark/85
    --theme-colors-bread-crumb-link-color: #FFFFFFFF;   // Colors/White/Color
    --theme-colors-bread-crumb-link-color-hover: #FFFFFF72;   // Colors/Grey/Dark/45
    --theme-colors-bread-crumb-separator-color: #FFFFFF72;   // Colors/Grey/Dark/45
    --theme-colors-button-bg: #141414FF;   // Colors/Black/2
    --theme-colors-button-check-bg: #141414FF;   // Colors/Black/2
    --theme-colors-button-button-color: #FFFFFFD8;   // Colors/Grey/Dark/85
    --theme-colors-button-button-hover-color: #3C89E8FF;   // Colors/Daybreak Blue/Dark/70
    --theme-colors-button-button-active-color: #1554ADFF;   // Colors/Daybreak Blue/Dark/50
    --theme-colors-button-disabled-button-checked-bg: #FFFFFF19;   // Colors/Grey/Dark/10
    --theme-colors-button-disabled-button-checked-color: #FFFFFF3F;   // Colors/Grey/Dark/25
    --theme-colors-border-color-split: #FFFFFF0F;   // Colors/Grey/Dark/6
    --theme-colors-border-color-inverse: #303030FF;   // Colors/Black/4
    --theme-colors-buttons-primary-primary-color: #FFFFFFFF;   // Colors/White/Color
    --theme-colors-calendar-bg: #141414FF;   // Colors/Black/2
    --theme-colors-card-head-color: #FFFFFFD8;   // Colors/Grey/Dark/85
    --theme-colors-cascader-bg: #141414FF;   // Colors/Black/2
    --theme-colors-cascader-item-selected-bg: #111A2CFF;   // Colors/Daybreak Blue/Dark/10
    --theme-colors-cascader-menu-bg: #1F1F1FFF;   // Colors/Black/5
    --theme-colors-cascader-menu-border-color-split: #FFFFFF0F;   // Colors/Grey/Dark/6
    --theme-colors-card-header-background: #00000000;   // Theme Colors/Card/header-background
    --theme-colors-card-actions-background: #000000FF;   // Colors/Black/1
    --theme-colors-card-background: #141414FF;   // Colors/Black/2
    --theme-colors-card-head-extra-color: #030303D8;   // Colors/Grey/Light/85
    --theme-colors-calendar-input-bg: #141414FF;   // Colors/Black/2
    --theme-colors-calendar-border-color: #424242FF;   // Colors/Black/3
    --theme-colors-calendar-item-active-bg: #111A2CFF;   // Colors/Daybreak Blue/Dark/10
    --theme-colors-calendar-column-active-bg: #E6F7FF33;   // Theme Colors/Calendar/column-active-bg
    --theme-colors-calendar-full-bg: #FFFFFF3F;   // Colors/Grey/Dark/25
    --theme-colors-calendar-full-panel-bg: #FFFFFF14;   // Colors/Grey/Dark/8
    --theme-colors-buttons-primary-bg: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-buttons-default-color: #FFFFFFFF;   // Colors/White/Color
    --theme-colors-buttons-default-bg: #141414FF;   // Colors/Black/2
    --theme-colors-buttons-default-border: #424242FF;   // Colors/Black/3
    --theme-colors-buttons-danger-color: #FFFFFFFF;   // Colors/White/Color
    --theme-colors-buttons-danger-bg: #D32029FF;   // Colors/Dust Red/Dark/60
    --theme-colors-buttons-danger-border: #E84749FF;   // Colors/Dust Red/Dark/70
    --theme-colors-buttons-disable-color: #FFFFFF3F;   // Colors/Grey/Dark/25
    --theme-colors-buttons-disable-bg: #FFFFFF0A;   // Colors/Grey/Dark/0
    --theme-colors-buttons-disable-border: #FFFFFF26;   // Colors/Grey/Dark/15
    --theme-colors-buttons-default-ghost-color: #FFFFFFFF;   // Colors/White/Color
    --theme-colors-buttons-default-ghost-bg: #00000000;   // Theme Colors/Buttons/Default Ghost/bg
    --theme-colors-buttons-default-ghost-border: #424242FF;   // Colors/Black/3
    --theme-colors-buttons-group-border: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-buttons-link-hover-bg: #00000000;   // Theme Colors/Buttons/Link/hover-bg
    --theme-colors-buttons-text-hover-bg: #FFFFFF26;   // Colors/Grey/Dark/15
    --theme-colors-checkbox-color: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-checkbox-check-color: #FFFFFFFF;   // Colors/White/Color
    --theme-colors-checkbox-check-bg: #141414FF;   // Colors/Black/2
    --theme-colors-collapse-header-bg: #FFFFFF0A;   // Colors/Grey/Dark/0
    --theme-colors-collapse-content-bg: #FFFFFF0A;   // Colors/Grey/Dark/0
    --theme-colors-comment-author-name-color: #FFFFFF72;   // Colors/Grey/Dark/45
    --theme-colors-comment-author-time-color: #CCCCCCFF;   // Colors/White/7
    --theme-colors-comment-action-color: #FFFFFF72;   // Colors/Grey/Dark/45
    --theme-colors-comment-action-hover-color: #595959FF;   // Colors/Black/6
    --theme-colors-component-background: #141414FF;   // Colors/Black/2
    --theme-colors-descriptions-bg: #FFFFFF0A;   // Colors/Grey/Dark/0
    --theme-colors-descriptions-extra-color: #030303D8;   // Colors/Grey/Light/85
    --theme-colors-disabled-color: #FFFFFF3F;   // Colors/Grey/Dark/25
    --theme-colors-disabled-bg: #FFFFFF0A;   // Colors/Grey/Dark/0
    --theme-colors-disabled-active-bg: #FFFFFF19;   // Colors/Grey/Dark/10
    --theme-colors-disabled-color-dark: #FFFFFF4C;   // Colors/Grey/Dark/30
    --theme-colors-divider-bg: #FFFFFF0F;   // Colors/Grey/Dark/6
    --theme-colors-dot-dot-color: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-dot-dot-disabled-color: #FFFFFF33;   // Colors/Grey/Dark/20
    --theme-colors-drawer-bg: #1F1F1FFF;   // Colors/Black/5
    --theme-colors-dropdown-selected-color: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-dropdown-menu-submenu-disabled-bg: #FFFFFFFF;   // Colors/White/Color
    --theme-colors-dropdown-selected-bg: #111A2CFF;   // Colors/Daybreak Blue/Dark/10
    --theme-colors-dropdown-menu-bg: #1F1F1FFF;   // Colors/Black/5
    --theme-colors-empty-img-default-elipse: #141414FF;   // Colors/Black/2
    --theme-colors-empty-img-default-path-1: #1D1D1DFF;   // Colors/Black/8
    --theme-colors-empty-img-default-path-3: #000000FF;   // Colors/Black/1
    --theme-colors-empty-img-default-path-4: #424242FF;   // Colors/Black/3
    --theme-colors-empty-img-default-path-5: #141414FF;   // Colors/Black/2
    --theme-colors-error-color: #E84749FF;   // Colors/Dust Red/Dark/70
    --theme-colors-form-warning-input-bg: #141414FF;   // Colors/Black/2
    --theme-colors-form-error-input-bg: #141414FF;   // Colors/Black/2
    --theme-colors-error-color-hover: #E84749FF;   // Colors/Dust Red/Dark/70
    --theme-colors-error-color-active: #791A1FFF;   // Colors/Dust Red/Dark/40
    --theme-colors-error-color-outline: #58181CFF;   // Colors/Dust Red/Dark/30
    --theme-colors-error-color-deprecated-bg: #2A1215FF;   // Colors/Dust Red/Dark/10
    --theme-colors-error-color-deprecated-border: #58181CFF;   // Colors/Dust Red/Dark/30
    --theme-colors-header-color: #FFFFFFD8;   // Colors/Grey/Dark/85
    --theme-colors-header-component-header-color: #65A9F3FF;   // Colors/Daybreak Blue/Dark/80
    --theme-colors-highlight-color: #A61D24FF;   // Colors/Dust Red/Dark/50
    --theme-colors-highlight-search-return-bg: #CDA8F0FF;   // Colors/Golden Purple/Dark/90
    --theme-colors-highlight-search-return-color: #301C4DFF;   // Colors/Golden Purple/Dark/30
    --theme-colors-icon-color: #FFFFFFCC;   // Colors/Grey/Dark/80
    --theme-colors-icon-secondary: #FFFFFF3F;   // Colors/Grey/Dark/25
    --theme-colors-images-bg: #FFFFFF26;   // Colors/Grey/Dark/15
    --theme-colors-images-color: #000000FF;   // Colors/Black/1
    --theme-colors-images-preview-operation-color: #030303D8;   // Colors/Grey/Light/85
    --theme-colors-info-color: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-input-placeholder-color: #FFFFFF3F;   // Colors/Grey/Dark/25
    --theme-colors-input-color: #FFFFFFD8;   // Colors/Grey/Dark/85
    --theme-colors-input-icon-color: #FFFFFFD8;   // Colors/Grey/Dark/85
    --theme-colors-input-border-color: #424242FF;   // Colors/Black/3
    --theme-colors-input-bg: #141414FF;   // Colors/Black/2
    --theme-colors-input-number-hover-border-color: #1554ADFF;   // Colors/Daybreak Blue/Dark/50
    --theme-colors-input-number-handler-active-bg: #F4F4F4FF;   // Colors/White/3
    --theme-colors-input-number-handler-hover-bg: #3C89E8FF;   // Colors/Daybreak Blue/Dark/70
    --theme-colors-input-number-handler-bg: #141414FF;   // Colors/Black/2
    --theme-colors-input-number-handler-border-color: #FFFFFF26;   // Colors/Grey/Dark/15
    --theme-colors-input-addon-bg: #FFFFFF0A;   // Colors/Grey/Dark/0
    --theme-colors-input-hover-border-color: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-input-disabled-bg: #FFFFFF0A;   // Colors/Grey/Dark/0
    --theme-colors-input-icon-hover-color: #FFFFFFD8;   // Colors/Grey/Dark/85
    --theme-colors-input-disabled-color: #FFFFFF3F;   // Colors/Grey/Dark/25
    --theme-colors-input-read-only: #141414FF;   // Colors/Black/2
    --theme-colors-info-color-deprecated-bg: #111A2CFF;   // Colors/Daybreak Blue/Dark/10
    --theme-colors-info-color-deprecated-border: #15325BFF;   // Colors/Daybreak Blue/Dark/30
    --theme-colors-item-active-bg: #111A2CFF;   // Colors/Daybreak Blue/Dark/10
    --theme-colors-item-hover-bg: #FFFFFF19;   // Colors/Grey/Dark/10
    --theme-colors-label-required-color: #A61D24FF;   // Colors/Dust Red/Dark/50
    --theme-colors-label-color: #030303D8;   // Colors/Grey/Light/85
    --theme-colors-link-color-base: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-link-hover: #3C89E8FF;   // Colors/Daybreak Blue/Dark/70
    --theme-colors-link-color-split: #3C89E8FF;   // Colors/Daybreak Blue/Dark/70
    --theme-colors-link-color-inverse: #1554ADFF;   // Colors/Daybreak Blue/Dark/50
    --theme-colors-link-active: #1554ADFF;   // Colors/Daybreak Blue/Dark/50
    --theme-colors-list-header-background: #00000000;   // Theme Colors/List/header-background
    --theme-colors-page-header-back-color: #FFFFFF8C;   // Colors/Grey/Dark/55
    --theme-colors-pagination-item-bg: #FFFFFF14;   // Colors/Grey/Dark/8
    --theme-colors-pagination-item-bg-active: #000000FF;   // Colors/Black/1
    --theme-colors-pagination-item-link-bg: #000000FF;   // Colors/Black/1
    --theme-colors-pagination-item-disabled-color-active: #FFFFFF3F;   // Colors/Grey/Dark/25
    --theme-colors-pagination-item-disabled-bg-active: #FFFFFF19;   // Colors/Grey/Dark/10
    --theme-colors-pagination-item-input-bg: #FFFFFF0A;   // Colors/Grey/Dark/0
    --theme-colors-list-footer-background: #00000000;   // Theme Colors/List/footer-background
    --theme-colors-list-customize-card-bg: var(--colors-white-color);   // Theme Colors/List/customize-card-bg
    --theme-colors-picker-bg: #000000FF;   // Colors/Black/1
    --theme-colors-popover-bg: #1F1F1FFF;   // Colors/Black/5
    --theme-colors-popover-color: #FFFFFFD8;   // Colors/Grey/Dark/85
    --theme-colors-popover-arrow-color: #1F1F1FFF;   // Colors/Black/5
    --theme-colors-popover-arrow-outer-color: #141414FF;   // Colors/Black/2
    --theme-colors-popover-background: #141414FF;   // Colors/Black/2
    --theme-colors-popover-customize-border-color: #FFFFFF0F;   // Colors/Grey/Dark/6
    --theme-colors-primary-10: #111A2CFF;   // Colors/Daybreak Blue/Dark/10
    --theme-colors-primary-20: #112545FF;   // Colors/Daybreak Blue/Dark/20
    --theme-colors-primary-30: #15325BFF;   // Colors/Daybreak Blue/Dark/30
    --theme-colors-primary-40: #15417EFF;   // Colors/Daybreak Blue/Dark/40
    --theme-colors-primary-50: #1554ADFF;   // Colors/Daybreak Blue/Dark/50
    --theme-colors-primary-60: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-primary-70: #3C89E8FF;   // Colors/Daybreak Blue/Dark/70
    --theme-colors-primary-80: #65A9F3FF;   // Colors/Daybreak Blue/Dark/80
    --theme-colors-primary-90: #8DC5F8FF;   // Colors/Daybreak Blue/Dark/90
    --theme-colors-primary-100: #B7DCFAFF;   // Colors/Daybreak Blue/Dark/100
    --theme-colors-primary-color: #1554ADFF;   // Colors/Daybreak Blue/Dark/50
    --theme-colors-primary-color-hover: #3C89E8FF;   // Colors/Daybreak Blue/Dark/70
    --theme-colors-primary-color-active: #1554ADFF;   // Colors/Daybreak Blue/Dark/50
    --theme-colors-primary-color-outline: #15325BFF;   // Colors/Daybreak Blue/Dark/30
    --theme-colors-picker-basic-cell-hover-color: #FFFFFF14;   // Colors/Grey/Dark/8
    --theme-colors-picker-basic-cell-active-with-range-color: #111A2CFF;   // Colors/Daybreak Blue/Dark/10
    --theme-colors-picker-basic-cell-disabled-bg: #0303030A;   // Colors/Grey/Light/0
    --theme-colors-picker-border-color: #FFFFFF0F;   // Colors/Grey/Dark/6
    --theme-colors-picker-basic-cell-hover-with-range-color: #3C8618FF;   // Colors/Green/Dark/50
    --theme-colors-picker-date-hover-range-color: #3C8618FF;   // Colors/Green/Dark/50
    --theme-colors-processing-color: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-progress-default-color: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-progress-remaining-color: #FFFFFF0A;   // Colors/Grey/Dark/0
    --theme-colors-progress-info-text-color: #FFFFFFFF;   // Colors/White/Color
    --theme-colors-progress-steps-item-bg: #FFFFFF0F;   // Colors/Grey/Dark/6
    --theme-colors-progress-text-color: #FFFFFFD8;   // Colors/Grey/Dark/85
    --theme-colors-process-tail-color: #FFFFFF0F;   // Colors/Grey/Dark/6
    --theme-colors-mark-bg: #594214FF;   // Colors/Gold/Dark/30
    --theme-colors-mentions-dropdown-bg: #141414FF;   // Colors/Black/2
    --theme-colors-menu-bg: #141414FF;   // Colors/Black/2
    --theme-colors-menu-popup-bg: #141414FF;   // Colors/Black/2
    --theme-colors-menu-item-color: #FFFFFFD8;   // Colors/Grey/Dark/85
    --theme-colors-menu-inline-submenu-bg: #03030305;   // Colors/Grey/Light/2
    --theme-colors-menu-highlight-color: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-menu-highlight-danger-color: #A61D24FF;   // Colors/Dust Red/Dark/50
    --theme-colors-menu-item-active-bg: #111A2CFF;   // Colors/Daybreak Blue/Dark/10
    --theme-colors-menu-item-active-danger-bg: #F37370FF;   // Colors/Dust Red/Dark/80
    --theme-colors-menu-item-group-title-color: #FFFFFF72;   // Colors/Grey/Dark/45
    --theme-colors-menu-dark-color: #FFFFFF99;   // Colors/Grey/Dark/60
    --theme-colors-menu-dark-danger-color: #A61D24FF;   // Colors/Dust Red/Dark/50
    --theme-colors-menu-dark-bg: #141414FF;   // Colors/Black/2
    --theme-colors-menu-dark-arrow-color: #FFFFFFFF;   // Colors/White/Color
    --theme-colors-menu-dark-inline-submenu-bg: #070707FF;   // Colors/Black/9
    --theme-colors-menu-dark-item-active-bg: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-menu-dark-item-active-danger-bg: #A61D24FF;   // Colors/Dust Red/Dark/50
    --theme-colors-menu-dark-selected-item-icon-color: #FFFFFFFF;   // Colors/White/Color
    --theme-colors-menu-dark-selected-item-text-color: #FFFFFFFF;   // Colors/White/Color
    --theme-colors-menu-dark-item-hover-bg: #00000000;   // Theme Colors/Menu/dark-item-hover-bg
    --theme-colors-menu-dark-highlight-color: #FFFFFFFF;   // Colors/White/Color
    --theme-colors-message-notice-content-bg: #1F1F1FFF;   // Colors/Black/5
    --theme-colors-mentions-dropdown-menu-item-hover-bg: #424242FF;   // Colors/Black/3
    --theme-colors-modal-header-bg: #FFFFFFFF;   // Colors/White/Color
    --theme-colors-modal-header-border-color-split: #FFFFFF0F;   // Colors/Grey/Dark/6
    --theme-colors-modal-content-bg: #1F1F1FFF;   // Colors/Black/5
    --theme-colors-modal-heading-color: #FFFFFFFF;   // Colors/White/Color
    --theme-colors-modal-close-color: #FFFFFF72;   // Colors/Grey/Dark/45
    --theme-colors-modal-footer-bg: #00000000;   // Theme Colors/Modal/footer-bg
    --theme-colors-modal-footer-border-color-split: #FFFFFF0F;   // Colors/Grey/Dark/6
    --theme-colors-modal-mask-bg: #03030372;   // Colors/Grey/Light/45
    --theme-colors-normal-color: #FFFFFF3F;   // Colors/Grey/Dark/25
    --theme-colors-notifications-bg: #1F1F1FFF;   // Colors/Black/5
    --theme-colors-radio-solid-checked-color: #141414FF;   // Colors/Black/2
    --theme-colors-radio-dot-color: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-radio-button-bg: #141414FF;   // Colors/Black/2
    --theme-colors-radio-button-checked-bg: #141414FF;   // Colors/Black/2
    --theme-colors-radio-button-color: #FFFFFFD8;   // Colors/Grey/Dark/85
    --theme-colors-radio-button-hover-color: #3C89E8FF;   // Colors/Daybreak Blue/Dark/70
    --theme-colors-radio-button-active-color: #1554ADFF;   // Colors/Daybreak Blue/Dark/50
    --theme-colors-radio-button-disabled-checked-bg: #FFFFFF19;   // Colors/Grey/Dark/10
    --theme-colors-radio-button-disabled-button-checked-color: #FFFFFF3F;   // Colors/Grey/Dark/25
    --theme-colors-radio-dot-disabled-color: #FFFFFF33;   // Colors/Grey/Dark/20
    --theme-colors-rate-star-color: #D8BD14FF;   // Colors/Yellow/Dark/60
    --theme-colors-rate-star-bg: #FFFFFF26;   // Colors/Grey/Dark/15
    --theme-colors-segmented-bg: #FFFFFF0A;   // Colors/Grey/Dark/0
    --theme-colors-segmented-hover-bg: #FFFFFF0F;   // Colors/Grey/Dark/6
    --theme-colors-segmented-selected-bg: #1F1F1FFF;   // Colors/Black/5
    --theme-colors-segmented-label-color: #FFFFFFA5;   // Colors/Grey/Dark/65
    --theme-colors-segmented-label-hover-color: #262626FF;   // Colors/Black/7
    --theme-colors-select-border-color: #03030326;   // Colors/Grey/Light/15
    --theme-colors-select-item-selected-color: #FFFFFFFF;   // Colors/White/Color
    --theme-colors-select-dropdown-bg: #1F1F1FFF;   // Colors/Black/5
    --theme-colors-select-item-selected-bg: #111A2CFF;   // Colors/Daybreak Blue/Dark/10
    --theme-colors-select-item-active-bg: #FFFFFF19;   // Colors/Grey/Dark/10
    --theme-colors-select-background: #141414FF;   // Colors/Black/2
    --theme-colors-select-clear-background: #141414FF;   // Colors/Black/2
    --theme-colors-select-selection-item-bg: #FFFFFF14;   // Colors/Grey/Dark/8
    --theme-colors-select-selection-item-border-color: #FFFFFF0F;   // Colors/Grey/Dark/6
    --theme-colors-select-multiple-disabled-baclground: #0303030F;   // Colors/Grey/Light/6
    --theme-colors-select-multiple-item-disabled-color: #FFFFFF3F;   // Colors/Grey/Dark/25
    --theme-colors-select-multiple-item-disabled-border-color: #FFFFFF26;   // Colors/Grey/Dark/15
    --theme-colors-shadow-color: #03030326;   // Colors/Grey/Light/15
    --theme-colors-skeleton-color: #FFFFFF26;   // Colors/Grey/Dark/15
    --theme-colors-slider-rail-background-color: #FFFFFF0F;   // Colors/Grey/Dark/6
    --theme-colors-slider-rail-background-color-hover: #FFFFFF26;   // Colors/Grey/Dark/15
    --theme-colors-slider-track-background-color: #15325BFF;   // Colors/Daybreak Blue/Dark/30
    --theme-colors-slider-track-background-color-hover: #15417EFF;   // Colors/Daybreak Blue/Dark/40
    --theme-colors-slider-handle-background-color: #FFFFFF72;   // Colors/Grey/Dark/45
    --theme-colors-slider-handle-color: #15325BFF;   // Colors/Daybreak Blue/Dark/30
    --theme-colors-slider-handle-color-hover: #15417EFF;   // Colors/Daybreak Blue/Dark/40
    --theme-colors-slider-handle-color-tooltip-open: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-slider-dor-border-color: #FFFFFF0F;   // Colors/Grey/Dark/6
    --theme-colors-slider-disabled-color: #FFFFFF3F;   // Colors/Grey/Dark/25
    --theme-colors-slider-disabled-background-color: #000000FF;   // Colors/Black/1
    --theme-colors-shadow-color-inverse: #FFFFFFFF;   // Colors/White/Color
    --theme-colors-solid-checked-color: #141414FF;   // Colors/Black/2
    --theme-colors-status-new-default: #CB2B83FF;   // Colors/Magenta/Dark/60
    --theme-colors-status-new-background: #FAD2E3FF;   // Colors/Magenta/Dark/100
    --theme-colors-status-new-border: #F37FB7FF;   // Colors/Magenta/Dark/80
    --theme-colors-status-in-progress-default: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-status-in-progress-background: #B7DCFAFF;   // Colors/Daybreak Blue/Dark/100
    --theme-colors-status-in-progress-border: #65A9F3FF;   // Colors/Daybreak Blue/Dark/80
    --theme-colors-status-revised-default: #D87A16FF;   // Colors/Orange/Dark/60
    --theme-colors-status-revised-background: #FAE3B7FF;   // Colors/Orange/Dark/100
    --theme-colors-status-revised-border: #593815FF;   // Colors/Orange/Dark/30
    --theme-colors-status-completed-default: #49AA19FF;   // Colors/Green/Dark/60
    --theme-colors-status-completed-background: #D5F2BBFF;   // Colors/Green/Dark/100
    --theme-colors-status-completed-border: #274916FF;   // Colors/Green/Dark/30
    --theme-colors-status-concan-default: #FFFFFF72;   // Colors/Grey/Dark/45
    --theme-colors-status-concan-background: #FFFFFF19;   // Colors/Grey/Dark/10
    --theme-colors-status-concan-border: #FFFFFF3F;   // Colors/Grey/Dark/25
    --theme-colors-status-cancelled-default: #424242FF;   // Colors/Black/3
    --theme-colors-status-cancelled-background: #FFFFFF19;   // Colors/Grey/Dark/10
    --theme-colors-status-cancelled-border: #FFFFFFA5;   // Colors/Grey/Dark/65
    --theme-colors-steps-background: #111A2CFF;   // Colors/Daybreak Blue/Dark/10
    --theme-colors-success-color: #49AA19FF;   // Colors/Green/Dark/60
    --theme-colors-switch-color: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-switch-bg: #000000FF;   // Colors/Black/1
    --theme-colors-success-color-hover: #6ABE39FF;   // Colors/Green/Dark/70
    --theme-colors-success-color-active: #3C8618FF;   // Colors/Green/Dark/50
    --theme-colors-success-color-outline: #274916FF;   // Colors/Green/Dark/30
    --theme-colors-success-color-deprecated-bg: #162312FF;   // Colors/Green/Dark/10
    --theme-colors-success-color-deprecated-border: #1D3712FF;   // Colors/Green/Dark/20
    --theme-colors-table-bg: #141414FF;   // Colors/Black/2
    --theme-colors-tabs-card-head-background: #FFFFFF05;   // Colors/Grey/Dark/2
    --theme-colors-tabs-card-active-color: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-tabs-ink-bar-color: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-tabs-highlight-color: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-tabs-hover-color: #1554ADFF;   // Colors/Daybreak Blue/Dark/50
    --theme-colors-tabs-active-color: #3C89E8FF;   // Colors/Daybreak Blue/Dark/70
    --theme-colors-table-header-bg: #FFFFFF0A;   // Colors/Grey/Dark/0
    --theme-colors-table-header-color: #FFFFFFD8;   // Colors/Grey/Dark/85
    --theme-colors-table-header-sort-bg: #0303030A;   // Colors/Grey/Light/0
    --theme-colors-table-body-sort-bg: #FFFFFF0A;   // Colors/Grey/Dark/0
    --theme-colors-table-row-hover-bg: #FFFFFF0A;   // Colors/Grey/Dark/0
    --theme-colors-table-selected-row-bg: #111A2CFF;   // Colors/Daybreak Blue/Dark/10
    --theme-colors-table-body-selected-sort-bg: #111A2CFF;   // Colors/Daybreak Blue/Dark/10
    --theme-colors-table-expand-row-bg: #FFFFFF14;   // Colors/Grey/Dark/8
    --theme-colors-table-border-color: #FFFFFF0F;   // Colors/Grey/Dark/6
    --theme-colors-table-footer-bg: #FFFFFF0A;   // Colors/Grey/Dark/0
    --theme-colors-table-footer-color: #FFFFFFD8;   // Colors/Grey/Dark/85
    --theme-colors-table-header-bg-sm: #FFFFFF05;   // Colors/Grey/Dark/2
    --theme-colors-table-header-cell-split-color: #FFFFFF0F;   // Colors/Grey/Dark/6
    --theme-colors-table-header-sort-active-bg: #FFFFFF0A;   // Colors/Grey/Dark/0
    --theme-colors-table-fixed-header-sort-active-bg: #030303F4;   // Colors/Grey/Light/96
    --theme-colors-table-header-filter-active-bg: #FFFFFF0A;   // Colors/Grey/Dark/0
    --theme-colors-table-filter-dropdown-bg: #000000FF;   // Colors/Black/1
    --theme-colors-table-expand-icon-bg: #141414FF;   // Colors/Black/2
    --theme-colors-table-sticky-scroll-bar-bg: #FFFFFF59;   // Colors/Grey/Dark/35
    --theme-colors-tag-default-bg: #FFFFFF0A;   // Colors/Grey/Dark/0
    --theme-colors-tag-default-color: #FFFFFFD8;   // Colors/Grey/Dark/85
    --theme-colors-text-color: #FFFFFFD8;   // Colors/Grey/Dark/85
    --theme-colors-timeline-color: #FFFFFF0F;   // Colors/Grey/Dark/6
    --theme-colors-tooltip-color: #FFFFFFFF;   // Colors/White/Color
    --theme-colors-transfer-disabled-bg: #FFFFFF14;   // Colors/Grey/Dark/8
    --theme-colors-transfer-item-hover-bg: #FFFFFF26;   // Colors/Grey/Dark/15
    --theme-colors-tooltip-bg: #1D1D1DFF;   // Colors/Black/8
    --theme-colors-tooltip-arrow-color: #1D1D1DFF;   // Colors/Black/8
    --theme-colors-timeline-dot-color: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-timeline-dot-bg: #FFFFFF0F;   // Colors/Grey/Dark/6
    --theme-colors-text-color-secondary: #FFFFFF8C;   // Colors/Grey/Dark/55
    --theme-colors-text-color-inverse: #000000FF;   // Colors/Black/1
    --theme-colors-text-selection-background: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-tree-bg: #000000FF;   // Colors/Black/1
    --theme-colors-tree-directory-selected-color: #FFFFFF0A;   // Colors/Grey/Dark/0
    --theme-colors-tree-directory-selected-bg: #1668DCFF;   // Colors/Daybreak Blue/Dark/60
    --theme-colors-tree-node-hover-bg: #FFFFFF26;   // Colors/Grey/Dark/15
    --theme-colors-tree-node-selected-bg: #112545FF;   // Colors/Daybreak Blue/Dark/20
    --theme-colors-upload-actions-color: #FFFFFF72;   // Colors/Grey/Dark/45
    --theme-colors-warning-color: #D89614FF;   // Colors/Gold/Dark/60
    --theme-colors-warning-color-hover: #E8B339FF;   // Colors/Gold/Dark/70
    --theme-colors-warning-color-active: #AA7714FF;   // Colors/Gold/Dark/50
    --theme-colors-warning-color-outline: #594214FF;   // Colors/Gold/Dark/30
    --theme-colors-warning-color-deprecated-bg: #2B2111FF;   // Colors/Gold/Dark/10
    --theme-colors-warning-color-deprecated-border: #7C5914FF;   // Colors/Gold/Dark/40
    --layout-body-body-background: #000000FF;   // Colors/Black/1
    --layout-footer-background: #000000FF;   // Colors/Black/1
    --layout-header-background: #141414FF;   // Colors/Black/2
    --layout-sider-background: #141414FF;   // Colors/Black/2
    --layout-sider-background-light: #FFFFFF0A;   // Colors/Grey/Dark/0
    --layout-trigger-background: #141414FF;   // Colors/Black/2
    --layout-trigger-color: #000000FF;   // Colors/Black/1
    --layout-trigger-background-light: #FFFFFF0A;   // Colors/Grey/Dark/0
    --layout-trigger-color-light: #030303D8;   // Colors/Grey/Light/85
    --layout-header-header-color: #FFFFFF0A;   // Colors/Grey/Dark/0
    --layout-nav-ncx-menu: #141414FF;   // Colors/Black/2
    --layout-nav-ncx-inline: #070707FF;   // Colors/Black/9
    --layout-nav-search-menu: #141414FF;   // Colors/Black/2
    --layout-nav-search-inline: var(--search-inline);   // Layout/Nav/Search/Inline
    --theme-colors-text-dark: #FFFFFFD8;   // Colors/Grey/Dark/85
    --layout-header-text-main-color: #FFFFFFD8;   // Colors/Grey/Dark/85
    --layout-header-text-secondary: #FFFFFFD8;   // Colors/Grey/Dark/85 
    }
  }