/*
This file contains shortcut SASS variables that refer to the Light/Dark CSS variables of the Ant Design system.
DO NOT ADD or REMOVE variables from this file.
If you need to add or update these colors, it must be done by the UX Design Team and exported out of Figma again.


You can use these SASS variables instead of using the common var(--color-variable) syntax.
Example: {color: $global-colors-grey-0}
*/

$global-colors-grey-0: var(--global-colors-grey-0);
$global-colors-grey-2: var(--global-colors-grey-2);
$global-colors-grey-6: var(--global-colors-grey-6);
$global-colors-grey-8: var(--global-colors-grey-8);
$global-colors-grey-10: var(--global-colors-grey-10);
$global-colors-grey-15: var(--global-colors-grey-15);
$global-colors-grey-20: var(--global-colors-grey-20);
$global-colors-grey-25: var(--global-colors-grey-25);
$global-colors-grey-30: var(--global-colors-grey-30);
$global-colors-grey-35: var(--global-colors-grey-35);
$global-colors-grey-40: var(--global-colors-grey-40);
$global-colors-grey-45: var(--global-colors-grey-45);
$global-colors-grey-50: var(--global-colors-grey-50);
$global-colors-grey-55: var(--global-colors-grey-55);
$global-colors-grey-60: var(--global-colors-grey-60);
$global-colors-grey-65: var(--global-colors-grey-65);
$global-colors-grey-70: var(--global-colors-grey-70);
$global-colors-grey-75: var(--global-colors-grey-75);
$global-colors-grey-80: var(--global-colors-grey-80);
$global-colors-grey-85: var(--global-colors-grey-85);
$global-colors-grey-90: var(--global-colors-grey-90);
$global-colors-grey-96: var(--global-colors-grey-96);
$global-colors-grey-100: var(--global-colors-grey-100);
$global-colors-blue-main-10: var(--global-colors-blue-main-10);
$global-colors-cyan-10: var(--global-colors-cyan-10);
$global-colors-cyan-20: var(--global-colors-cyan-20);
$global-colors-cyan-30: var(--global-colors-cyan-30);
$global-colors-cyan-40: var(--global-colors-cyan-40);
$global-colors-cyan-50: var(--global-colors-cyan-50);
$global-colors-cyan-60: var(--global-colors-cyan-60);
$global-colors-cyan-70: var(--global-colors-cyan-70);
$global-colors-cyan-80: var(--global-colors-cyan-80);
$global-colors-cyan-90: var(--global-colors-cyan-90);
$global-colors-cyan-100: var(--global-colors-cyan-100);
$global-colors-blue-main-20: var(--global-colors-blue-main-20);
$global-colors-blue-main-30: var(--global-colors-blue-main-30);
$global-colors-blue-main-40: var(--global-colors-blue-main-40);
$global-colors-blue-main-50: var(--global-colors-blue-main-50);
$global-colors-blue-main-60: var(--global-colors-blue-main-60);
$global-colors-blue-main-70: var(--global-colors-blue-main-70);
$global-colors-blue-main-80: var(--global-colors-blue-main-80);
$global-colors-blue-main-90: var(--global-colors-blue-main-90);
$global-colors-blue-main-100: var(--global-colors-blue-main-100);
$global-colors-blue-secondary-10: var(--global-colors-blue-secondary-10);
$global-colors-blue-secondary-20: var(--global-colors-blue-secondary-20);
$global-colors-blue-secondary-30: var(--global-colors-blue-secondary-30);
$global-colors-blue-secondary-40: var(--global-colors-blue-secondary-40);
$global-colors-blue-secondary-50: var(--global-colors-blue-secondary-50);
$global-colors-blue-secondary-60: var(--global-colors-blue-secondary-60);
$global-colors-blue-secondary-70: var(--global-colors-blue-secondary-70);
$global-colors-blue-secondary-80: var(--global-colors-blue-secondary-80);
$global-colors-blue-secondary-90: var(--global-colors-blue-secondary-90);
$global-colors-blue-secondary-100: var(--global-colors-blue-secondary-100);
$global-colors-green-main-10: var(--global-colors-green-main-10);
$global-colors-green-main-20: var(--global-colors-green-main-20);
$global-colors-green-main-30: var(--global-colors-green-main-30);
$global-colors-green-main-40: var(--global-colors-green-main-40);
$global-colors-green-main-50: var(--global-colors-green-main-50);
$global-colors-green-main-60: var(--global-colors-green-main-60);
$global-colors-green-main-70: var(--global-colors-green-main-70);
$global-colors-green-main-80: var(--global-colors-green-main-80);
$global-colors-green-main-90: var(--global-colors-green-main-90);
$global-colors-green-main-100: var(--global-colors-green-main-100);
$global-colors-green-secondary-10: var(--global-colors-green-secondary-10);
$global-colors-green-secondary-20: var(--global-colors-green-secondary-20);
$global-colors-green-secondary-30: var(--global-colors-green-secondary-30);
$global-colors-green-secondary-40: var(--global-colors-green-secondary-40);
$global-colors-green-secondary-50: var(--global-colors-green-secondary-50);
$global-colors-green-secondary-60: var(--global-colors-green-secondary-60);
$global-colors-green-secondary-70: var(--global-colors-green-secondary-70);
$global-colors-green-secondary-80: var(--global-colors-green-secondary-80);
$global-colors-green-secondary-90: var(--global-colors-green-secondary-90);
$global-colors-green-secondary-100: var(--global-colors-green-secondary-100);
$global-colors-magenta-10: var(--global-colors-magenta-10);
$global-colors-magenta-20: var(--global-colors-magenta-20);
$global-colors-magenta-30: var(--global-colors-magenta-30);
$global-colors-magenta-40: var(--global-colors-magenta-40);
$global-colors-magenta-50: var(--global-colors-magenta-50);
$global-colors-magenta-60: var(--global-colors-magenta-60);
$global-colors-magenta-70: var(--global-colors-magenta-70);
$global-colors-magenta-80: var(--global-colors-magenta-80);
$global-colors-magenta-90: var(--global-colors-magenta-90);
$global-colors-magenta-100: var(--global-colors-magenta-100);
$global-colors-orange-10: var(--global-colors-orange-10);
$global-colors-orange-20: var(--global-colors-orange-20);
$global-colors-orange-30: var(--global-colors-orange-30);
$global-colors-orange-40: var(--global-colors-orange-40);
$global-colors-orange-50: var(--global-colors-orange-50);
$global-colors-orange-60: var(--global-colors-orange-60);
$global-colors-orange-70: var(--global-colors-orange-70);
$global-colors-orange-80: var(--global-colors-orange-80);
$global-colors-orange-90: var(--global-colors-orange-90);
$global-colors-orange-100: var(--global-colors-orange-100);
$global-colors-purple-10: var(--global-colors-purple-10);
$global-colors-purple-20: var(--global-colors-purple-20);
$global-colors-purple-30: var(--global-colors-purple-30);
$global-colors-purple-40: var(--global-colors-purple-40);
$global-colors-purple-50: var(--global-colors-purple-50);
$global-colors-purple-60: var(--global-colors-purple-60);
$global-colors-purple-70: var(--global-colors-purple-70);
$global-colors-purple-80: var(--global-colors-purple-80);
$global-colors-purple-90: var(--global-colors-purple-90);
$global-colors-purple-100: var(--global-colors-purple-100);
$global-colors-red-main-10: var(--global-colors-red-main-10);
$global-colors-red-main-20: var(--global-colors-red-main-20);
$global-colors-red-main-30: var(--global-colors-red-main-30);
$global-colors-red-main-40: var(--global-colors-red-main-40);
$global-colors-red-main-50: var(--global-colors-red-main-50);
$global-colors-red-main-60: var(--global-colors-red-main-60);
$global-colors-red-main-70: var(--global-colors-red-main-70);
$global-colors-red-main-80: var(--global-colors-red-main-80);
$global-colors-red-main-90: var(--global-colors-red-main-90);
$global-colors-red-main-100: var(--global-colors-red-main-100);
$global-colors-red-secondary-10: var(--global-colors-red-secondary-10);
$global-colors-red-secondary-20: var(--global-colors-red-secondary-20);
$global-colors-red-secondary-30: var(--global-colors-red-secondary-30);
$global-colors-red-secondary-40: var(--global-colors-red-secondary-40);
$global-colors-red-secondary-50: var(--global-colors-red-secondary-50);
$global-colors-red-secondary-60: var(--global-colors-red-secondary-60);
$global-colors-red-secondary-70: var(--global-colors-red-secondary-70);
$global-colors-red-secondary-80: var(--global-colors-red-secondary-80);
$global-colors-red-secondary-90: var(--global-colors-red-secondary-90);
$global-colors-red-secondary-100: var(--global-colors-red-secondary-100);
$global-colors-yellow-main-10: var(--global-colors-yellow-main-10);
$global-colors-yellow-main-20: var(--global-colors-yellow-main-20);
$global-colors-yellow-main-30: var(--global-colors-yellow-main-30);
$global-colors-yellow-main-40: var(--global-colors-yellow-main-40);
$global-colors-yellow-main-50: var(--global-colors-yellow-main-50);
$global-colors-yellow-main-60: var(--global-colors-yellow-main-60);
$global-colors-yellow-main-70: var(--global-colors-yellow-main-70);
$global-colors-yellow-main-80: var(--global-colors-yellow-main-80);
$global-colors-yellow-main-90: var(--global-colors-yellow-main-90);
$global-colors-yellow-main-100: var(--global-colors-yellow-main-100);
$global-colors-yellow-secondary-10: var(--global-colors-yellow-secondary-10);
$global-colors-yellow-secondary-20: var(--global-colors-yellow-secondary-20);
$global-colors-yellow-secondary-30: var(--global-colors-yellow-secondary-30);
$global-colors-yellow-secondary-40: var(--global-colors-yellow-secondary-40);
$global-colors-yellow-secondary-50: var(--global-colors-yellow-secondary-50);
$global-colors-yellow-secondary-60: var(--global-colors-yellow-secondary-60);
$global-colors-yellow-secondary-70: var(--global-colors-yellow-secondary-70);
$global-colors-yellow-secondary-80: var(--global-colors-yellow-secondary-80);
$global-colors-yellow-secondary-90: var(--global-colors-yellow-secondary-90);
$global-colors-yellow-secondary-100: var(--global-colors-yellow-secondary-100);
$theme-colors-alert-success-border-color: var(--theme-colors-alert-success-border-color);
$theme-colors-alert-success-bg-color: var(--theme-colors-alert-success-bg-color);
$theme-colors-alert-success-icon-color: var(--theme-colors-alert-success-icon-color);
$theme-colors-alert-info-border-color: var(--theme-colors-alert-info-border-color);
$theme-colors-alert-info-bg-color: var(--theme-colors-alert-info-bg-color);
$theme-colors-alert-info-icon-color: var(--theme-colors-alert-info-icon-color);
$theme-colors-alert-warning-border-color: var(--theme-colors-alert-warning-border-color);
$theme-colors-alert-warning-bg-color: var(--theme-colors-alert-warning-bg-color);
$theme-colors-alert-warning-icon-color: var(--theme-colors-alert-warning-icon-color);
$theme-colors-alert-error-border-color: var(--theme-colors-alert-error-border-color);
$theme-colors-alert-error-bg-color: var(--theme-colors-alert-error-bg-color);
$theme-colors-alert-error-icon-color: var(--theme-colors-alert-error-icon-color);
$theme-colors-alert-message-color: var(--theme-colors-alert-message-color);
$theme-colors-alert-message-text-color: var(--theme-colors-alert-message-text-color);
$theme-colors-alert-message-close-out: var(--theme-colors-alert-message-close-out);
$theme-colors-alert-message-close-hover-color: var(--theme-colors-alert-message-close-hover-color);
$theme-colors-anchor-bg: var(--theme-colors-anchor-bg);
$theme-colors-avatar-bg: var(--theme-colors-avatar-bg);
$theme-colors-avatar-color: var(--theme-colors-avatar-color);
$theme-colors-avatar-group-border-color: var(--theme-colors-avatar-group-border-color);
$theme-colors-anchor-border-color: var(--theme-colors-anchor-border-color);
$theme-colors-back-top-color: var(--theme-colors-back-top-color);
$theme-colors-back-top-bg: var(--theme-colors-back-top-bg);
$theme-colors-back-top-hover-bg: var(--theme-colors-back-top-hover-bg);
$theme-colors-background-color-light: var(--theme-colors-background-color-light);
$theme-colors-background-color-base: var(--theme-colors-background-color-base);
$theme-colors-background-color-card: var(--theme-colors-background-color-card);
$theme-colors-background-color-rhd: var(--theme-colors-background-color-rhd);
$theme-colors-background-color--rhd---alt: var(--theme-colors-background-color--rhd---alt);
$theme-colors-badge-text-color: var(--theme-colors-badge-text-color);
$theme-colors-badge-badge-color: var(--theme-colors-badge-badge-color);
$theme-colors-body-background: var(--theme-colors-body-background);
$theme-colors-border-color-base: var(--theme-colors-border-color-base);
$theme-colors-bread-crumb-base-color: var(--theme-colors-bread-crumb-base-color);
$theme-colors-bread-crumb-last-item-color: var(--theme-colors-bread-crumb-last-item-color);
$theme-colors-bread-crumb-link-color: var(--theme-colors-bread-crumb-link-color);
$theme-colors-bread-crumb-link-color-hover: var(--theme-colors-bread-crumb-link-color-hover);
$theme-colors-bread-crumb-separator-color: var(--theme-colors-bread-crumb-separator-color);
$theme-colors-button-bg: var(--theme-colors-button-bg);
$theme-colors-button-check-bg: var(--theme-colors-button-check-bg);
$theme-colors-button-button-color: var(--theme-colors-button-button-color);
$theme-colors-button-button-hover-color: var(--theme-colors-button-button-hover-color);
$theme-colors-button-button-active-color: var(--theme-colors-button-button-active-color);
$theme-colors-button-disabled-button-checked-bg: var(--theme-colors-button-disabled-button-checked-bg);
$theme-colors-button-disabled-button-checked-color: var(--theme-colors-button-disabled-button-checked-color);
$theme-colors-border-color-split: var(--theme-colors-border-color-split);
$theme-colors-border-color-inverse: var(--theme-colors-border-color-inverse);
$theme-colors-buttons-primary-primary-color: var(--theme-colors-buttons-primary-primary-color);
$theme-colors-calendar-bg: var(--theme-colors-calendar-bg);
$theme-colors-card-head-color: var(--theme-colors-card-head-color);
$theme-colors-cascader-bg: var(--theme-colors-cascader-bg);
$theme-colors-cascader-item-selected-bg: var(--theme-colors-cascader-item-selected-bg);
$theme-colors-cascader-menu-bg: var(--theme-colors-cascader-menu-bg);
$theme-colors-cascader-menu-border-color-split: var(--theme-colors-cascader-menu-border-color-split);
$theme-colors-card-header-background: var(--theme-colors-card-header-background);
$theme-colors-card-actions-background: var(--theme-colors-card-actions-background);
$theme-colors-card-background: var(--theme-colors-card-background);
$theme-colors-card-head-extra-color: var(--theme-colors-card-head-extra-color);
$theme-colors-calendar-input-bg: var(--theme-colors-calendar-input-bg);
$theme-colors-calendar-border-color: var(--theme-colors-calendar-border-color);
$theme-colors-calendar-item-active-bg: var(--theme-colors-calendar-item-active-bg);
$theme-colors-calendar-column-active-bg: var(--theme-colors-calendar-column-active-bg);
$theme-colors-calendar-full-bg: var(--theme-colors-calendar-full-bg);
$theme-colors-calendar-full-panel-bg: var(--theme-colors-calendar-full-panel-bg);
$theme-colors-buttons-primary-bg: var(--theme-colors-buttons-primary-bg);
$theme-colors-buttons-default-color: var(--theme-colors-buttons-default-color);
$theme-colors-buttons-default-bg: var(--theme-colors-buttons-default-bg);
$theme-colors-buttons-default-border: var(--theme-colors-buttons-default-border);
$theme-colors-buttons-danger-color: var(--theme-colors-buttons-danger-color);
$theme-colors-buttons-danger-bg: var(--theme-colors-buttons-danger-bg);
$theme-colors-buttons-danger-border: var(--theme-colors-buttons-danger-border);
$theme-colors-buttons-disable-color: var(--theme-colors-buttons-disable-color);
$theme-colors-buttons-disable-bg: var(--theme-colors-buttons-disable-bg);
$theme-colors-buttons-disable-border: var(--theme-colors-buttons-disable-border);
$theme-colors-buttons-default-ghost-color: var(--theme-colors-buttons-default-ghost-color);
$theme-colors-buttons-default-ghost-bg: var(--theme-colors-buttons-default-ghost-bg);
$theme-colors-buttons-default-ghost-border: var(--theme-colors-buttons-default-ghost-border);
$theme-colors-buttons-group-border: var(--theme-colors-buttons-group-border);
$theme-colors-buttons-link-hover-bg: var(--theme-colors-buttons-link-hover-bg);
$theme-colors-buttons-text-hover-bg: var(--theme-colors-buttons-text-hover-bg);
$theme-colors-checkbox-color: var(--theme-colors-checkbox-color);
$theme-colors-checkbox-check-color: var(--theme-colors-checkbox-check-color);
$theme-colors-checkbox-check-bg: var(--theme-colors-checkbox-check-bg);
$theme-colors-collapse-header-bg: var(--theme-colors-collapse-header-bg);
$theme-colors-collapse-content-bg: var(--theme-colors-collapse-content-bg);
$theme-colors-comment-author-name-color: var(--theme-colors-comment-author-name-color);
$theme-colors-comment-author-time-color: var(--theme-colors-comment-author-time-color);
$theme-colors-comment-action-color: var(--theme-colors-comment-action-color);
$theme-colors-comment-action-hover-color: var(--theme-colors-comment-action-hover-color);
$theme-colors-component-background: var(--theme-colors-component-background);
$theme-colors-descriptions-bg: var(--theme-colors-descriptions-bg);
$theme-colors-descriptions-extra-color: var(--theme-colors-descriptions-extra-color);
$theme-colors-disabled-color: var(--theme-colors-disabled-color);
$theme-colors-disabled-bg: var(--theme-colors-disabled-bg);
$theme-colors-disabled-active-bg: var(--theme-colors-disabled-active-bg);
$theme-colors-disabled-color-dark: var(--theme-colors-disabled-color-dark);
$theme-colors-divider-bg: var(--theme-colors-divider-bg);
$theme-colors-dot-dot-color: var(--theme-colors-dot-dot-color);
$theme-colors-dot-dot-disabled-color: var(--theme-colors-dot-dot-disabled-color);
$theme-colors-drawer-bg: var(--theme-colors-drawer-bg);
$theme-colors-dropdown-selected-color: var(--theme-colors-dropdown-selected-color);
$theme-colors-dropdown-menu-submenu-disabled-bg: var(--theme-colors-dropdown-menu-submenu-disabled-bg);
$theme-colors-dropdown-selected-bg: var(--theme-colors-dropdown-selected-bg);
$theme-colors-dropdown-menu-bg: var(--theme-colors-dropdown-menu-bg);
$theme-colors-empty-img-default-elipse: var(--theme-colors-empty-img-default-elipse);
$theme-colors-empty-img-default-path-1: var(--theme-colors-empty-img-default-path-1);
$theme-colors-empty-img-default-path-3: var(--theme-colors-empty-img-default-path-3);
$theme-colors-empty-img-default-path-4: var(--theme-colors-empty-img-default-path-4);
$theme-colors-empty-img-default-path-5: var(--theme-colors-empty-img-default-path-5);
$theme-colors-error-color: var(--theme-colors-error-color);
$theme-colors-form-warning-input-bg: var(--theme-colors-form-warning-input-bg);
$theme-colors-form-error-input-bg: var(--theme-colors-form-error-input-bg);
$theme-colors-error-color-hover: var(--theme-colors-error-color-hover);
$theme-colors-error-color-active: var(--theme-colors-error-color-active);
$theme-colors-error-color-outline: var(--theme-colors-error-color-outline);
$theme-colors-error-color-deprecated-bg: var(--theme-colors-error-color-deprecated-bg);
$theme-colors-error-color-deprecated-border: var(--theme-colors-error-color-deprecated-border);
$theme-colors-header-color: var(--theme-colors-header-color);
$theme-colors-header-component-header-color: var(--theme-colors-header-component-header-color);
$theme-colors-highlight-color: var(--theme-colors-highlight-color);
$theme-colors-highlight-search-return-bg: var(--theme-colors-highlight-search-return-bg);
$theme-colors-highlight-search-return-color: var(--theme-colors-highlight-search-return-color);
$theme-colors-icon-color: var(--theme-colors-icon-color);
$theme-colors-icon-secondary: var(--theme-colors-icon-secondary);
$theme-colors-images-bg: var(--theme-colors-images-bg);
$theme-colors-images-color: var(--theme-colors-images-color);
$theme-colors-images-preview-operation-color: var(--theme-colors-images-preview-operation-color);
$theme-colors-info-color: var(--theme-colors-info-color);
$theme-colors-input-placeholder-color: var(--theme-colors-input-placeholder-color);
$theme-colors-input-color: var(--theme-colors-input-color);
$theme-colors-input-icon-color: var(--theme-colors-input-icon-color);
$theme-colors-input-border-color: var(--theme-colors-input-border-color);
$theme-colors-input-bg: var(--theme-colors-input-bg);
$theme-colors-input-number-hover-border-color: var(--theme-colors-input-number-hover-border-color);
$theme-colors-input-number-handler-active-bg: var(--theme-colors-input-number-handler-active-bg);
$theme-colors-input-number-handler-hover-bg: var(--theme-colors-input-number-handler-hover-bg);
$theme-colors-input-number-handler-bg: var(--theme-colors-input-number-handler-bg);
$theme-colors-input-number-handler-border-color: var(--theme-colors-input-number-handler-border-color);
$theme-colors-input-addon-bg: var(--theme-colors-input-addon-bg);
$theme-colors-input-hover-border-color: var(--theme-colors-input-hover-border-color);
$theme-colors-input-disabled-bg: var(--theme-colors-input-disabled-bg);
$theme-colors-input-icon-hover-color: var(--theme-colors-input-icon-hover-color);
$theme-colors-input-disabled-color: var(--theme-colors-input-disabled-color);
$theme-colors-input-read-only: var(--theme-colors-input-read-only);
$theme-colors-info-color-deprecated-bg: var(--theme-colors-info-color-deprecated-bg);
$theme-colors-info-color-deprecated-border: var(--theme-colors-info-color-deprecated-border);
$theme-colors-item-active-bg: var(--theme-colors-item-active-bg);
$theme-colors-item-hover-bg: var(--theme-colors-item-hover-bg);
$theme-colors-label-required-color: var(--theme-colors-label-required-color);
$theme-colors-label-color: var(--theme-colors-label-color);
$theme-colors-link-color-base: var(--theme-colors-link-color-base);
$theme-colors-link-hover: var(--theme-colors-link-hover);
$theme-colors-link-color-split: var(--theme-colors-link-color-split);
$theme-colors-link-color-inverse: var(--theme-colors-link-color-inverse);
$theme-colors-link-active: var(--theme-colors-link-active);
$theme-colors-list-header-background: var(--theme-colors-list-header-background);
$theme-colors-page-header-back-color: var(--theme-colors-page-header-back-color);
$theme-colors-pagination-item-bg: var(--theme-colors-pagination-item-bg);
$theme-colors-pagination-item-bg-active: var(--theme-colors-pagination-item-bg-active);
$theme-colors-pagination-item-link-bg: var(--theme-colors-pagination-item-link-bg);
$theme-colors-pagination-item-disabled-color-active: var(--theme-colors-pagination-item-disabled-color-active);
$theme-colors-pagination-item-disabled-bg-active: var(--theme-colors-pagination-item-disabled-bg-active);
$theme-colors-pagination-item-input-bg: var(--theme-colors-pagination-item-input-bg);
$theme-colors-list-footer-background: var(--theme-colors-list-footer-background);
$theme-colors-list-customize-card-bg: var(--theme-colors-list-customize-card-bg);
$theme-colors-picker-bg: var(--theme-colors-picker-bg);
$theme-colors-popover-bg: var(--theme-colors-popover-bg);
$theme-colors-popover-color: var(--theme-colors-popover-color);
$theme-colors-popover-arrow-color: var(--theme-colors-popover-arrow-color);
$theme-colors-popover-arrow-outer-color: var(--theme-colors-popover-arrow-outer-color);
$theme-colors-popover-background: var(--theme-colors-popover-background);
$theme-colors-popover-customize-border-color: var(--theme-colors-popover-customize-border-color);
$theme-colors-primary-10: var(--theme-colors-primary-10);
$theme-colors-primary-20: var(--theme-colors-primary-20);
$theme-colors-primary-30: var(--theme-colors-primary-30);
$theme-colors-primary-40: var(--theme-colors-primary-40);
$theme-colors-primary-50: var(--theme-colors-primary-50);
$theme-colors-primary-60: var(--theme-colors-primary-60);
$theme-colors-primary-70: var(--theme-colors-primary-70);
$theme-colors-primary-80: var(--theme-colors-primary-80);
$theme-colors-primary-90: var(--theme-colors-primary-90);
$theme-colors-primary-100: var(--theme-colors-primary-100);
$theme-colors-primary-color: var(--theme-colors-primary-color);
$theme-colors-primary-color-hover: var(--theme-colors-primary-color-hover);
$theme-colors-primary-color-active: var(--theme-colors-primary-color-active);
$theme-colors-primary-color-outline: var(--theme-colors-primary-color-outline);
$theme-colors-picker-basic-cell-hover-color: var(--theme-colors-picker-basic-cell-hover-color);
$theme-colors-picker-basic-cell-active-with-range-color: var(--theme-colors-picker-basic-cell-active-with-range-color);
$theme-colors-picker-basic-cell-disabled-bg: var(--theme-colors-picker-basic-cell-disabled-bg);
$theme-colors-picker-border-color: var(--theme-colors-picker-border-color);
$theme-colors-picker-basic-cell-hover-with-range-color: var(--theme-colors-picker-basic-cell-hover-with-range-color);
$theme-colors-picker-date-hover-range-color: var(--theme-colors-picker-date-hover-range-color);
$theme-colors-processing-color: var(--theme-colors-processing-color);
$theme-colors-progress-default-color: var(--theme-colors-progress-default-color);
$theme-colors-progress-remaining-color: var(--theme-colors-progress-remaining-color);
$theme-colors-progress-info-text-color: var(--theme-colors-progress-info-text-color);
$theme-colors-progress-steps-item-bg: var(--theme-colors-progress-steps-item-bg);
$theme-colors-progress-text-color: var(--theme-colors-progress-text-color);
$theme-colors-process-tail-color: var(--theme-colors-process-tail-color);
$theme-colors-mark-bg: var(--theme-colors-mark-bg);
$theme-colors-mentions-dropdown-bg: var(--theme-colors-mentions-dropdown-bg);
$theme-colors-menu-bg: var(--theme-colors-menu-bg);
$theme-colors-menu-popup-bg: var(--theme-colors-menu-popup-bg);
$theme-colors-menu-item-color: var(--theme-colors-menu-item-color);
$theme-colors-menu-inline-submenu-bg: var(--theme-colors-menu-inline-submenu-bg);
$theme-colors-menu-highlight-color: var(--theme-colors-menu-highlight-color);
$theme-colors-menu-highlight-danger-color: var(--theme-colors-menu-highlight-danger-color);
$theme-colors-menu-item-active-bg: var(--theme-colors-menu-item-active-bg);
$theme-colors-menu-item-active-danger-bg: var(--theme-colors-menu-item-active-danger-bg);
$theme-colors-menu-item-group-title-color: var(--theme-colors-menu-item-group-title-color);
$theme-colors-menu-dark-color: var(--theme-colors-menu-dark-color);
$theme-colors-menu-dark-danger-color: var(--theme-colors-menu-dark-danger-color);
$theme-colors-menu-dark-bg: var(--theme-colors-menu-dark-bg);
$theme-colors-menu-dark-arrow-color: var(--theme-colors-menu-dark-arrow-color);
$theme-colors-menu-dark-inline-submenu-bg: var(--theme-colors-menu-dark-inline-submenu-bg);
$theme-colors-menu-dark-item-active-bg: var(--theme-colors-menu-dark-item-active-bg);
$theme-colors-menu-dark-item-active-danger-bg: var(--theme-colors-menu-dark-item-active-danger-bg);
$theme-colors-menu-dark-selected-item-icon-color: var(--theme-colors-menu-dark-selected-item-icon-color);
$theme-colors-menu-dark-selected-item-text-color: var(--theme-colors-menu-dark-selected-item-text-color);
$theme-colors-menu-dark-item-hover-bg: var(--theme-colors-menu-dark-item-hover-bg);
$theme-colors-menu-dark-highlight-color: var(--theme-colors-menu-dark-highlight-color);
$theme-colors-message-notice-content-bg: var(--theme-colors-message-notice-content-bg);
$theme-colors-mentions-dropdown-menu-item-hover-bg: var(--theme-colors-mentions-dropdown-menu-item-hover-bg);
$theme-colors-modal-header-bg: var(--theme-colors-modal-header-bg);
$theme-colors-modal-header-border-color-split: var(--theme-colors-modal-header-border-color-split);
$theme-colors-modal-content-bg: var(--theme-colors-modal-content-bg);
$theme-colors-modal-heading-color: var(--theme-colors-modal-heading-color);
$theme-colors-modal-close-color: var(--theme-colors-modal-close-color);
$theme-colors-modal-footer-bg: var(--theme-colors-modal-footer-bg);
$theme-colors-modal-footer-border-color-split: var(--theme-colors-modal-footer-border-color-split);
$theme-colors-modal-mask-bg: var(--theme-colors-modal-mask-bg);
$theme-colors-normal-color: var(--theme-colors-normal-color);
$theme-colors-notifications-bg: var(--theme-colors-notifications-bg);
$theme-colors-radio-solid-checked-color: var(--theme-colors-radio-solid-checked-color);
$theme-colors-radio-dot-color: var(--theme-colors-radio-dot-color);
$theme-colors-radio-button-bg: var(--theme-colors-radio-button-bg);
$theme-colors-radio-button-checked-bg: var(--theme-colors-radio-button-checked-bg);
$theme-colors-radio-button-color: var(--theme-colors-radio-button-color);
$theme-colors-radio-button-hover-color: var(--theme-colors-radio-button-hover-color);
$theme-colors-radio-button-active-color: var(--theme-colors-radio-button-active-color);
$theme-colors-radio-button-disabled-checked-bg: var(--theme-colors-radio-button-disabled-checked-bg);
$theme-colors-radio-button-disabled-button-checked-color: var(--theme-colors-radio-button-disabled-button-checked-color);
$theme-colors-radio-dot-disabled-color: var(--theme-colors-radio-dot-disabled-color);
$theme-colors-rate-star-color: var(--theme-colors-rate-star-color);
$theme-colors-rate-star-bg: var(--theme-colors-rate-star-bg);
$theme-colors-segmented-bg: var(--theme-colors-segmented-bg);
$theme-colors-segmented-hover-bg: var(--theme-colors-segmented-hover-bg);
$theme-colors-segmented-selected-bg: var(--theme-colors-segmented-selected-bg);
$theme-colors-segmented-label-color: var(--theme-colors-segmented-label-color);
$theme-colors-segmented-label-hover-color: var(--theme-colors-segmented-label-hover-color);
$theme-colors-select-border-color: var(--theme-colors-select-border-color);
$theme-colors-select-item-selected-color: var(--theme-colors-select-item-selected-color);
$theme-colors-select-dropdown-bg: var(--theme-colors-select-dropdown-bg);
$theme-colors-select-item-selected-bg: var(--theme-colors-select-item-selected-bg);
$theme-colors-select-item-active-bg: var(--theme-colors-select-item-active-bg);
$theme-colors-select-background: var(--theme-colors-select-background);
$theme-colors-select-clear-background: var(--theme-colors-select-clear-background);
$theme-colors-select-selection-item-bg: var(--theme-colors-select-selection-item-bg);
$theme-colors-select-selection-item-border-color: var(--theme-colors-select-selection-item-border-color);
$theme-colors-select-multiple-disabled-baclground: var(--theme-colors-select-multiple-disabled-baclground);
$theme-colors-select-multiple-item-disabled-color: var(--theme-colors-select-multiple-item-disabled-color);
$theme-colors-select-multiple-item-disabled-border-color: var(--theme-colors-select-multiple-item-disabled-border-color);
$theme-colors-shadow-color: var(--theme-colors-shadow-color);
$theme-colors-skeleton-color: var(--theme-colors-skeleton-color);
$theme-colors-slider-rail-background-color: var(--theme-colors-slider-rail-background-color);
$theme-colors-slider-rail-background-color-hover: var(--theme-colors-slider-rail-background-color-hover);
$theme-colors-slider-track-background-color: var(--theme-colors-slider-track-background-color);
$theme-colors-slider-track-background-color-hover: var(--theme-colors-slider-track-background-color-hover);
$theme-colors-slider-handle-background-color: var(--theme-colors-slider-handle-background-color);
$theme-colors-slider-handle-color: var(--theme-colors-slider-handle-color);
$theme-colors-slider-handle-color-hover: var(--theme-colors-slider-handle-color-hover);
$theme-colors-slider-handle-color-tooltip-open: var(--theme-colors-slider-handle-color-tooltip-open);
$theme-colors-slider-dor-border-color: var(--theme-colors-slider-dor-border-color);
$theme-colors-slider-disabled-color: var(--theme-colors-slider-disabled-color);
$theme-colors-slider-disabled-background-color: var(--theme-colors-slider-disabled-background-color);
$theme-colors-shadow-color-inverse: var(--theme-colors-shadow-color-inverse);
$theme-colors-solid-checked-color: var(--theme-colors-solid-checked-color);
$theme-colors-status-new-default: var(--theme-colors-status-new-default);
$theme-colors-status-new-background: var(--theme-colors-status-new-background);
$theme-colors-status-new-border: var(--theme-colors-status-new-border);
$theme-colors-status-in-progress-default: var(--theme-colors-status-in-progress-default);
$theme-colors-status-in-progress-background: var(--theme-colors-status-in-progress-background);
$theme-colors-status-in-progress-border: var(--theme-colors-status-in-progress-border);
$theme-colors-status-revised-default: var(--theme-colors-status-revised-default);
$theme-colors-status-revised-background: var(--theme-colors-status-revised-background);
$theme-colors-status-revised-border: var(--theme-colors-status-revised-border);
$theme-colors-status-completed-default: var(--theme-colors-status-completed-default);
$theme-colors-status-completed-background: var(--theme-colors-status-completed-background);
$theme-colors-status-completed-border: var(--theme-colors-status-completed-border);
$theme-colors-status-concan-default: var(--theme-colors-status-concan-default);
$theme-colors-status-concan-background: var(--theme-colors-status-concan-background);
$theme-colors-status-concan-border: var(--theme-colors-status-concan-border);
$theme-colors-status-cancelled-default: var(--theme-colors-status-cancelled-default);
$theme-colors-status-cancelled-background: var(--theme-colors-status-cancelled-background);
$theme-colors-status-cancelled-border: var(--theme-colors-status-cancelled-border);
$theme-colors-steps-background: var(--theme-colors-steps-background);
$theme-colors-success-color: var(--theme-colors-success-color);
$theme-colors-switch-color: var(--theme-colors-switch-color);
$theme-colors-switch-bg: var(--theme-colors-switch-bg);
$theme-colors-success-color-hover: var(--theme-colors-success-color-hover);
$theme-colors-success-color-active: var(--theme-colors-success-color-active);
$theme-colors-success-color-outline: var(--theme-colors-success-color-outline);
$theme-colors-success-color-deprecated-bg: var(--theme-colors-success-color-deprecated-bg);
$theme-colors-success-color-deprecated-border: var(--theme-colors-success-color-deprecated-border);
$theme-colors-table-bg: var(--theme-colors-table-bg);
$theme-colors-tabs-card-head-background: var(--theme-colors-tabs-card-head-background);
$theme-colors-tabs-card-active-color: var(--theme-colors-tabs-card-active-color);
$theme-colors-tabs-ink-bar-color: var(--theme-colors-tabs-ink-bar-color);
$theme-colors-tabs-highlight-color: var(--theme-colors-tabs-highlight-color);
$theme-colors-tabs-hover-color: var(--theme-colors-tabs-hover-color);
$theme-colors-tabs-active-color: var(--theme-colors-tabs-active-color);
$theme-colors-table-header-bg: var(--theme-colors-table-header-bg);
$theme-colors-table-header-color: var(--theme-colors-table-header-color);
$theme-colors-table-header-sort-bg: var(--theme-colors-table-header-sort-bg);
$theme-colors-table-body-sort-bg: var(--theme-colors-table-body-sort-bg);
$theme-colors-table-row-hover-bg: var(--theme-colors-table-row-hover-bg);
$theme-colors-table-selected-row-bg: var(--theme-colors-table-selected-row-bg);
$theme-colors-table-body-selected-sort-bg: var(--theme-colors-table-body-selected-sort-bg);
$theme-colors-table-expand-row-bg: var(--theme-colors-table-expand-row-bg);
$theme-colors-table-border-color: var(--theme-colors-table-border-color);
$theme-colors-table-footer-bg: var(--theme-colors-table-footer-bg);
$theme-colors-table-footer-color: var(--theme-colors-table-footer-color);
$theme-colors-table-header-bg-sm: var(--theme-colors-table-header-bg-sm);
$theme-colors-table-header-cell-split-color: var(--theme-colors-table-header-cell-split-color);
$theme-colors-table-header-sort-active-bg: var(--theme-colors-table-header-sort-active-bg);
$theme-colors-table-fixed-header-sort-active-bg: var(--theme-colors-table-fixed-header-sort-active-bg);
$theme-colors-table-header-filter-active-bg: var(--theme-colors-table-header-filter-active-bg);
$theme-colors-table-filter-dropdown-bg: var(--theme-colors-table-filter-dropdown-bg);
$theme-colors-table-expand-icon-bg: var(--theme-colors-table-expand-icon-bg);
$theme-colors-table-sticky-scroll-bar-bg: var(--theme-colors-table-sticky-scroll-bar-bg);
$theme-colors-tag-default-bg: var(--theme-colors-tag-default-bg);
$theme-colors-tag-default-color: var(--theme-colors-tag-default-color);
$theme-colors-text-color: var(--theme-colors-text-color);
$theme-colors-timeline-color: var(--theme-colors-timeline-color);
$theme-colors-tooltip-color: var(--theme-colors-tooltip-color);
$theme-colors-transfer-disabled-bg: var(--theme-colors-transfer-disabled-bg);
$theme-colors-transfer-item-hover-bg: var(--theme-colors-transfer-item-hover-bg);
$theme-colors-tooltip-bg: var(--theme-colors-tooltip-bg);
$theme-colors-tooltip-arrow-color: var(--theme-colors-tooltip-arrow-color);
$theme-colors-timeline-dot-color: var(--theme-colors-timeline-dot-color);
$theme-colors-timeline-dot-bg: var(--theme-colors-timeline-dot-bg);
$theme-colors-text-color-secondary: var(--theme-colors-text-color-secondary);
$theme-colors-text-color-inverse: var(--theme-colors-text-color-inverse);
$theme-colors-text-selection-background: var(--theme-colors-text-selection-background);
$theme-colors-tree-bg: var(--theme-colors-tree-bg);
$theme-colors-tree-directory-selected-color: var(--theme-colors-tree-directory-selected-color);
$theme-colors-tree-directory-selected-bg: var(--theme-colors-tree-directory-selected-bg);
$theme-colors-tree-node-hover-bg: var(--theme-colors-tree-node-hover-bg);
$theme-colors-tree-node-selected-bg: var(--theme-colors-tree-node-selected-bg);
$theme-colors-upload-actions-color: var(--theme-colors-upload-actions-color);
$theme-colors-warning-color: var(--theme-colors-warning-color);
$theme-colors-warning-color-hover: var(--theme-colors-warning-color-hover);
$theme-colors-warning-color-active: var(--theme-colors-warning-color-active);
$theme-colors-warning-color-outline: var(--theme-colors-warning-color-outline);
$theme-colors-warning-color-deprecated-bg: var(--theme-colors-warning-color-deprecated-bg);
$theme-colors-warning-color-deprecated-border: var(--theme-colors-warning-color-deprecated-border);
$layout-body-body-background: var(--layout-body-body-background);
$layout-footer-background: var(--layout-footer-background);
$layout-header-background: var(--layout-header-background);
$layout-sider-background: var(--layout-sider-background);
$layout-sider-background-light: var(--layout-sider-background-light);
$layout-trigger-background: var(--layout-trigger-background);
$layout-trigger-color: var(--layout-trigger-color);
$layout-trigger-background-light: var(--layout-trigger-background-light);
$layout-trigger-color-light: var(--layout-trigger-color-light);
$layout-header-header-color: var(--layout-header-header-color);
$layout-nav-ncx-menu: var(--layout-nav-ncx-menu);
$layout-nav-ncx-inline: var(--layout-nav-ncx-inline);
$layout-nav-search-menu: var(--layout-nav-search-menu);
$layout-nav-search-inline: var(--layout-nav-search-inline);
$theme-colors-text-dark: var(--theme-colors-text-dark);
$layout-header-text-main-color: var(--layout-header-text-main-color);
$layout-header-text-secondary: var(--layout-header-text-secondary);
$global-colors-grey-0: var(--global-colors-grey-0);
$global-colors-grey-2: var(--global-colors-grey-2);
$global-colors-grey-6: var(--global-colors-grey-6);
$global-colors-grey-8: var(--global-colors-grey-8);
$global-colors-grey-10: var(--global-colors-grey-10);
$global-colors-grey-15: var(--global-colors-grey-15);
$global-colors-grey-20: var(--global-colors-grey-20);
$global-colors-grey-25: var(--global-colors-grey-25);
$global-colors-grey-30: var(--global-colors-grey-30);
$global-colors-grey-35: var(--global-colors-grey-35);
$global-colors-grey-40: var(--global-colors-grey-40);
$global-colors-grey-45: var(--global-colors-grey-45);
$global-colors-grey-50: var(--global-colors-grey-50);
$global-colors-grey-55: var(--global-colors-grey-55);
$global-colors-grey-60: var(--global-colors-grey-60);
$global-colors-grey-65: var(--global-colors-grey-65);
$global-colors-grey-70: var(--global-colors-grey-70);
$global-colors-grey-75: var(--global-colors-grey-75);
$global-colors-grey-80: var(--global-colors-grey-80);
$global-colors-grey-85: var(--global-colors-grey-85);
$global-colors-grey-90: var(--global-colors-grey-90);
$global-colors-grey-96: var(--global-colors-grey-96);
$global-colors-grey-100: var(--global-colors-grey-100);
$global-colors-blue-main-10: var(--global-colors-blue-main-10);
$global-colors-cyan-10: var(--global-colors-cyan-10);
$global-colors-cyan-20: var(--global-colors-cyan-20);
$global-colors-cyan-30: var(--global-colors-cyan-30);
$global-colors-cyan-40: var(--global-colors-cyan-40);
$global-colors-cyan-50: var(--global-colors-cyan-50);
$global-colors-cyan-60: var(--global-colors-cyan-60);
$global-colors-cyan-70: var(--global-colors-cyan-70);
$global-colors-cyan-80: var(--global-colors-cyan-80);
$global-colors-cyan-90: var(--global-colors-cyan-90);
$global-colors-cyan-100: var(--global-colors-cyan-100);
$global-colors-blue-main-20: var(--global-colors-blue-main-20);
$global-colors-blue-main-30: var(--global-colors-blue-main-30);
$global-colors-blue-main-40: var(--global-colors-blue-main-40);
$global-colors-blue-main-50: var(--global-colors-blue-main-50);
$global-colors-blue-main-60: var(--global-colors-blue-main-60);
$global-colors-blue-main-70: var(--global-colors-blue-main-70);
$global-colors-blue-main-80: var(--global-colors-blue-main-80);
$global-colors-blue-main-90: var(--global-colors-blue-main-90);
$global-colors-blue-main-100: var(--global-colors-blue-main-100);
$global-colors-blue-secondary-10: var(--global-colors-blue-secondary-10);
$global-colors-blue-secondary-20: var(--global-colors-blue-secondary-20);
$global-colors-blue-secondary-30: var(--global-colors-blue-secondary-30);
$global-colors-blue-secondary-40: var(--global-colors-blue-secondary-40);
$global-colors-blue-secondary-50: var(--global-colors-blue-secondary-50);
$global-colors-blue-secondary-60: var(--global-colors-blue-secondary-60);
$global-colors-blue-secondary-70: var(--global-colors-blue-secondary-70);
$global-colors-blue-secondary-80: var(--global-colors-blue-secondary-80);
$global-colors-blue-secondary-90: var(--global-colors-blue-secondary-90);
$global-colors-blue-secondary-100: var(--global-colors-blue-secondary-100);
$global-colors-green-main-10: var(--global-colors-green-main-10);
$global-colors-green-main-20: var(--global-colors-green-main-20);
$global-colors-green-main-30: var(--global-colors-green-main-30);
$global-colors-green-main-40: var(--global-colors-green-main-40);
$global-colors-green-main-50: var(--global-colors-green-main-50);
$global-colors-green-main-60: var(--global-colors-green-main-60);
$global-colors-green-main-70: var(--global-colors-green-main-70);
$global-colors-green-main-80: var(--global-colors-green-main-80);
$global-colors-green-main-90: var(--global-colors-green-main-90);
$global-colors-green-main-100: var(--global-colors-green-main-100);
$global-colors-green-secondary-10: var(--global-colors-green-secondary-10);
$global-colors-green-secondary-20: var(--global-colors-green-secondary-20);
$global-colors-green-secondary-30: var(--global-colors-green-secondary-30);
$global-colors-green-secondary-40: var(--global-colors-green-secondary-40);
$global-colors-green-secondary-50: var(--global-colors-green-secondary-50);
$global-colors-green-secondary-60: var(--global-colors-green-secondary-60);
$global-colors-green-secondary-70: var(--global-colors-green-secondary-70);
$global-colors-green-secondary-80: var(--global-colors-green-secondary-80);
$global-colors-green-secondary-90: var(--global-colors-green-secondary-90);
$global-colors-green-secondary-100: var(--global-colors-green-secondary-100);
$global-colors-magenta-10: var(--global-colors-magenta-10);
$global-colors-magenta-20: var(--global-colors-magenta-20);
$global-colors-magenta-30: var(--global-colors-magenta-30);
$global-colors-magenta-40: var(--global-colors-magenta-40);
$global-colors-magenta-50: var(--global-colors-magenta-50);
$global-colors-magenta-60: var(--global-colors-magenta-60);
$global-colors-magenta-70: var(--global-colors-magenta-70);
$global-colors-magenta-80: var(--global-colors-magenta-80);
$global-colors-magenta-90: var(--global-colors-magenta-90);
$global-colors-magenta-100: var(--global-colors-magenta-100);
$global-colors-orange-10: var(--global-colors-orange-10);
$global-colors-orange-20: var(--global-colors-orange-20);
$global-colors-orange-30: var(--global-colors-orange-30);
$global-colors-orange-40: var(--global-colors-orange-40);
$global-colors-orange-50: var(--global-colors-orange-50);
$global-colors-orange-60: var(--global-colors-orange-60);
$global-colors-orange-70: var(--global-colors-orange-70);
$global-colors-orange-80: var(--global-colors-orange-80);
$global-colors-orange-90: var(--global-colors-orange-90);
$global-colors-orange-100: var(--global-colors-orange-100);
$global-colors-purple-10: var(--global-colors-purple-10);
$global-colors-purple-20: var(--global-colors-purple-20);
$global-colors-purple-30: var(--global-colors-purple-30);
$global-colors-purple-40: var(--global-colors-purple-40);
$global-colors-purple-50: var(--global-colors-purple-50);
$global-colors-purple-60: var(--global-colors-purple-60);
$global-colors-purple-70: var(--global-colors-purple-70);
$global-colors-purple-80: var(--global-colors-purple-80);
$global-colors-purple-90: var(--global-colors-purple-90);
$global-colors-purple-100: var(--global-colors-purple-100);
$global-colors-red-main-10: var(--global-colors-red-main-10);
$global-colors-red-main-20: var(--global-colors-red-main-20);
$global-colors-red-main-30: var(--global-colors-red-main-30);
$global-colors-red-main-40: var(--global-colors-red-main-40);
$global-colors-red-main-50: var(--global-colors-red-main-50);
$global-colors-red-main-60: var(--global-colors-red-main-60);
$global-colors-red-main-70: var(--global-colors-red-main-70);
$global-colors-red-main-80: var(--global-colors-red-main-80);
$global-colors-red-main-90: var(--global-colors-red-main-90);
$global-colors-red-main-100: var(--global-colors-red-main-100);
$global-colors-red-secondary-10: var(--global-colors-red-secondary-10);
$global-colors-red-secondary-20: var(--global-colors-red-secondary-20);
$global-colors-red-secondary-30: var(--global-colors-red-secondary-30);
$global-colors-red-secondary-40: var(--global-colors-red-secondary-40);
$global-colors-red-secondary-50: var(--global-colors-red-secondary-50);
$global-colors-red-secondary-60: var(--global-colors-red-secondary-60);
$global-colors-red-secondary-70: var(--global-colors-red-secondary-70);
$global-colors-red-secondary-80: var(--global-colors-red-secondary-80);
$global-colors-red-secondary-90: var(--global-colors-red-secondary-90);
$global-colors-red-secondary-100: var(--global-colors-red-secondary-100);
$global-colors-yellow-main-10: var(--global-colors-yellow-main-10);
$global-colors-yellow-main-20: var(--global-colors-yellow-main-20);
$global-colors-yellow-main-30: var(--global-colors-yellow-main-30);
$global-colors-yellow-main-40: var(--global-colors-yellow-main-40);
$global-colors-yellow-main-50: var(--global-colors-yellow-main-50);
$global-colors-yellow-main-60: var(--global-colors-yellow-main-60);
$global-colors-yellow-main-70: var(--global-colors-yellow-main-70);
$global-colors-yellow-main-80: var(--global-colors-yellow-main-80);
$global-colors-yellow-main-90: var(--global-colors-yellow-main-90);
$global-colors-yellow-main-100: var(--global-colors-yellow-main-100);
$global-colors-yellow-secondary-10: var(--global-colors-yellow-secondary-10);
$global-colors-yellow-secondary-20: var(--global-colors-yellow-secondary-20);
$global-colors-yellow-secondary-30: var(--global-colors-yellow-secondary-30);
$global-colors-yellow-secondary-40: var(--global-colors-yellow-secondary-40);
$global-colors-yellow-secondary-50: var(--global-colors-yellow-secondary-50);
$global-colors-yellow-secondary-60: var(--global-colors-yellow-secondary-60);
$global-colors-yellow-secondary-70: var(--global-colors-yellow-secondary-70);
$global-colors-yellow-secondary-80: var(--global-colors-yellow-secondary-80);
$global-colors-yellow-secondary-90: var(--global-colors-yellow-secondary-90);
$global-colors-yellow-secondary-100: var(--global-colors-yellow-secondary-100);
$theme-colors-alert-success-border-color: var(--theme-colors-alert-success-border-color);
$theme-colors-alert-success-bg-color: var(--theme-colors-alert-success-bg-color);
$theme-colors-alert-success-icon-color: var(--theme-colors-alert-success-icon-color);
$theme-colors-alert-info-border-color: var(--theme-colors-alert-info-border-color);
$theme-colors-alert-info-bg-color: var(--theme-colors-alert-info-bg-color);
$theme-colors-alert-info-icon-color: var(--theme-colors-alert-info-icon-color);
$theme-colors-alert-warning-border-color: var(--theme-colors-alert-warning-border-color);
$theme-colors-alert-warning-bg-color: var(--theme-colors-alert-warning-bg-color);
$theme-colors-alert-warning-icon-color: var(--theme-colors-alert-warning-icon-color);
$theme-colors-alert-error-border-color: var(--theme-colors-alert-error-border-color);
$theme-colors-alert-error-bg-color: var(--theme-colors-alert-error-bg-color);
$theme-colors-alert-error-icon-color: var(--theme-colors-alert-error-icon-color);
$theme-colors-alert-message-color: var(--theme-colors-alert-message-color);
$theme-colors-alert-message-text-color: var(--theme-colors-alert-message-text-color);
$theme-colors-alert-message-close-out: var(--theme-colors-alert-message-close-out);
$theme-colors-alert-message-close-hover-color: var(--theme-colors-alert-message-close-hover-color);
$theme-colors-anchor-bg: var(--theme-colors-anchor-bg);
$theme-colors-avatar-bg: var(--theme-colors-avatar-bg);
$theme-colors-avatar-color: var(--theme-colors-avatar-color);
$theme-colors-avatar-group-border-color: var(--theme-colors-avatar-group-border-color);
$theme-colors-anchor-border-color: var(--theme-colors-anchor-border-color);
$theme-colors-back-top-color: var(--theme-colors-back-top-color);
$theme-colors-back-top-bg: var(--theme-colors-back-top-bg);
$theme-colors-back-top-hover-bg: var(--theme-colors-back-top-hover-bg);
$theme-colors-background-color-light: var(--theme-colors-background-color-light);
$theme-colors-background-color-base: var(--theme-colors-background-color-base);
$theme-colors-background-color-card: var(--theme-colors-background-color-card);
$theme-colors-background-color-rhd: var(--theme-colors-background-color-rhd);
$theme-colors-background-color--rhd---alt: var(--theme-colors-background-color--rhd---alt);
$theme-colors-badge-text-color: var(--theme-colors-badge-text-color);
$theme-colors-badge-badge-color: var(--theme-colors-badge-badge-color);
$theme-colors-body-background: var(--theme-colors-body-background);
$theme-colors-border-color-base: var(--theme-colors-border-color-base);
$theme-colors-bread-crumb-base-color: var(--theme-colors-bread-crumb-base-color);
$theme-colors-bread-crumb-last-item-color: var(--theme-colors-bread-crumb-last-item-color);
$theme-colors-bread-crumb-link-color: var(--theme-colors-bread-crumb-link-color);
$theme-colors-bread-crumb-link-color-hover: var(--theme-colors-bread-crumb-link-color-hover);
$theme-colors-bread-crumb-separator-color: var(--theme-colors-bread-crumb-separator-color);
$theme-colors-button-bg: var(--theme-colors-button-bg);
$theme-colors-button-check-bg: var(--theme-colors-button-check-bg);
$theme-colors-button-button-color: var(--theme-colors-button-button-color);
$theme-colors-button-button-hover-color: var(--theme-colors-button-button-hover-color);
$theme-colors-button-button-active-color: var(--theme-colors-button-button-active-color);
$theme-colors-button-disabled-button-checked-bg: var(--theme-colors-button-disabled-button-checked-bg);
$theme-colors-button-disabled-button-checked-color: var(--theme-colors-button-disabled-button-checked-color);
$theme-colors-border-color-split: var(--theme-colors-border-color-split);
$theme-colors-border-color-inverse: var(--theme-colors-border-color-inverse);
$theme-colors-buttons-primary-primary-color: var(--theme-colors-buttons-primary-primary-color);
$theme-colors-calendar-bg: var(--theme-colors-calendar-bg);
$theme-colors-card-head-color: var(--theme-colors-card-head-color);
$theme-colors-cascader-bg: var(--theme-colors-cascader-bg);
$theme-colors-cascader-item-selected-bg: var(--theme-colors-cascader-item-selected-bg);
$theme-colors-cascader-menu-bg: var(--theme-colors-cascader-menu-bg);
$theme-colors-cascader-menu-border-color-split: var(--theme-colors-cascader-menu-border-color-split);
$theme-colors-card-header-background: var(--theme-colors-card-header-background);
$theme-colors-card-actions-background: var(--theme-colors-card-actions-background);
$theme-colors-card-background: var(--theme-colors-card-background);
$theme-colors-card-head-extra-color: var(--theme-colors-card-head-extra-color);
$theme-colors-calendar-input-bg: var(--theme-colors-calendar-input-bg);
$theme-colors-calendar-border-color: var(--theme-colors-calendar-border-color);
$theme-colors-calendar-item-active-bg: var(--theme-colors-calendar-item-active-bg);
$theme-colors-calendar-column-active-bg: var(--theme-colors-calendar-column-active-bg);
$theme-colors-calendar-full-bg: var(--theme-colors-calendar-full-bg);
$theme-colors-calendar-full-panel-bg: var(--theme-colors-calendar-full-panel-bg);
$theme-colors-buttons-primary-bg: var(--theme-colors-buttons-primary-bg);
$theme-colors-buttons-default-color: var(--theme-colors-buttons-default-color);
$theme-colors-buttons-default-bg: var(--theme-colors-buttons-default-bg);
$theme-colors-buttons-default-border: var(--theme-colors-buttons-default-border);
$theme-colors-buttons-danger-color: var(--theme-colors-buttons-danger-color);
$theme-colors-buttons-danger-bg: var(--theme-colors-buttons-danger-bg);
$theme-colors-buttons-danger-border: var(--theme-colors-buttons-danger-border);
$theme-colors-buttons-disable-color: var(--theme-colors-buttons-disable-color);
$theme-colors-buttons-disable-bg: var(--theme-colors-buttons-disable-bg);
$theme-colors-buttons-disable-border: var(--theme-colors-buttons-disable-border);
$theme-colors-buttons-default-ghost-color: var(--theme-colors-buttons-default-ghost-color);
$theme-colors-buttons-default-ghost-bg: var(--theme-colors-buttons-default-ghost-bg);
$theme-colors-buttons-default-ghost-border: var(--theme-colors-buttons-default-ghost-border);
$theme-colors-buttons-group-border: var(--theme-colors-buttons-group-border);
$theme-colors-buttons-link-hover-bg: var(--theme-colors-buttons-link-hover-bg);
$theme-colors-buttons-text-hover-bg: var(--theme-colors-buttons-text-hover-bg);
$theme-colors-checkbox-color: var(--theme-colors-checkbox-color);
$theme-colors-checkbox-check-color: var(--theme-colors-checkbox-check-color);
$theme-colors-checkbox-check-bg: var(--theme-colors-checkbox-check-bg);
$theme-colors-collapse-header-bg: var(--theme-colors-collapse-header-bg);
$theme-colors-collapse-content-bg: var(--theme-colors-collapse-content-bg);
$theme-colors-comment-author-name-color: var(--theme-colors-comment-author-name-color);
$theme-colors-comment-author-time-color: var(--theme-colors-comment-author-time-color);
$theme-colors-comment-action-color: var(--theme-colors-comment-action-color);
$theme-colors-comment-action-hover-color: var(--theme-colors-comment-action-hover-color);
$theme-colors-component-background: var(--theme-colors-component-background);
$theme-colors-descriptions-bg: var(--theme-colors-descriptions-bg);
$theme-colors-descriptions-extra-color: var(--theme-colors-descriptions-extra-color);
$theme-colors-disabled-color: var(--theme-colors-disabled-color);
$theme-colors-disabled-bg: var(--theme-colors-disabled-bg);
$theme-colors-disabled-active-bg: var(--theme-colors-disabled-active-bg);
$theme-colors-disabled-color-dark: var(--theme-colors-disabled-color-dark);
$theme-colors-divider-bg: var(--theme-colors-divider-bg);
$theme-colors-dot-dot-color: var(--theme-colors-dot-dot-color);
$theme-colors-dot-dot-disabled-color: var(--theme-colors-dot-dot-disabled-color);
$theme-colors-drawer-bg: var(--theme-colors-drawer-bg);
$theme-colors-dropdown-selected-color: var(--theme-colors-dropdown-selected-color);
$theme-colors-dropdown-menu-submenu-disabled-bg: var(--theme-colors-dropdown-menu-submenu-disabled-bg);
$theme-colors-dropdown-selected-bg: var(--theme-colors-dropdown-selected-bg);
$theme-colors-dropdown-menu-bg: var(--theme-colors-dropdown-menu-bg);
$theme-colors-empty-img-default-elipse: var(--theme-colors-empty-img-default-elipse);
$theme-colors-empty-img-default-path-1: var(--theme-colors-empty-img-default-path-1);
$theme-colors-empty-img-default-path-3: var(--theme-colors-empty-img-default-path-3);
$theme-colors-empty-img-default-path-4: var(--theme-colors-empty-img-default-path-4);
$theme-colors-empty-img-default-path-5: var(--theme-colors-empty-img-default-path-5);
$theme-colors-error-color: var(--theme-colors-error-color);
$theme-colors-form-warning-input-bg: var(--theme-colors-form-warning-input-bg);
$theme-colors-form-error-input-bg: var(--theme-colors-form-error-input-bg);
$theme-colors-error-color-hover: var(--theme-colors-error-color-hover);
$theme-colors-error-color-active: var(--theme-colors-error-color-active);
$theme-colors-error-color-outline: var(--theme-colors-error-color-outline);
$theme-colors-error-color-deprecated-bg: var(--theme-colors-error-color-deprecated-bg);
$theme-colors-error-color-deprecated-border: var(--theme-colors-error-color-deprecated-border);
$theme-colors-header-color: var(--theme-colors-header-color);
$theme-colors-header-component-header-color: var(--theme-colors-header-component-header-color);
$theme-colors-highlight-color: var(--theme-colors-highlight-color);
$theme-colors-highlight-search-return-bg: var(--theme-colors-highlight-search-return-bg);
$theme-colors-highlight-search-return-color: var(--theme-colors-highlight-search-return-color);
$theme-colors-icon-color: var(--theme-colors-icon-color);
$theme-colors-icon-secondary: var(--theme-colors-icon-secondary);
$theme-colors-images-bg: var(--theme-colors-images-bg);
$theme-colors-images-color: var(--theme-colors-images-color);
$theme-colors-images-preview-operation-color: var(--theme-colors-images-preview-operation-color);
$theme-colors-info-color: var(--theme-colors-info-color);
$theme-colors-input-placeholder-color: var(--theme-colors-input-placeholder-color);
$theme-colors-input-color: var(--theme-colors-input-color);
$theme-colors-input-icon-color: var(--theme-colors-input-icon-color);
$theme-colors-input-border-color: var(--theme-colors-input-border-color);
$theme-colors-input-bg: var(--theme-colors-input-bg);
$theme-colors-input-number-hover-border-color: var(--theme-colors-input-number-hover-border-color);
$theme-colors-input-number-handler-active-bg: var(--theme-colors-input-number-handler-active-bg);
$theme-colors-input-number-handler-hover-bg: var(--theme-colors-input-number-handler-hover-bg);
$theme-colors-input-number-handler-bg: var(--theme-colors-input-number-handler-bg);
$theme-colors-input-number-handler-border-color: var(--theme-colors-input-number-handler-border-color);
$theme-colors-input-addon-bg: var(--theme-colors-input-addon-bg);
$theme-colors-input-hover-border-color: var(--theme-colors-input-hover-border-color);
$theme-colors-input-disabled-bg: var(--theme-colors-input-disabled-bg);
$theme-colors-input-icon-hover-color: var(--theme-colors-input-icon-hover-color);
$theme-colors-input-disabled-color: var(--theme-colors-input-disabled-color);
$theme-colors-input-read-only: var(--theme-colors-input-read-only);
$theme-colors-info-color-deprecated-bg: var(--theme-colors-info-color-deprecated-bg);
$theme-colors-info-color-deprecated-border: var(--theme-colors-info-color-deprecated-border);
$theme-colors-item-active-bg: var(--theme-colors-item-active-bg);
$theme-colors-item-hover-bg: var(--theme-colors-item-hover-bg);
$theme-colors-label-required-color: var(--theme-colors-label-required-color);
$theme-colors-label-color: var(--theme-colors-label-color);
$theme-colors-link-color-base: var(--theme-colors-link-color-base);
$theme-colors-link-hover: var(--theme-colors-link-hover);
$theme-colors-link-color-split: var(--theme-colors-link-color-split);
$theme-colors-link-color-inverse: var(--theme-colors-link-color-inverse);
$theme-colors-link-active: var(--theme-colors-link-active);
$theme-colors-list-header-background: var(--theme-colors-list-header-background);
$theme-colors-page-header-back-color: var(--theme-colors-page-header-back-color);
$theme-colors-pagination-item-bg: var(--theme-colors-pagination-item-bg);
$theme-colors-pagination-item-bg-active: var(--theme-colors-pagination-item-bg-active);
$theme-colors-pagination-item-link-bg: var(--theme-colors-pagination-item-link-bg);
$theme-colors-pagination-item-disabled-color-active: var(--theme-colors-pagination-item-disabled-color-active);
$theme-colors-pagination-item-disabled-bg-active: var(--theme-colors-pagination-item-disabled-bg-active);
$theme-colors-pagination-item-input-bg: var(--theme-colors-pagination-item-input-bg);
$theme-colors-list-footer-background: var(--theme-colors-list-footer-background);
$theme-colors-list-customize-card-bg: var(--theme-colors-list-customize-card-bg);
$theme-colors-picker-bg: var(--theme-colors-picker-bg);
$theme-colors-popover-bg: var(--theme-colors-popover-bg);
$theme-colors-popover-color: var(--theme-colors-popover-color);
$theme-colors-popover-arrow-color: var(--theme-colors-popover-arrow-color);
$theme-colors-popover-arrow-outer-color: var(--theme-colors-popover-arrow-outer-color);
$theme-colors-popover-background: var(--theme-colors-popover-background);
$theme-colors-popover-customize-border-color: var(--theme-colors-popover-customize-border-color);
$theme-colors-primary-10: var(--theme-colors-primary-10);
$theme-colors-primary-20: var(--theme-colors-primary-20);
$theme-colors-primary-30: var(--theme-colors-primary-30);
$theme-colors-primary-40: var(--theme-colors-primary-40);
$theme-colors-primary-50: var(--theme-colors-primary-50);
$theme-colors-primary-60: var(--theme-colors-primary-60);
$theme-colors-primary-70: var(--theme-colors-primary-70);
$theme-colors-primary-80: var(--theme-colors-primary-80);
$theme-colors-primary-90: var(--theme-colors-primary-90);
$theme-colors-primary-100: var(--theme-colors-primary-100);
$theme-colors-primary-color: var(--theme-colors-primary-color);
$theme-colors-primary-color-hover: var(--theme-colors-primary-color-hover);
$theme-colors-primary-color-active: var(--theme-colors-primary-color-active);
$theme-colors-primary-color-outline: var(--theme-colors-primary-color-outline);
$theme-colors-picker-basic-cell-hover-color: var(--theme-colors-picker-basic-cell-hover-color);
$theme-colors-picker-basic-cell-active-with-range-color: var(--theme-colors-picker-basic-cell-active-with-range-color);
$theme-colors-picker-basic-cell-disabled-bg: var(--theme-colors-picker-basic-cell-disabled-bg);
$theme-colors-picker-border-color: var(--theme-colors-picker-border-color);
$theme-colors-picker-basic-cell-hover-with-range-color: var(--theme-colors-picker-basic-cell-hover-with-range-color);
$theme-colors-picker-date-hover-range-color: var(--theme-colors-picker-date-hover-range-color);
$theme-colors-processing-color: var(--theme-colors-processing-color);
$theme-colors-progress-default-color: var(--theme-colors-progress-default-color);
$theme-colors-progress-remaining-color: var(--theme-colors-progress-remaining-color);
$theme-colors-progress-info-text-color: var(--theme-colors-progress-info-text-color);
$theme-colors-progress-steps-item-bg: var(--theme-colors-progress-steps-item-bg);
$theme-colors-progress-text-color: var(--theme-colors-progress-text-color);
$theme-colors-process-tail-color: var(--theme-colors-process-tail-color);
$theme-colors-mark-bg: var(--theme-colors-mark-bg);
$theme-colors-mentions-dropdown-bg: var(--theme-colors-mentions-dropdown-bg);
$theme-colors-menu-bg: var(--theme-colors-menu-bg);
$theme-colors-menu-popup-bg: var(--theme-colors-menu-popup-bg);
$theme-colors-menu-item-color: var(--theme-colors-menu-item-color);
$theme-colors-menu-inline-submenu-bg: var(--theme-colors-menu-inline-submenu-bg);
$theme-colors-menu-highlight-color: var(--theme-colors-menu-highlight-color);
$theme-colors-menu-highlight-danger-color: var(--theme-colors-menu-highlight-danger-color);
$theme-colors-menu-item-active-bg: var(--theme-colors-menu-item-active-bg);
$theme-colors-menu-item-active-danger-bg: var(--theme-colors-menu-item-active-danger-bg);
$theme-colors-menu-item-group-title-color: var(--theme-colors-menu-item-group-title-color);
$theme-colors-menu-dark-color: var(--theme-colors-menu-dark-color);
$theme-colors-menu-dark-danger-color: var(--theme-colors-menu-dark-danger-color);
$theme-colors-menu-dark-bg: var(--theme-colors-menu-dark-bg);
$theme-colors-menu-dark-arrow-color: var(--theme-colors-menu-dark-arrow-color);
$theme-colors-menu-dark-inline-submenu-bg: var(--theme-colors-menu-dark-inline-submenu-bg);
$theme-colors-menu-dark-item-active-bg: var(--theme-colors-menu-dark-item-active-bg);
$theme-colors-menu-dark-item-active-danger-bg: var(--theme-colors-menu-dark-item-active-danger-bg);
$theme-colors-menu-dark-selected-item-icon-color: var(--theme-colors-menu-dark-selected-item-icon-color);
$theme-colors-menu-dark-selected-item-text-color: var(--theme-colors-menu-dark-selected-item-text-color);
$theme-colors-menu-dark-item-hover-bg: var(--theme-colors-menu-dark-item-hover-bg);
$theme-colors-menu-dark-highlight-color: var(--theme-colors-menu-dark-highlight-color);
$theme-colors-message-notice-content-bg: var(--theme-colors-message-notice-content-bg);
$theme-colors-mentions-dropdown-menu-item-hover-bg: var(--theme-colors-mentions-dropdown-menu-item-hover-bg);
$theme-colors-modal-header-bg: var(--theme-colors-modal-header-bg);
$theme-colors-modal-header-border-color-split: var(--theme-colors-modal-header-border-color-split);
$theme-colors-modal-content-bg: var(--theme-colors-modal-content-bg);
$theme-colors-modal-heading-color: var(--theme-colors-modal-heading-color);
$theme-colors-modal-close-color: var(--theme-colors-modal-close-color);
$theme-colors-modal-footer-bg: var(--theme-colors-modal-footer-bg);
$theme-colors-modal-footer-border-color-split: var(--theme-colors-modal-footer-border-color-split);
$theme-colors-modal-mask-bg: var(--theme-colors-modal-mask-bg);
$theme-colors-normal-color: var(--theme-colors-normal-color);
$theme-colors-notifications-bg: var(--theme-colors-notifications-bg);
$theme-colors-radio-solid-checked-color: var(--theme-colors-radio-solid-checked-color);
$theme-colors-radio-dot-color: var(--theme-colors-radio-dot-color);
$theme-colors-radio-button-bg: var(--theme-colors-radio-button-bg);
$theme-colors-radio-button-checked-bg: var(--theme-colors-radio-button-checked-bg);
$theme-colors-radio-button-color: var(--theme-colors-radio-button-color);
$theme-colors-radio-button-hover-color: var(--theme-colors-radio-button-hover-color);
$theme-colors-radio-button-active-color: var(--theme-colors-radio-button-active-color);
$theme-colors-radio-button-disabled-checked-bg: var(--theme-colors-radio-button-disabled-checked-bg);
$theme-colors-radio-button-disabled-button-checked-color: var(--theme-colors-radio-button-disabled-button-checked-color);
$theme-colors-radio-dot-disabled-color: var(--theme-colors-radio-dot-disabled-color);
$theme-colors-rate-star-color: var(--theme-colors-rate-star-color);
$theme-colors-rate-star-bg: var(--theme-colors-rate-star-bg);
$theme-colors-segmented-bg: var(--theme-colors-segmented-bg);
$theme-colors-segmented-hover-bg: var(--theme-colors-segmented-hover-bg);
$theme-colors-segmented-selected-bg: var(--theme-colors-segmented-selected-bg);
$theme-colors-segmented-label-color: var(--theme-colors-segmented-label-color);
$theme-colors-segmented-label-hover-color: var(--theme-colors-segmented-label-hover-color);
$theme-colors-select-border-color: var(--theme-colors-select-border-color);
$theme-colors-select-item-selected-color: var(--theme-colors-select-item-selected-color);
$theme-colors-select-dropdown-bg: var(--theme-colors-select-dropdown-bg);
$theme-colors-select-item-selected-bg: var(--theme-colors-select-item-selected-bg);
$theme-colors-select-item-active-bg: var(--theme-colors-select-item-active-bg);
$theme-colors-select-background: var(--theme-colors-select-background);
$theme-colors-select-clear-background: var(--theme-colors-select-clear-background);
$theme-colors-select-selection-item-bg: var(--theme-colors-select-selection-item-bg);
$theme-colors-select-selection-item-border-color: var(--theme-colors-select-selection-item-border-color);
$theme-colors-select-multiple-disabled-baclground: var(--theme-colors-select-multiple-disabled-baclground);
$theme-colors-select-multiple-item-disabled-color: var(--theme-colors-select-multiple-item-disabled-color);
$theme-colors-select-multiple-item-disabled-border-color: var(--theme-colors-select-multiple-item-disabled-border-color);
$theme-colors-shadow-color: var(--theme-colors-shadow-color);
$theme-colors-skeleton-color: var(--theme-colors-skeleton-color);
$theme-colors-slider-rail-background-color: var(--theme-colors-slider-rail-background-color);
$theme-colors-slider-rail-background-color-hover: var(--theme-colors-slider-rail-background-color-hover);
$theme-colors-slider-track-background-color: var(--theme-colors-slider-track-background-color);
$theme-colors-slider-track-background-color-hover: var(--theme-colors-slider-track-background-color-hover);
$theme-colors-slider-handle-background-color: var(--theme-colors-slider-handle-background-color);
$theme-colors-slider-handle-color: var(--theme-colors-slider-handle-color);
$theme-colors-slider-handle-color-hover: var(--theme-colors-slider-handle-color-hover);
$theme-colors-slider-handle-color-tooltip-open: var(--theme-colors-slider-handle-color-tooltip-open);
$theme-colors-slider-dor-border-color: var(--theme-colors-slider-dor-border-color);
$theme-colors-slider-disabled-color: var(--theme-colors-slider-disabled-color);
$theme-colors-slider-disabled-background-color: var(--theme-colors-slider-disabled-background-color);
$theme-colors-shadow-color-inverse: var(--theme-colors-shadow-color-inverse);
$theme-colors-solid-checked-color: var(--theme-colors-solid-checked-color);
$theme-colors-status-new-default: var(--theme-colors-status-new-default);
$theme-colors-status-new-background: var(--theme-colors-status-new-background);
$theme-colors-status-new-border: var(--theme-colors-status-new-border);
$theme-colors-status-in-progress-default: var(--theme-colors-status-in-progress-default);
$theme-colors-status-in-progress-background: var(--theme-colors-status-in-progress-background);
$theme-colors-status-in-progress-border: var(--theme-colors-status-in-progress-border);
$theme-colors-status-revised-default: var(--theme-colors-status-revised-default);
$theme-colors-status-revised-background: var(--theme-colors-status-revised-background);
$theme-colors-status-revised-border: var(--theme-colors-status-revised-border);
$theme-colors-status-completed-default: var(--theme-colors-status-completed-default);
$theme-colors-status-completed-background: var(--theme-colors-status-completed-background);
$theme-colors-status-completed-border: var(--theme-colors-status-completed-border);
$theme-colors-status-concan-default: var(--theme-colors-status-concan-default);
$theme-colors-status-concan-background: var(--theme-colors-status-concan-background);
$theme-colors-status-concan-border: var(--theme-colors-status-concan-border);
$theme-colors-status-cancelled-default: var(--theme-colors-status-cancelled-default);
$theme-colors-status-cancelled-background: var(--theme-colors-status-cancelled-background);
$theme-colors-status-cancelled-border: var(--theme-colors-status-cancelled-border);
$theme-colors-steps-background: var(--theme-colors-steps-background);
$theme-colors-success-color: var(--theme-colors-success-color);
$theme-colors-switch-color: var(--theme-colors-switch-color);
$theme-colors-switch-bg: var(--theme-colors-switch-bg);
$theme-colors-success-color-hover: var(--theme-colors-success-color-hover);
$theme-colors-success-color-active: var(--theme-colors-success-color-active);
$theme-colors-success-color-outline: var(--theme-colors-success-color-outline);
$theme-colors-success-color-deprecated-bg: var(--theme-colors-success-color-deprecated-bg);
$theme-colors-success-color-deprecated-border: var(--theme-colors-success-color-deprecated-border);
$theme-colors-table-bg: var(--theme-colors-table-bg);
$theme-colors-tabs-card-head-background: var(--theme-colors-tabs-card-head-background);
$theme-colors-tabs-card-active-color: var(--theme-colors-tabs-card-active-color);
$theme-colors-tabs-ink-bar-color: var(--theme-colors-tabs-ink-bar-color);
$theme-colors-tabs-highlight-color: var(--theme-colors-tabs-highlight-color);
$theme-colors-tabs-hover-color: var(--theme-colors-tabs-hover-color);
$theme-colors-tabs-active-color: var(--theme-colors-tabs-active-color);
$theme-colors-table-header-bg: var(--theme-colors-table-header-bg);
$theme-colors-table-header-color: var(--theme-colors-table-header-color);
$theme-colors-table-header-sort-bg: var(--theme-colors-table-header-sort-bg);
$theme-colors-table-body-sort-bg: var(--theme-colors-table-body-sort-bg);
$theme-colors-table-row-hover-bg: var(--theme-colors-table-row-hover-bg);
$theme-colors-table-selected-row-bg: var(--theme-colors-table-selected-row-bg);
$theme-colors-table-body-selected-sort-bg: var(--theme-colors-table-body-selected-sort-bg);
$theme-colors-table-expand-row-bg: var(--theme-colors-table-expand-row-bg);
$theme-colors-table-border-color: var(--theme-colors-table-border-color);
$theme-colors-table-footer-bg: var(--theme-colors-table-footer-bg);
$theme-colors-table-footer-color: var(--theme-colors-table-footer-color);
$theme-colors-table-header-bg-sm: var(--theme-colors-table-header-bg-sm);
$theme-colors-table-header-cell-split-color: var(--theme-colors-table-header-cell-split-color);
$theme-colors-table-header-sort-active-bg: var(--theme-colors-table-header-sort-active-bg);
$theme-colors-table-fixed-header-sort-active-bg: var(--theme-colors-table-fixed-header-sort-active-bg);
$theme-colors-table-header-filter-active-bg: var(--theme-colors-table-header-filter-active-bg);
$theme-colors-table-filter-dropdown-bg: var(--theme-colors-table-filter-dropdown-bg);
$theme-colors-table-expand-icon-bg: var(--theme-colors-table-expand-icon-bg);
$theme-colors-table-sticky-scroll-bar-bg: var(--theme-colors-table-sticky-scroll-bar-bg);
$theme-colors-tag-default-bg: var(--theme-colors-tag-default-bg);
$theme-colors-tag-default-color: var(--theme-colors-tag-default-color);
$theme-colors-text-color: var(--theme-colors-text-color);
$theme-colors-timeline-color: var(--theme-colors-timeline-color);
$theme-colors-tooltip-color: var(--theme-colors-tooltip-color);
$theme-colors-transfer-disabled-bg: var(--theme-colors-transfer-disabled-bg);
$theme-colors-transfer-item-hover-bg: var(--theme-colors-transfer-item-hover-bg);
$theme-colors-tooltip-bg: var(--theme-colors-tooltip-bg);
$theme-colors-tooltip-arrow-color: var(--theme-colors-tooltip-arrow-color);
$theme-colors-timeline-dot-color: var(--theme-colors-timeline-dot-color);
$theme-colors-timeline-dot-bg: var(--theme-colors-timeline-dot-bg);
$theme-colors-text-color-secondary: var(--theme-colors-text-color-secondary);
$theme-colors-text-color-inverse: var(--theme-colors-text-color-inverse);
$theme-colors-text-selection-background: var(--theme-colors-text-selection-background);
$theme-colors-tree-bg: var(--theme-colors-tree-bg);
$theme-colors-tree-directory-selected-color: var(--theme-colors-tree-directory-selected-color);
$theme-colors-tree-directory-selected-bg: var(--theme-colors-tree-directory-selected-bg);
$theme-colors-tree-node-hover-bg: var(--theme-colors-tree-node-hover-bg);
$theme-colors-tree-node-selected-bg: var(--theme-colors-tree-node-selected-bg);
$theme-colors-upload-actions-color: var(--theme-colors-upload-actions-color);
$theme-colors-warning-color: var(--theme-colors-warning-color);
$theme-colors-warning-color-hover: var(--theme-colors-warning-color-hover);
$theme-colors-warning-color-active: var(--theme-colors-warning-color-active);
$theme-colors-warning-color-outline: var(--theme-colors-warning-color-outline);
$theme-colors-warning-color-deprecated-bg: var(--theme-colors-warning-color-deprecated-bg);
$theme-colors-warning-color-deprecated-border: var(--theme-colors-warning-color-deprecated-border);
$layout-body-body-background: var(--layout-body-body-background);
$layout-footer-background: var(--layout-footer-background);
$layout-header-background: var(--layout-header-background);
$layout-sider-background: var(--layout-sider-background);
$layout-sider-background-light: var(--layout-sider-background-light);
$layout-trigger-background: var(--layout-trigger-background);
$layout-trigger-color: var(--layout-trigger-color);
$layout-trigger-background-light: var(--layout-trigger-background-light);
$layout-trigger-color-light: var(--layout-trigger-color-light);
$layout-header-header-color: var(--layout-header-header-color);
$layout-nav-ncx-menu: var(--layout-nav-ncx-menu);
$layout-nav-ncx-inline: var(--layout-nav-ncx-inline);
$layout-nav-search-menu: var(--layout-nav-search-menu);
$layout-nav-search-inline: var(--layout-nav-search-inline);
$theme-colors-text-dark: var(--theme-colors-text-dark);
$layout-header-text-main-color: var(--layout-header-text-main-color);
$layout-header-text-secondary: var(--layout-header-text-secondary);