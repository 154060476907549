@import 'src/styles/utilities/utilities';

// Give normal HTML buttons a default style
button:not(.ant-btn):not(.ant-switch):not(.ant-table-row-expand-icon):not(
    .attachment
  ) {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

// This is an additional class to style AntDesign buttons.
// Use 'secondary' class to apply a white/blue background when selected in light mode and a blue/dark background when selected in dark mode
// This is part of the updated NCX design.
body.default {
  .ant-btn {
    &.secondary {
      background: $gray-1;
      color: $gray-12;

      &:not(.active) {
        background: $gray-21;
      }
    }
  }
}

body.dark {
  .ant-btn {
    &.secondary {
      // ant design blue
      background: #177ddc;
      color: $gray-1;

      &:not(.active) {
        background: $gray-21;
      }
    }
  }
}
