@import 'src/styles/utilities/utilities';

// Stack date ranger picker panels
@media screen and (min-width: 360px) and (max-width: 767px) {
  .ant-picker-panel-container {
    .ant-picker-panels {
      flex-wrap: wrap !important;
    }
  }
  .ant-picker-range-wrapper {
    width: 280px;
  }
}
