@import 'src/styles/utilities/utilities';

body {
  &.default {
    .ant-segmented {
      background-color: #0000000a;
    }
    .ant-segmented-item-label {
      color: #000000d9;
    }

    .ant-segmented-item-selected {
      background: #ffffff;
      color: #000000d9;
    }
  }
}

body {
  &.dark {
    .ant-segmented {
      background-color: #0000000a;
      border: 1px solid #424242;
    }

    .ant-segmented-item-selected {
      background: #1890ff;
      color: #ffffff;
    }
  }
}

.ant-segmented {
  border-radius: 2px;
}

.ant-segmented-item-label {
  border-radius: 2px;
  font-family: $sf-pro-display;
  font-size: 14px;
  padding: 0 12px;

  @media #{$min-medium} {
    padding: 4px 12px 4px 12px;
    font-size: 16px;
  }
  font-weight: 400;
}
