@import 'src/styles/utilities/utilities_new';

body,
html {
  font-family: $sf-pro-display;

  // don't do this!  will cause issues with mobile browsers --mparillo
  // overflow: hidden !important;

  user-select: text;

}

body,
.ant-layout {
  margin: 0;
  font-style: normal;
  background: var(--primary-background-color);
  color: var(--primary-font-color);
}

a {
  text-decoration: none;
  color: #1890ff;

  &:hover,
  &:active {
    color: #1890ff;
  }
}

.container {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerLabel {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
}

.snippet {
  color: $global-colors-purple-80;
  font-weight: 700;
}

.remove-hide {
  .anticon-close {
    display: none !important;
  }
}

.ant-divider-horizontal-privacy {
  @include settingDivider(100%);
}

.ant-upload-list-item {
  a {
    color: rgba(0, 0, 0, 0.85);
  }
}

/*Manage Folloers CSS End */
@media screen and (min-width: 360px) and (max-width: 767px) {
  .ant-upload-list-picture .ant-upload-list-item {
    width: 100% !important;
  }
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.profile {
  .ant-descriptions-item-label {
    font-size: 12px !important;
    line-height: 20px;
    color: #262626;
  }

  nz-descriptions {
    .title {
      font-weight: 600;
      font-size: 12px !important;
      line-height: 20px;
      color: #000000;
      margin: 0px 12px;
    }
  }
}

.alert {
  .ant-popover-inner-content {
    padding: 0px !important;
  }
}

#toolbarItems {
  .ant-popover-inner-content {
    display: none;
  }

  .ant-popover-title {
    padding: 0;
  }
}

.groupLanding {
  .ant-pagination {
    display: none;
  }
}

//NCX -1548 Start
.lightbox .lb-image {
  position: absolute !important;
  left: 0 !important;
  z-index: 999 !important;
}

.lb-nav a.lb-next {
  width: 60%;
  right: 0;
  left: 58px;
  position: relative;
  font-size: 16px;
  color: black;
  opacity: 0.75;
}

.lb-nav a.lb-prev {
  left: -70px;
  width: 40%;
  position: relative;
  font-size: 16px;
  color: black;
  opacity: 0.75;
}

/* Custom Mentions */
.customMentions {
  font-weight: bold;
  color: grey;
  font-size: 18px;
  cursor: not-allowed;
  border-bottom: 1px solid grey;
  pointer-events: unset;
}

/*alert card css start*/
.alertsCard {
  .ant-card-body {
    padding: 24px !important;
  }
}

// embedly template css
.storyDescription,
.postDescription,
.postContent,
.discontent {
  table {
    border: none;

    tr {
      td {
        border: none;
      }
    }
  }
}

.media-options-group .ant-checkbox-group-item {
  display: block;
  margin-bottom: 10px;
}

/*IOS shell UI CSS*/
.iosDeviceContainer {
  .ant-card-body {
    padding: 0;
  }
}

/*IOS shell UI CSS  */
@media only screen and (min-width: 360px) and (max-width: 767px) {
  .date-picker-div {
    display: inline-block;

    .ant-calendar-picker {
      .ant-calendar-picker-input.ant-input {
        border-style: none;
        padding: 0;
      }

      .ant-calendar-range-picker-input {
        display: none;
      }

      .ant-calendar-range-picker-separator {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .date-picker-div {
    display: inline-block;

    .ant-calendar-picker {
      .ant-calendar-picker-input.ant-input {
        border-style: none;
        padding: 0px;
        width: 210px;
      }
    }

    .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
      visibility: visible !important;
    }

    .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
      visibility: visible !important;
    }

    .ant-picker-panels > *:last-child {
      display: none;
    }

    .ant-picker-panel-container,
    .ant-picker-footer {
      width: 280px !important;
    }

    .ant-picker-footer-extra > div {
      flex-wrap: wrap !important;
    }
  }
  .post-section-grid {
    // nz-carousel.ant-carousel{
    //   height: 198px;
    // }
  }
}

@media only screen and (min-width: 375px) and (max-width: 667px) {
  .post-section-grid {
    nz-carousel.ant-carousel {
      // height: 180px;
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .post-section-grid {
    nz-carousel.ant-carousel {
      //height: 198px;
    }
  }
  .date-picker-div .ant-calendar-picker .ant-calendar-picker-input.ant-input {
    border-style: none;
    padding: 0px;
  }
  .setBorder {
    width: 178px;
  }
}

.post-section-grid .ant-btn {
  margin: -28px 0 8px 4px !important;
}

.ant-input-search-enter-button input {
  border-right: 0.5px solid #d9d9d9;
}

.ant-btn-circle,
.ant-btn-circle-outline {
  border-radius: 50% !important;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  width: 336px;
}

@media screen and (min-width: 300px) and (max-width: 575px) {
  .ant-modal-content {
    margin-left: 25px !important;
    margin-right: 25px !important;
    margin-top: 230px;
  }
}

.postDetailsBodySection {
  //NRT-13866 - Pre tag white-space issue - Post Id - 1723101582596
  pre {
    white-space: pre-wrap !important;
  }
}
