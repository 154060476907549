@import 'src/styles/utilities/utilities_new';

.ant-select-item-option-content {
  text-wrap: wrap !important;
  word-break: break-all !important;
  overflow-wrap: break-word !important;

  text-align: left;
  vertical-align: middle;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media #{$max-small} {
    -webkit-line-clamp: 2;
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: $theme-colors-select-item-selected-bg;
}
