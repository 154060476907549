@import 'src/styles/utilities/utilities';

body {
  &.default {
  }

  &.dark {
  }
}

// force the carousel navigator buttons to look like blue dots
.ant-carousel {
  .slick-dots {
    li {
      background: none;

      button {
        background: #1890ff !important;
        width: 12px !important;
        height: 12px !important;
        border-radius: 100% !important;
      }

      &.slick-active {
        width: 16px !important;
      }
    }
  }
}
