.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  //background: $theme-colors-button-button-color !important;
  border-color: $theme-colors-border-color-base !important;
}

.ant-radio-button-wrapper {
  color: $theme-colors-text-color !important;
  background: $theme-colors-buttons-default-bg !important;
  border-color: $theme-colors-border-color-base !important;
  font-weight: 400;

  &.ant-radio-button-wrapper-checked {
    color: $theme-colors-primary-color !important;
    background: $theme-colors-button-check-bg !important;
    border-color: $theme-colors-primary-color !important;
  }
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background: none !important;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  color: $theme-colors-primary-color !important;
}
