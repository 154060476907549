// Global styles
@import './global';

// Default LIGHT AntD styles
@import '../../node_modules/ng-zorro-antd/ng-zorro-antd.min.css';

// Color variables
@import './utilities/variables';

// Custom styles (overrides)
@import './custom';
