$studio-red: #d4380d;
$studio-yellow: #ffec3d;
$studio-gold: #faad14;
$studio-blue: #2f54eb;
$studio-green: #389e0d;
$studio-black: #000000;
$studio-grey: #808080;
$studio-purple: #9254de;

$gray-1: #ffffff;
$gray-2: #fafafa;
$gray-3: #f5f5f5;
$gray-4: #f0f0f0;
$gray-5: #d9d9d9;
$gray-6: #bfbfbf;
$gray-7: #8c8c8c;
$gray-8: #595959;
$gray-9: #262626;
$gray-10: #000000;
$gray-11: #a1a1a1;
$gray-12: #000000d9; //rgba(0, 0, 0, 0.85)
$gray-13: #00000026; //rgba(0, 0, 0, 0.15)
$gray-14: #00000073; //rgba(0, 0, 0, 0.45)
$gray-15: #0000000a; //rgba(0, 0, 0, 0.04)
$gray-16: #00000040; //rgba(0, 0, 0, 0.25)
$gray-17: #ffffff80; //rgba(255, 255, 255, 0.5);
$gray-18: #000000bf; //rgba(0, 0, 0, 0.75);
$gray-19: #ffffff0f; //rgba(255, 255, 255, 0.06)
$gray-20: #0000000f; //rgba(0, 0, 0, 0.06)
$gray-21: #0000000a; //rgba(0, 0, 0, 0.04);
$gray-22: #ffffffa6; //rgba(255, 255, 255, 0.65)
$gray-23: #434343;

$blue-1: #e6f7ff;
$blue-2: #bae7ff;
$blue-3: #91d5ff;
$blue-4: #69c0ff;
$blue-5: #40a9ff;
$blue-6: #1890ff;
$blue-7: #096dd9;
$blue-8: #0050b3;
$blue-9: #003a8c;
$blue-10: #002766;

$green-1: #f6ffed;
$green-2: #d9f7be;
$green-3: #b7eb8f;
$green-4: #95de64;
$green-5: #73d13d;
$green-6: #52c41a;
$green-7: #389e0d;
$green-8: #237804;
$green-9: #135200;
$green-10: #092b00;

$purple-1: #f9f0ff;
$purple-2: #efdbff;
$purple-3: #d3adf7;
$purple-4: #b37feb;
$purple-5: #9254de;
$purple-6: #722ed1;
$purple-7: #531dab;
$purple-8: #391085;
$purple-9: #22075e;
$purple-10: #120338;

$orange-1: #fff7e6;
$orange-2: #ffe7ba;
$orange-3: #ffd591;
$orange-4: #ffc069;
$orange-5: #ffa940;
$orange-6: #fa8c16;
$orange-7: #d46b08;
$orange-8: #ad4e00;
$orange-9: #873800;
$orange-10: #612500;

$gold-1: #fffbe6;
$gold-2: #fff1b8;
$gold-3: #ffe58f;
$gold-4: #ffd666;
$gold-5: #ffc53d;
$gold-6: #faad14;
$gold-7: #d48806;
$gold-8: #ad6800;
$gold-9: #874d00;
$gold-10: #613400;

$yellow-1: #feffe6;
$yellow-2: #ffffb8;
$yellow-3: #fffb8f;
$yellow-4: #fff566;
$yellow-5: #ffec3d;
$yellow-6: #fadb14;
$yellow-7: #d4b106;
$yellow-8: #ad8b00;
$yellow-9: #876800;
$yellow-10: #614700;

$red-1: #fff1f0;
$red-2: #ffccc7;
$red-3: #ffa39e;
$red-4: #ff7875;
$red-5: #ff4d4f;
$red-6: #f5222d;
$red-7: #cf1322;
$red-8: #a8071a;
$red-9: #820014;
$red-10: #5c0011;

// $orange-6: #FA8C16;

// $gold-6: #FAAD14;

$background-1: #f0f2f5;
$background-2: #001529;
$background-3: #000000;
$background-4: #130c25;
$background-5: #000c17;

$sf-pro-display: 'SF Pro Display', sans-serif;
$sf-pro-display-bold: 'SF Pro Display Bold', sans-serif;
$sf-pro-display-semi-bold: 'SF Pro Display Semi Bold', sans-serif;
$montserrat: 'Montserrat', 'SF Pro Display', sans-serif;

$navigation-drawer-open-width: 255px;
$navigation-drawer-closed-width: 80px;
$step-navigation-width: 240px;
$menu-background-color: $background-2;
$header-height: 55px;
$subheader-height-small: 64px;
$subheader-height-medium: 96px;
$subheader-height-large: 176px;

$max-page-width: 1600px;

// Page pad values at different breakpoints
$page-pad-large: 48px 48px 100px 48px;
$page-pad-medium: 24px 24px 100px 24px;
$page-pad-xsmall: 24px 0px 100px 0px;

/** These variables are ONLY for convenience
    so that we don't need to type var(--XXX) everytime in our SCSS files **/

$primary-background-color: var(--primary-background-color);
$secondary-background-color: var(--secondary-background-color);
$third-background-color: var(--third-background-color);
$fourth-background-color: var(--fourth-background-color);

$primary-font-color: var(--primary-font-color);
$secondary-font-color: var(--secondary-font-color);
$second-third-font-color: var(--second-third-font-color);
$third-font-color: var(--third-font-color);
$fourth-font-color: var(--fourth-font-color);
$fifth-font-color: var(--fifth-font-color);

$components-border: var(--components-border);
$components-background: var(--components-background);
$components-second-background: var(--components-second-background);
$components-third-background: var(--components-third-background);

$input-text-color: var(--input-text-color);
$input-background: var(--input-background);
$input-placeholder: var(--input-placeholder);
$text-selection: var(--text-selection);

$pinned-post-bg: var(--pinned-post-bg);
$pinned-post-border: var(--pinned-post-border);
$pinned-post-icon: var(--pinned-post-icon);

$archive-banner-bg: var(--archive-banner-bg);
$archive-banner-border: var(--archive-banner-border);

$draft-banner-bg: var(--draft-banner-bg);
$draft-banner-border: var(--draft-banner-border);

$lock-banner-bg: var(--lock-banner-bg);
$lock-banner-border: var(--lock-banner-border);
$lock-btn-border: var(--lock-btn-border);
$lock-btn-bg: var(--lock-btn-bg);

$comments-profile-color: var(--comments-profile-color);
$comments-bg: var(--comments-bg);
$comments-color: var(--comments-color);
$comments-placeholder: var(--comments-placeholder);
$comments-edit-icon: var(--comments-edit-icon);

$selection-item-bg: var(--selection-item-bg);
$selection-item-border: var(--selection-item-border);

$icon-btn: var(--icon-btn);
$close-btn: var(--close-btn);

$crown-info-color: var(--crown-info-color);

$attachments-drag-color: var(--attachments-drag-color);
$attachments-drag-bg: var(--attachments-drag-bg);
$attachments-drag-border: var(--attachments-drag-border);
$attachments-max-size-color: var(--attachments-max-size-color);

$generate-tags-auto: var(--generate-tags-auto);

$post-title-metadata-bg: var(--post-title-metadata-bg);
$metadata-close: var(--metadata-close);

$scroll-bar-color: var(--scroll-bar-color);

$divider-color: var(--divider-color);

$banner-container-height: var(--banner-container-height);

$session-expire-container-height: var(--session-expire-container-height);

$drop-down-border: var(--drop-down-border);

$font-6: var(--font-6);

$element-icon: var(--element-icon);

$tool-tip-bg: var(--tool-tip-bg);
$tool-tip-color: var(--tool-tip-color);

$collapse-icon: var(--collapse-icon);

$footer-default: var(--footer-default);

$selected-generate-tag: var(--selected-generate-tag);

$font-7: var(--font-7);

$back-arrow: var(--back-arrow);

$attach-comment-share: var(--attach-comment-share);

$attach-delete-icon: var(--attach-delete-icon);

$font-8: var(--font-8);

$font-9: var(--font-9);

$story-team-name: var(--story-team-name);
$story-team-role: var(--story-team-role);

$post-header-icon: var(--post-header-icon);
$post-move-icon: var(--post-move-icon);

$topic-tags-view-post: var(--topic-tags-view-post);

$topics-tags-label-bg: var(--topics-tags-label-bg);

$global-search-title: var(--global-search-title);
$global-search-title-match: var(--global-search-title-match);
$global-search-created-by: var(--global-search-created-by);
$global-search-header: var(--global-search-header);
$global-search-angle-id: var(--global-search-angle-id);
$global-search-border: var(--global-search-border);
$global-search-bg: var(--global-search-bg);
$global-search-preview-eye-invisible-icon: var(
    --global-search-preview-eye-invisible-icon
);
$global-search-page-eye-invisible-icon: var(
    --global-search-page-eye-invisible-icon
);

$tabs-fore-color: var(--tabs-fore-color);
$collapse-panel-header: var(--collapse-panel-header);
$collapse-panel-header-icons: var(--collapse-panel-header-icons);

$froala-rt-editor-header-bg: var(--froala-rt-editor-header-bg);

$profile-bg: var(--profile-bg);
$profile-icon: var(--profile-icon);

$add-icon-bg: var(--add-icon-bg);
$close-icon-bg: var(--close-icon-bg);
$search-icon-color: var(--search-icon-color);

$private-angle-overivew: var(--private-angle-overivew);

$angle-landing-eye-invisible-icon: var(--angle-landing-eye-invisible-icon);

$angle-details-eye-invisible-icon: var(--angle-details-eye-invisible-icon);

$eye-icon-bg: var(--eye-icon-bg);

$angle-landing-tab-default: var(--angle-landing-tab-default);

$angle-landing-tab-disabled: var(--angle-landing-tab-disabled);

$angle-log-section-bg: var(--angle-log-section-bg);

$angle-log-collapse-bg: var(--angle-log-collapse-bg);

$create-story-label: var(--create-story-label);

$create-story-content-section-border: var(
  --create-story-content-section-border
);

$create-story-content-section-bg: var(--create-story-content-section-bg);

$create-story-content-bg: var(--create-story-content-bg);

$create-story-metadata-bg: var(--create-story-metadata-bg);
